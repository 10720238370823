<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>Persönliche <span class="fw-bold">Daten</span></h1>
        <p>Herzlich Willkommen in Ihrem persönlichen Kundenbereich</p>
          <div class="mt-5 pt-3">
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <label for="cardId" class="form-label label-view">Kartennummer(n)</label>
                  <p id="cardId" class="mb-0 fw-bold">
                    <span v-for="cardID, index in personalData.cardIDs" :key="cardID"> {{ index > 0 ? ', ' : '' }}{{ cardID }}</span></p>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputEmail" class="form-label label-view">
                    E-Mail-Adresse
                  </label>
                  <input v-model="personalData.email" type="email" id="email" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="100" readonly>
                  <div id="emailNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputGender" class="form-label label-view">
                    Geschlecht
                  </label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="personalData.gender" class="form-select input-view" id="gender">
                        <option value="männlich">männlich</option>
                        <option value="weiblich">weiblich</option>
                        <option value="divers">divers</option>
                        <option value="sonstige">sonstige</option>
                      </select>
                    </div>
                  </div>
                  <div id="genderNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputFirstName" class="form-label label-view">
                    Vorname
                  </label>
                  <input v-model="personalData.firstName" type="text" id="firstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                  <div id="firstNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputLastName" class="form-label label-view">
                    Nachname
                  </label>
                  <input v-model="personalData.lastName" type="text" id="lastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                  <div id="lastNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputStreet" class="form-label label-view">
                    Straße und Hausnummer
                  </label>
                  <input v-model="personalData.street" type="text" id="street" class="form-control input-view" placeholder="Straße und Hausnummer eingeben" maxlength="45">
                  <div id="streetNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputZip" class="form-label label-view">
                    Postleitzahl
                  </label>
                  <div class="row">
                    <div class="col-lg-4">
                      <input v-model="personalData.zip" type="text" maxlength="5" id="zip" class="form-control input-view" placeholder="PLZ">
                    </div>
                  </div>
                  <div id="zipNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputCity" class="form-label label-view">
                    Ort
                  </label>
                  <input v-model="personalData.city" type="text" id="city" class="form-control input-view" placeholder="Ort eingeben" maxlength="30">
                  <div id="cityNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputCountry" class="form-label label-view">
                    Land
                  </label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="personalData.country" class="form-select input-view" id="country">
                          <option value="DE">Deutschland</option>
                          <option value="CH">Schweiz</option>
                          <option value="AT">Österreich</option>
                          <option value="FR">Frankreich</option>
                          <option value="BE">Belgien</option>
                          <option value="LU">Luxemburg</option>
                          <option value="DK">Dänemark</option>
                          <option value="NO">Norwegen</option>
                          <option value="SE">Schweden</option>
                          <option value="FI">Finnland</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="NL">Niederlande</option>
                          <option value="PL">Polen</option>
                          <option value="PT">Portugal</option>
                          <option value="RO">Rumänien</option>
                          <option value="RU">Russland</option>
                          <option value="GR">Griechenland</option>
                          <option value="AL">Albanien</option>
                          <option value="AD">Andorra</option>
                          <option value="BG">Bulgarien</option>
                          <option value="IT">Italien</option>
                          <option value="IE">Irland</option>
                          <option value="EE">Estland</option>
                          <option value="LV">Lettland</option>
                          <option value="LT">Litauen</option>
                          <option value="KZ">Kasachstan</option>
                          <option value="SM">San Marino</option>
                          <option value="RS">Serbien</option>
                          <option value="SK">Slowakei</option>
                          <option value="SI">Slowenien</option>
                          <option value="ES">Spanien</option>
                          <option value="CZ">Tschechien</option>
                          <option value="TR">Türkei</option>
                          <option value="UA">Ukraine</option>
                          <option value="HU">Ungarn</option>
                          <option value="GB">Vereinigtes Königreich</option>
                          <option value="MT">Malta</option>
                          <option value="MC">Monaco</option>
                          <option value="MK">Nordmazedonien</option>
                        </select>
                    </div>
                  </div>
                  <div id="countryNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputPhone" class="form-label label-view">
                    Telefonnummer (optional)
                  </label>
                  <input v-model="personalData.phone" type="text" id="phone" class="form-control input-view" placeholder="+49 12345678910">
                  <div id="phoneNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top border-bottom">
              <div class="row">
                <div class="col-lg-6">
                  <label for="inputBirthdate" class="form-label label-view">
                    Geburtsdatum (TT.MM.JJJJ)
                  </label>
                  <input v-model="personalData.birthdate" type="text" id="birthdate" class="form-control input-view" placeholder="(TT.MM.JJJJ)">
                  <div id="birthdateNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="text-end">
              <button @click="savePersonalData" class="btn btn-primary">Speichern >></button>
            </div>
          </div>
      </div>
    </div>
    <div class="row my-5 pt-5">
      <div class="col-12">
        <h2>Einwilligungserklärung <b>Werbung</b></h2>
        <div class="mt-3">
          <div class="mb-3 form-check">
            <input v-model="personalData.marketingAdsConsent" type="checkbox" class="form-check-input p-2" id="marketingAdsConsent">
            <label class="form-check-label" for="marketingAdsConsent">
              Ich bin damit einverstanden, dass ich per E-Mail persönliche auf mich ausgerichtete Informationen und Werbung, wie z.B. Geburtstagsbonus, Sonderangebote, Bonus- und Rabattaktionen, lokale Events der teilnehmenden Geschäfte, erhalte. Ich habe die <router-link :to="{ name: 'DeclarationOfConsent' }">Einwilligungserklärung</router-link> gelesen. Ihre Einwilligung können Sie nach erfolgter Registrierung jederzeit in Ihrem Kundenkonto widerrufen.
            </label>
          </div>
          <div class="mb-3 form-check">
            <input v-model="personalData.newsletterConsent" type="checkbox" class="form-check-input p-2" id="newsletterConsent">
            <label class="form-check-label" for="newsletterConsent">
              Ich abonniere den kostenlosen Newsletter zu meiner CARD<br />
              (Versand maximal einmal im Monat). Bitte bestätigen Sie den Empfang in der separat zugesandten E-Mail.<br />
              Eine Abmeldung vom Newsletter ist jederzeit innerhalb Ihres Kundenkontos möglich. <br />
              <br />
              Im Rahmen der Registrierung und Nutzung Ihrer CARD verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }">Datenschutzerklärung</router-link>.
            </label>
          </div>
          <button @click="saveConsents" class="btn btn-primary">Speichern >></button>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import Helper from '../helper'

export default {
  name: 'PersonalDataPage',
  components: {
    CardData
  },
  data () {
    return {
      personalData: {},
      validationStatus: false
    }
  },
  methods: {
    async fetchPersonalData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('getPersonalData').then(response => {
        this.personalData = response
        if (typeof this.personalData.birthdate !== 'undefined' && this.personalData.birthdate !== null && this.personalData.birthdate !== '') {
          this.personalData.birthdate = Helper.convertISODateToGermanDate(this.personalData.birthdate)
        }
      }).catch(() => {
      })
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    validatePersonalData () {
      this.validationStatus = true
      Object.keys(this.personalData).forEach(key => {
        if (key === 'cardIDs' || key === 'marketingAdsConsent' || key === 'newsletterConsent' || key === 'gguid' || key === 'isCardActive' || key === 'salutation') {
          return
        }
        if (!this.personalData[key] && key !== 'phone') {
          this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
        } else if (key !== 'firstName' && key !== 'lastName' && key !== 'phone' && key !== 'country' && this.personalData[key].length < 3) {
          this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
        } else if (key === 'country' && this.personalData[key].length < 2) {
          this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
        } else if ((key === 'gender' || key === 'firstName' || key === 'lastName' || key === 'city' || key === 'country') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.personalData[key]))) {
          this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
        } else if (key === 'street' && !(/\d/.test(this.personalData[key]))) {
          this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
        } else if ((key === 'zip') && !(/^\d+$/.test(this.personalData[key]))) {
          this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
        } else if ((key === 'zip') && !(this.personalData[key].length === 5 || this.personalData[key].length === 4)) {
          this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
        } else if (key === 'birthdate' && !(/^(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})$/.test(this.personalData[key]))) {
          this.showValidationError(key, 'Dies ist kein gültiges Geburtsdatum. Es muss das Format TT.MM.JJJJ (mit führenden Nullen) haben.')
        } else if (key === 'phone' && this.personalData[key].length > 0 && (this.personalData[key].length < 4 || this.personalData[key].length > 29 || !/^[+ 0-9]+$/.test(this.personalData[key]))) {
          this.showValidationError(key, 'Die Telefonnummer ist ungültig.')
        }
      })
      return this.validationStatus
    },
    async savePersonalData () {
      Object.keys(this.personalData).forEach(key => {
        if (key !== 'marketingAdsConsent' && key !== 'newsletterConsent' && key !== 'gguid' && key === 'isCardActive' && key === 'salutation') {
          document.getElementById(key).classList.remove('form-element-danger')
          document.getElementById(key + 'Note').innerHTML = ''
        }
      })
      if (this.validatePersonalData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.personalData.birthdate = Helper.convertGermanDateToISODate(this.personalData.birthdate)
        this.$store.dispatch('savePersonalData', this.personalData)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.personalData.birthdate = Helper.convertISODateToGermanDate(this.personalData.birthdate)
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      }
    },
    showValidationError(key, validationMessage) {
      document.getElementById(key).classList.add('form-element-danger')
      document.getElementById(key + 'Note').innerHTML = validationMessage
      this.validationStatus = false
    },
    async saveConsents () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('saveConsents', { gguid: this.personalData.gguid, marketingAdsConsent: this.personalData.marketingAdsConsent, newsletterConsent: this.personalData.newsletterConsent })
        .then(() => { })
        .catch(() => { })
        .finally(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    }
  },
  mounted () {
    this.fetchPersonalData()
  }
}
</script>

<style lang="scss" scoped>
#cardId {
  padding: .375rem 0 .375rem 0;
  line-height: 1.5;
  color: #212529;
}

#gender {
  min-width: 130px;
}

#country {
  min-width: 220px;
}

.label-view {
  margin-bottom: 5px;
}

.input-view {
  background-color: #f6f6f6 !important;
  border: none;
  border-radius: 0;
  padding-left: 0;
}
/*
.form-control:focus, .form-select:focus {
  border: 1px solid #003882;
  box-shadow: 0 0 0 0.1rem #003882;
  padding-left: 5px;
}

.form-element-danger {
  background-color: #fff !important;
  border: 2px solid #ff0000;
  border-radius: 0;
  padding-left: 5px;
} */
</style>
