<template>
  <div class="container py-1 py-md-5">
    <div class="content">
      <div class="row mt-3">
        <div class="col-lg-6 col-12 pe-lg-4">
          <h4 class="h5">Entwicklung der Transaktionszahlen (Anzahl)</h4>
          <select id="timePeriodAmountOption" class="form-select input-view" @change="changeChartTimePeriod('amount')">
            <option value="day">Tag</option>
            <option value="week">Woche</option>
            <option value="month">Monat</option>
            <option value="year">Jahr</option>
          </select>
          <canvas id="chartTransactionsDayWeekYearMonth"></canvas>
        </div>
        <div class="col-lg-6 col-12 ps-lg-4 pt-4 pt-lg-0">
          <h4 class="h5">Entwicklung der Transaktionszahlen (Summe in Euro)</h4>
          <select id="timePeriodSumOption" class="form-select input-view" @change="changeChartTimePeriod('sum')">
            <option value="day">Tag</option>
            <option value="week">Woche</option>
            <option value="month">Monat</option>
            <option value="year">Jahr</option>
          </select>
          <canvas id="chartTransactionsDayWeekYearMonthEuroSum"></canvas>
        </div>
        <!--
        <div class="col-lg-6 col-12 ps-lg-4 pt-4 pt-lg-0">
          <h4 class="h5">Top 10 Kunden nach Gesamtbetrag</h4>
          <select id="showBookingType" class="form-select input-view" @change="makeTransactionByTop10UsersChart()">
            <option value="Einlösung">Einlösung</option>
            <option value="Einkauf">Einkauf</option>
            <option value="Bonus">Bonus</option>
            <option value="Aufladung">Aufladung</option>
          </select>
          <canvas id="chartTransactionsTop10Cards"></canvas>
        </div>
      -->
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-12 pe-lg-4">
          <h4 class="h5">Transaktionen pro Tagesabschnitt (Anzahl)</h4>
          <canvas id="chartTransactionsDaytime"></canvas>
        </div>
        <div class="col-lg-6 col-12 ps-lg-4 pt-4 pt-lg-0">
          <h4 class="h5">Transaktionen pro Tagesabschnitt (Summe in Euro)</h4>
          <canvas id="chartTransactionsDaytimeEuroSum"></canvas>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-12 pe-lg-4">
          <h4 class="h5">Transaktionen pro Wochentag (Anzahl)</h4>
          <canvas id="chartTransactionsWeekdays"></canvas>
        </div>
        <div class="col-lg-6 col-12 ps-lg-4 pt-4 pt-lg-0">
          <h4 class="h5">Transaktionen pro Wochentag (Summe in Euro)</h4>
          <canvas id="chartTransactionsWeekdaysEuroSum"></canvas>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12 col-12 pe-lg-4">
          <h4 class="h5">Kartenanzahl pro Monat: Registriert vs. Unregistriert</h4>
          <canvas id="chartCustomersRegistrationStatus"></canvas>
        </div>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="accordion" id="accor">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingHelpText">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHelpText" aria-expanded="false" aria-controls="collapseHelpText">
                <img class="img-fluid me-2" :src="require('../assets/img/booking/circle-info.svg')" width="20">Wie kann ich mit den Diagrammen interagieren?
              </button>
            </h2>
            <div id="collapseHelpText" class="accordion-collapse collapse" aria-labelledby="helpText" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Wenn Sie auf die, über dem Diagramm befindenden, Legenden anklicken, können Sie einzelne Daten ein- oder ausblenden.
                <br />
                <br />
                Wenn Sie mit der Maus über ein Diagramm fahren bekommen Sie die jeweils konkreten Werte angezeigt.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Chart from 'chart.js/auto'

export default {
  name: 'PartnerStatisticsPages',
  data () {
    return {
      bookingColors: ['#e30513', '#5db12f', '#ffff00', '#ffa500'],
      transactions: [],
      chartTransactionsByPeriods: undefined,
      chartTransactionsByPeriodsEuroSum: undefined,
      chartTransactionsByDatetime: undefined,
      chartTransactionsByDatetimeEuroSum: undefined,
      chartTransactionsByWeekdays: undefined,
      chartTransactionsByWeekdaysEuroSum: undefined,
      // chartTransactionsTop10: undefined,
      chartTransactionsRegistrationStatus: undefined
    }
  },
  methods: {
    async fetchTransactionData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getPartnerTransactions').then(response => {
        this.transactions = response
        this.makeTransactionsRegistrationStatusMonth()
        // this.makeTransactionByTop10UsersChart()
        this.makeTransactionsByDaytimeChart('amount')
        this.makeTransactionsByDaytimeChart('sum')
        this.makeTransactionsByWeekdaysChart('amount')
        this.makeTransactionsByWeekdaysChart('sum')
        this.changeChartTimePeriod('amount')
        this.changeChartTimePeriod('sum')
      }).catch(() => { })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async makeTransactionByDayChart(chartID, amountOrSum) {
      const todayStartOfDay = moment().startOf('day').format('DD.MM.YYYY')
      const todayEndOfDay = moment().endOf('day').format('DD.MM.YYYY')
      const todayData = this.getDataForDateBetween(todayStartOfDay, todayEndOfDay, amountOrSum)
      const yesterdayStartOfDay = moment().subtract(1, 'days').startOf('day').format('DD.MM.YYYY')
      const yesterdayEndOfDay = moment().subtract(1, 'days').endOf('day').format('DD.MM.YYYY')
      const yesterdayData = this.getDataForDateBetween(yesterdayStartOfDay, yesterdayEndOfDay, amountOrSum)
      this.updateTransactionChartData([yesterdayData, todayData], ['gestern', 'heute'], chartID, amountOrSum)
    },
    async makeTransactionByWeekChart(chartID, amountOrSum) {
      const thisWeekStart = moment().startOf('isoWeek').format('DD.MM.YYYY')
      const thisWeekEnd = moment().endOf('isoWeek').format('DD.MM.YYYY')
      const thisWeekData = this.getDataForDateBetween(thisWeekStart, thisWeekEnd, amountOrSum)
      const lastWeekStart = moment().subtract(1, 'isoWeek').startOf('isoWeek').format('DD.MM.YYYY')
      const lastWeekEnd = moment().subtract(1, 'isoWeek').endOf('isoWeek').format('DD.MM.YYYY')
      const lastWeekData = this.getDataForDateBetween(lastWeekStart, lastWeekEnd, amountOrSum)
      this.updateTransactionChartData([lastWeekData, thisWeekData], ['letzte Woche', 'diese Woche'], chartID, amountOrSum)
    },
    async makeTransactionByMonthChart(chartID, amountOrSum) {
      const thisMonthStart = moment().startOf('month').format('DD.MM.YYYY')
      const thisMonthEnd = moment().endOf('month').format('DD.MM.YYYY')
      const thisMonthData = this.getDataForDateBetween(thisMonthStart, thisMonthEnd, amountOrSum)
      const lastMonthStart = moment().subtract(1, 'month').startOf('month').format('DD.MM.YYYY')
      const lastMonthEnd = moment().subtract(1, 'month').endOf('month').format('DD.MM.YYYY')
      const lastMonthData = this.getDataForDateBetween(lastMonthStart, lastMonthEnd, amountOrSum)
      this.updateTransactionChartData([lastMonthData, thisMonthData], ['letzter Monat', 'dieser Monat'], chartID, amountOrSum)
    },
    async makeTransactionByYearChart(chartID, amountOrSum) {
      const thisYearStart = moment().startOf('year').format('DD.MM.YYYY')
      const thisYearEnd = moment().endOf('year').format('DD.MM.YYYY')
      const thisYearData = this.getDataForDateBetween(thisYearStart, thisYearEnd, amountOrSum)
      const lastYearStart = moment().subtract(1, 'year').startOf('year').format('DD.MM.YYYY')
      const lastYearEnd = moment().subtract(1, 'year').endOf('year').format('DD.MM.YYYY')
      const lastYearData = this.getDataForDateBetween(lastYearStart, lastYearEnd, amountOrSum)
      this.updateTransactionChartData([lastYearData, thisYearData], ['letztes Jahr', 'dieses Jahr'], chartID, amountOrSum)
    },
    async makeTransactionsByDaytimeChart(amountOrSum) {
      const time0to9 = this.getDataForTimeBetween(0, 9, amountOrSum)
      const time9to14 = this.getDataForTimeBetween(9, 14, amountOrSum)
      const time14to19 = this.getDataForTimeBetween(14, 19, amountOrSum)
      const time19to24 = this.getDataForTimeBetween(19, 24, amountOrSum)
      let chartID = ''
      if (amountOrSum === 'amount') {
        chartID = 'chartTransactionsDaytime'
      } else if (amountOrSum === 'sum') {
        chartID = 'chartTransactionsDaytimeEuroSum'
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Umstellen des Zeitraumes ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.' })
      }
      this.updateTransactionChartData([time0to9, time9to14, time14to19, time19to24], ['0 - 9 Uhr', '9 - 14 Uhr', '14 - 19 Uhr', '19 - 24 Uhr'], chartID, amountOrSum)
    },
    async makeTransactionsByWeekdaysChart(amountOrSum) {
      const transactionsByDay = []
      const defaultWeekdaysLabels = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
      const indices = [1, 2, 3, 4, 5, 6, 0] // 0..6 Sunday..Saturday
      const customWeekdaysLabels = indices.map(index => defaultWeekdaysLabels[index])
      for (let ind = 0; ind < indices.length; ind++) {
        const filteredDates = this.transactions.filter(transaction => {
          return (moment(transaction.dateFormatted, 'DD.MM.YYYY').day() === indices[ind])
        })
        const groupedDates = this.groupByBookingType(filteredDates, amountOrSum)
        transactionsByDay.push(groupedDates)
      }
      let chartID = ''
      if (amountOrSum === 'amount') {
        chartID = 'chartTransactionsWeekdays'
      } else if (amountOrSum === 'sum') {
        chartID = 'chartTransactionsWeekdaysEuroSum'
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Umstellen des Zeitraumes ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.' })
      }
      this.updateTransactionChartData(transactionsByDay, customWeekdaysLabels, chartID, amountOrSum)
    },
    async makeTransactionsRegistrationStatusMonth() {
      const transactionsByMonth = []
      for (let ind = 0; ind < 12; ind++) {
        const filteredTransactions = this.transactions.filter(transaction => {
          return (moment(transaction.dateFormatted, 'DD.MM.YYYY').month() === ind)
        })
        const uniqueCardIDs = {}
        filteredTransactions.forEach(filteredTransaction => {
          const cardID = filteredTransaction.cardID
          if (!(cardID in uniqueCardIDs)) {
            const registered = filteredTransaction.registered
            uniqueCardIDs[cardID] = registered
          }
        })

        let countRegistered = 0
        let countUnregistered = 0
        for (const uniqueCardID in uniqueCardIDs) {
          if (uniqueCardID.registered) {
            countRegistered++
          } else {
            countUnregistered++
          }
        }

        transactionsByMonth.push([countUnregistered, countRegistered])
      }
      const ctx = document.getElementById('chartCustomersRegistrationStatus')
      const registrationStati = ['registriert', 'unregistriert']
      const registrationStatiColor = ['#5db12f', '#e30513']
      const datasets = []
      for (let i = 0; i < registrationStati.length; i++) {
        const tempData = transactionsByMonth.map(subArray => subArray[i])
        datasets.push({
          label: registrationStati[i],
          data: tempData,
          borderWidth: 1,
          borderColor: registrationStatiColor[i],
          backgroundColor: registrationStatiColor[i]
        })
      }
      const monthLabels = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      const data = {
        labels: monthLabels,
        datasets: datasets
      }
      if (this.chartTransactionsRegistrationStatus) {
        this.chartTransactionsRegistrationStatus.destroy()
      }
      this.chartTransactionsRegistrationStatus = new Chart(ctx, { // eslint-disable-line no-new
        type: 'line',
        data: data,
        options: {
          scales: {
            x: {
              stacked: false,
              beginAtZero: true
            },
            y: {
              stacked: false,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Anzahl an Karten'
              },
              ticks: {
                precision: 0
              }
            }
          }
        }
      })
    },
    /*
    async makeTransactionByTop10UsersChart() {
      const bookingType = document.getElementById('showBookingType').value
      let bookingTypeColorIndex = 0
      switch (bookingType) {
        case 'Einlösung':
          bookingTypeColorIndex = 0
          break
        case 'Aufladung':
          bookingTypeColorIndex = 1
          break
        case 'Einkauf':
          bookingTypeColorIndex = 2
          break
        case 'Bonus':
          bookingTypeColorIndex = 3
          break
        default:
          break
      }
      const filteredTransactions = this.transactions.filter(obj => {
        return obj.text === bookingType
      })
      // convert Einlösung and Einkauf to positiv values, Bonus is already positive
      const makeValuePositiv = (bookingType === 'Einlösung' || bookingType === 'Einkauf') ? (-1) : 1
      const groupedSum = filteredTransactions.reduce((acc, transaction) => {
        const cardID = transaction.cardID
        const amount = transaction.amount * makeValuePositiv
        if (acc[cardID]) {
          acc[cardID] += amount
        } else {
          acc[cardID] = amount
        }
        return acc
      }, {})
      // {'cardId1': 234.34, 'cardid45': 240, ...}

      // conv groupedSum to Array, sort, slice top 10, convert back to object
      const top10cardIDs = Object.fromEntries(Object.entries(groupedSum).sort((a, b) => b[1] - a[1]).slice(0, 10))

      if (this.chartTransactionsTop10) {
        this.chartTransactionsTop10.destroy()
      }
      const ctx = document.getElementById('chartTransactionsTop10Cards')
      this.chartTransactionsTop10 = new Chart(ctx, { // eslint-disable-line no-new
        type: 'bar',
        data: {
          labels: Object.keys(top10cardIDs),
          datasets: [
            {
              label: 'Gesamtbetrag (€)',
              data: Object.values(top10cardIDs),
              backgroundColor: this.bookingColors[bookingTypeColorIndex]
            }
          ]
        },
        options: {
          ticks: {
            precision: 0
          }
        }
      })
    },
    */
    getDataForTimeBetween (from, to, amountOrSum) {
      // time between 'from' and 'to' is same as from <= hours < to
      const transactionsSelected = this.transactions.filter(transaction => {
        const hours = transaction.time.split(':')[0] // transaction.time format is HH:mm:ss
        return (from <= hours && hours < to)
      })
      return this.groupByBookingType(transactionsSelected, amountOrSum)
    },
    getDataForDateBetween (from, to, amountOrSum) {
      const transactionsToday = this.transactions.filter(transaction => {
        const fromDate = moment(from, 'DD.MM.YYYY')
        const toDate = moment(to, 'DD.MM.YYYY')
        return moment(transaction.dateFormatted, 'DD.MM.YYYY').isBetween(fromDate, toDate, undefined, '[]')
      })
      return this.groupByBookingType(transactionsToday, amountOrSum)
    },
    groupByBookingType (transactionsInput, amountOrSum) {
      let einloesung = transactionsInput.filter(obj => {
        return obj.text === 'Einlösung'
      })
      let aufladen = transactionsInput.filter(obj => {
        return obj.text === 'Aufladung'
      })
      let einkauf = transactionsInput.filter(obj => {
        return obj.text === 'Einkauf'
      })
      let bonus = transactionsInput.filter(obj => {
        return obj.text === 'Bonus'
      })
      if (amountOrSum === 'amount') {
        einloesung = einloesung.length
        aufladen = aufladen.length
        einkauf = einkauf.length
        bonus = bonus.length
      } else if (amountOrSum === 'sum') {
        einloesung = einloesung.reduce((n, { amountPositive }) => n + amountPositive, 0)
        aufladen = aufladen.reduce((n, { amountPositive }) => n + amountPositive, 0)
        einkauf = einkauf.reduce((n, { amountPositive }) => n + amountPositive, 0)
        bonus = bonus.reduce((n, { amountPositive }) => n + amountPositive, 0)
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Umstellen des Zeitraumes ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.' })
      }
      return [einloesung, aufladen, einkauf, bonus]
    },
    updateTransactionChartData (inputData, inputLabels, chartID, amountOrSum) {
      const ctx = document.getElementById(chartID)
      const bookingTypesLabels = ['Einlösungen', 'Aufladungen', 'Einkäufe', 'Bonus']
      const datasets = []
      for (let i = 0; i < bookingTypesLabels.length; i++) {
        const tempData = inputData.map(subArray => subArray[i])
        datasets.push({
          label: bookingTypesLabels[i],
          data: tempData,
          borderWidth: 1,
          backgroundColor: this.bookingColors[i]
        })
      }
      const data = {
        labels: inputLabels,
        datasets: datasets
      }
      if (chartID === 'chartTransactionsDayWeekYearMonth' && this.chartTransactionsByPeriods) {
        this.chartTransactionsByPeriods.destroy()
      } else if (chartID === 'chartTransactionsDaytime' && this.chartTransactionsByDatetime) {
        this.chartTransactionsByDatetime.destroy()
      } else if (chartID === 'chartTransactionsWeekdays' && this.chartTransactionsByWeekdays) {
        this.chartTransactionsByWeekdays.destroy()
      } else if (chartID === 'chartTransactionsDayWeekYearMonthEuroSum' && this.chartTransactionsByPeriodsEuroSum) {
        this.chartTransactionsByPeriodsEuroSum.destroy()
      } else if (chartID === 'chartTransactionsDaytimeEuroSum' && this.chartTransactionsByDatetimeEuroSum) {
        this.chartTransactionsByDatetimeEuroSum.destroy()
      } else if (chartID === 'chartTransactionsWeekdaysEuroSum' && this.chartTransactionsByWeekdaysEuroSum) {
        this.chartTransactionsByWeekdaysEuroSum.destroy()
      }

      let textYAxis = ''
      if (amountOrSum === 'amount') {
        textYAxis = 'Anzahl an Transaktionen'
      } else if (amountOrSum === 'sum') {
        textYAxis = 'Summe (in Euro)'
      }

      const chart = new Chart(ctx, { // eslint-disable-line no-new
        type: 'bar',
        data: data,
        options: {
          scales: {
            x: {
              stacked: true,
              beginAtZero: true
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: textYAxis
              },
              ticks: {
                precision: 0
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  if (amountOrSum === 'sum') {
                    return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue + '€'
                  }
                }
              }
            }
          }
        }
      })
      if (chartID === 'chartTransactionsDayWeekYearMonth') {
        this.chartTransactionsByPeriods = chart
      } else if (chartID === 'chartTransactionsDaytime') {
        this.chartTransactionsByDatetime = chart
      } else if (chartID === 'chartTransactionsWeekdays') {
        this.chartTransactionsByWeekdays = chart
      } else if (chartID === 'chartTransactionsDayWeekYearMonthEuroSum') {
        this.chartTransactionsByPeriodsEuroSum = chart
      } else if (chartID === 'chartTransactionsDaytimeEuroSum') {
        this.chartTransactionsByDatetimeEuroSum = chart
      } else if (chartID === 'chartTransactionsWeekdaysEuroSum') {
        this.chartTransactionsByWeekdaysEuroSum = chart
      }
    },
    changeChartTimePeriod(amountOrSum) {
      var timePeriod = ''
      var chartID = ''
      if (amountOrSum === 'amount') {
        timePeriod = document.getElementById('timePeriodAmountOption').value
        chartID = 'chartTransactionsDayWeekYearMonth'
      } else if (amountOrSum === 'sum') {
        timePeriod = document.getElementById('timePeriodSumOption').value
        chartID = 'chartTransactionsDayWeekYearMonthEuroSum'
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Beim Umstellen des Zeitraumes ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.' })
      }

      if (!timePeriod || timePeriod === 'day') {
        this.makeTransactionByDayChart(chartID, amountOrSum)
      } else if (timePeriod === 'week') {
        this.makeTransactionByWeekChart(chartID, amountOrSum)
      } else if (timePeriod === 'month') {
        this.makeTransactionByMonthChart(chartID, amountOrSum)
      } else if (timePeriod === 'year') {
        this.makeTransactionByYearChart(chartID, amountOrSum)
      }
    }
  },
  created () {
    this.fetchTransactionData()
  },
  beforeUnmount() {
    if (this.chartTransactionsByPeriods) {
      this.chartTransactionsByPeriods.destroy()
    }
    if (this.chartTransactionsByDatetime) {
      this.chartTransactionsByDatetime.destroy()
    }
    if (this.chartTransactionsByWeekdays) {
      this.chartTransactionsByWeekdays.destroy()
    }
    if (this.chartTransactionsByPeriodsEuroSum) {
      this.chartTransactionsByPeriodsEuroSum.destroy()
    }
    if (this.chartTransactionsByDatetimeEuroSum) {
      this.chartTransactionsByDatetimeEuroSum.destroy()
    }
    if (this.chartTransactionsByWeekdaysEuroSum) {
      this.chartTransactionsByWeekdaysEuroSum.destroy()
    }
    if (this.chartTransactionsRegistrationStatus) {
      this.chartTransactionsRegistrationStatus.destroy()
    }
    /*
    if (this.chartTransactionsTop10) {
      this.chartTransactionsTop10.destroy()
    }
    */
  }
}
</script>
