<template>
  <div class="content">
    <div class="container py-1 py-md-5">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 text-center">
          <CardData />
        </div>
        <div class="col-12 col-lg-8 ps-lg-5">
          <h1>Die <span class="fw-bold">{{ this.$store.state.user.cardName }}</span> - für eine starke Zukunft</h1>
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>Die erste gemeinsame Karte für {{ this.regionPersonName }} Einzelhändler, Dienstleister, Gastronomen und Kunden. Gemeinsam für ein starkes {{ this.$store.state.region.name }}.</p>
              <h3>Alle können mitmachen</h3>
              <p>Kleine oder größere Geschäfte, Dienstleister, Gastronomen, Handwerker, Freischaffende oder Künstler. Die {{ this.$store.state.user.cardName }} verbindet und schafft ein großes Netzwerk für eine starke und attraktive Region.</p>
              <p>Jeder zusätzliche Partner erhöht die Attraktivität der CARD und damit die Vorteile für alle Nutzer. Das wiederum stärkt die regionale Kaufkraft. Der Geldkreislauf bleibt in der Region und sichert langfristig die Nahversorgung.</p>
              <h3>Doch die CARD kann noch viel mehr</h3>
              <p>Neben der Funktion als Gutschein-, Mitarbeiter- und BonusCARD ist die {{ this.$store.state.user.cardName }} gleichzeitig eine Marketingplattform für eine breite und zielgruppenspezifische Ansprache Ihrer Kunden.</p>
              <p>Gesteuert über die eigene {{ this.$store.state.user.cardName }} App und die offizielle Webseite wird die CARD für Ihre Kunden zu einem unverzichtbaren Partner für Einkäufe und Freizeitgestaltung und vielem mehr. Die regelmäßige Kommunikation mit Ihren Kunden über den Newsletter und Soziale Medien schafft direkte Kontaktpunkte und stärkt die Verbindung.</p>
            </div>
            <div class="col-12 col-lg-6">
              <h4>GutscheinCARD</h4>
              <p>In der Funktion als Gutschein kann die Karte mit Beträgen bis max. 250 Euro vom Kunden aufgeladen werden.</p>
              <p>Da sie ebenfalls bei allen teilnehmenden Partnern eingelöst werden kann, ist die CARD das ideale Geschenk für jeden Anlass.</p>
              <h4>MitarbeiterCARD</h4>
              <p>Arbeitgeber haben die Möglichkeit, ihren Beschäftigten steuer- und abgabenfreie Sachzuwendungen als Guthaben auf die Karte zu laden. Dieses kann ausschließlich bei teilnehmenden Partnern in {{ this.$store.state.region.name }} eingelöst werden.</p>
              <h4>BonusCARD</h4>
              <p>Die Nutzer der {{ this.$store.state.user.cardName }} können bei ihren Einkäufen von weiteren Vorteilen wie spezielle Angebote und Boni profitieren, die individuell von teilnehmenden Partnern angeboten werden können.</p>
              <p>Das Bonus-Guthaben auf der {{ this.$store.state.user.cardName }} kann bei allen teilnehmenden Geschäften wieder eingelöst werden.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-5 text-center" v-if="isInterested">
        <div class="col-12">
          <router-link :to="{ name: 'DemoBooking' }" class="btn btn-primary btn-lg">Hier Demobuchungsmaske testen &gt;&gt;</router-link>
          <router-link :to="{ name: 'PartnerWerden' }" class="btn btn-primary btn-lg ms-lg-4 mt-4 mt-lg-0">Jetzt Partnervertrag abschließen &gt;&gt;</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-4 mt-5">
          <h2 ><span class="fw-bold">Einfaches Handling</span> am POS</h2>
        </div>
        <div class="col-12 col-lg-6">
          <img class="img-fluid" :src="require('../assets/img/partner-info/pc-kasse.jpg')">
        </div>
        <div class="col-12 col-lg-6 my-auto">
          <h4>PC oder PC-Kasse</h4>
          <p>Sie haben einen PC/Notebook am POS oder arbeiten mit einer PC-Kasse?</p>
          <p>Dann können Sie über das Partner-Portal (Online-Login) alle Transaktionen schnell und einfach erledigen.</p>
          <p>Zum Einlesen der Kartennummer ist die Verwendung eines Barcode-Scanners vorteilhaft.</p>
          <p>Unsere Software kann über eine Web-API Schnittstelle an bestehende Kassensysteme angebunden werden. Die Schnittstellenbeschreibung können wir Ihrem Kassensystem-Anbieter zur Prüfung zur Verfügung stellen.<br />Falls Sie hierzu weitere Informationen benötigen, kontaktieren Sie uns bitte per E-Mail an <a href="mailto:technik@trolleymaker.com">technik@trolleymaker.com</a>.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 my-auto order-md-1 order-2">
          <h4>Tablet oder Smartphone</h4>
          <p>Das Online-Portal zur Erfassung der Buchungen können Sie ganz einfach auch mit jedem Smartphone oder Tablet nutzen.</p>
          <p>So können Sie in Ihrem Geschäft, aber auch unterwegs alle Transaktionen ganz flexibel erfassen.</p>
          <p>Zum Einlesen der Kartennummer (Barcode) wird die Kamera des Gerätes als Scanner verwendet.</p>
        </div>
        <div class="col-12 col-lg-6 my-auto order-md-2 order-1">
          <img class="img-fluid" :src="require('../assets/img/partner-info/tablet-und-mobile.png')">
        </div>
      </div>
      <div class="row" v-if="false">
        <div class="col-12 col-lg-6">
          <img class="img-fluid" :src="require('../assets/img/partner-info/ec-geraet.jpg')">
        </div>
        <div class="col-12 col-lg-6 my-auto">
          <h4>EC-Terminal</h4>
          <p>Alle Karten sind mit einem NFC-Chip ausgestattet, sodass auch Ihr bestehendes NFC-fähiges EC-Gerät für die Erfassung der Transaktionen genutzt werden kann.</p>
          <p>Leider sind nicht alle Geräte und Netzbetreiber dafür kompatibel. Gerne prüfen wir, ob Ihr Gerät dafür eingesetzt werden kann.</p>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <span><strong>EC-Gerät prüfen</strong></span>
                <div class="form-text">Die Freischaltung eines EC-Cash-Terminals ist mit einer zusätzlichen Freischaltgebühr verbunden <router-link :to="{ name: 'PartnerConditionsOverview' }" target="_blank">(s. aktuelle Preisliste)</router-link>. Ein/e Mitarbeiter/in von uns wird sich in Kürze mit Ihnen in Verbindung setzen, sobald die Prüfung Ihres EC-Gerätes abgeschlossen ist.</div>
                <div class="pt-2 border-bottom pb-3">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEcManufacturer" class="form-label label-view">Hersteller</label>
                      <input v-model="handlingAtPosData.ecManufacturer" type="text" id="ecManufacturer" class="form-control input-view" placeholder="Hersteller eingeben">
                      <div id="ecManufacturerNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEcType" class="form-label label-view">Typ EC-Gerät</label>
                      <input v-model="handlingAtPosData.ecType" type="text" id="ecType" class="form-control input-view" placeholder="Typ des EC-Gerätes eingeben">
                      <div id="ecTypeNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEcTerminalID" class="form-label label-view">Terminal-ID (TID)</label>
                      <input v-model="handlingAtPosData.ecTerminalID" type="text" id="ecTerminalID" class="form-control input-view" placeholder="Terminal-ID eingeben">
                      <div class="form-text">Sie finden Ihre TID auf jedem Händlerbeleg (s. Foto) </div>
                      <div id="ecTerminalIDNote" class="form-text error-note"></div>
                    </div>
                    <div class="col-lg-4">
                      <img class="img-fluid" :src="require('../assets/img/ec-terminal-tid-beleg.png')">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputEcSerialNumber" class="form-label label-view">Seriennummer</label>
                      <input v-model="handlingAtPosData.ecSerialNumber" type="text" id="ecSerialNumber" class="form-control input-view" placeholder="Seriennummer eingeben">
                      <div id="ecSerialNumberNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-lg-7">
                      <div class="form-check">
                        <input v-model="handlingAtPosData.ecCashpointIntegration" class="form-check-input" type="checkbox" value="" id="ecCashpointIntegration">
                        <label class="form-check-label" for="ecCashpointIntegration">
                          Integration in Handelskasse
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="handlingAtPosData.ecCashpointIntegration === true">
                    <div class="col-lg-7">
                      <label for="inputEcCashpointIntegrationManufacturer" class="form-label label-view">Anbieter Handelskassensoftware</label>
                      <input v-model="handlingAtPosData.ecCashpointIntegrationManufacturer" type="text" id="ecCashpointIntegrationManufacturer" class="form-control input-view" placeholder="Anbieter Handelskassensoftware eingeben">
                      <div id="ecCashpointIntegrationManufacturerNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div id="handlingAtPOSEcDeviceNote" class="form-text error-note"></div>
              </div>
              <div id="handlingAtPOSNote" class="form-text error-note"></div>
            </div>
            <button @click="checkEcTerminal()" href="#" class="btn btn-primary">EC-Terminal prüfen lassen &gt;&gt;</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row-full-width bg-light-gray py-5 mt-5 card-primary-color" v-if="isInterested">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="fw-bold">Sie haben Fragen zur Teilnahme an der {{ this.$store.state.user.cardName }}? Dann vereinbaren Sie direkt einen kostenfreien Online-Beratungstermin oder nehmen Sie Kontakt mit uns auf.</p>
            <router-link :to="{ name: 'Terminbuchung' }" class="btn btn-primary me-4">Beratungstermin vereinbaren &gt;&gt;</router-link>
            <router-link :to="{ name: 'PartnerContact' }" class="btn btn-primary">Kontakt aufnehmen &gt;&gt;</router-link>
            <p class="fw-bold mt-5">Sie haben bereits alle Informationen und möchten Partner der {{ this.$store.state.user.cardName }} werden, dann können Sie hier Ihren Partnervertrag online abschließen.</p>
            <router-link :to="{ name: 'PartnerWerden' }" class="btn btn-primary btn-lg">Partner werden &gt;&gt;</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-1 py-md-5">
      <div class="row mt-5 g-0">
        <div class="col-12 col-lg-4 card-primary-background-color text-white rounded-start p-4">
          <h2 class="text-white">FAQ</h2>
          <p>Sie haben Fragen rund um die {{ this.$store.state.user.cardName }}? In unseren FAQ finden Sie die Antwort auf die meisten Fragen.</p>
          <p>Wurde ihre Frage nicht beantwortet? Dann können Sie sich über das Kontaktformular gerne an uns wenden.</p>
          <p>Ihr Ansprechpartner vor Ort:</p>
          <p>
            <span v-html="contactPerson"></span><br />
            <span v-html="email"></span><br />
            <span v-html="phone"></span><br />
          </p>
        </div>
        <div class="col-12 col-lg-8">
          <img class="img-fluid" :src="require('../assets/img/partner-info/frau-mit-einkaufstaschen.jpg')">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="accordion" id="accordionFAQ">
            <div v-for="(faq, index) in partnerFAQs" :key="index">
              <div class="accordion-item">
                <h2 class="accordion-header" :id="`heading${index}`">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" :aria-expanded="index === 0? 'true' : 'false'" :aria-controls="`collapse${index}`">
                    {{ faq.question }}
                  </button>
                </h2>
                <div :id="`collapse${index}`" class="accordion-collapse collapse" :class="index === 0? 'show' : ''" :aria-labelledby="`heading${index}`" data-bs-parent="#accordionFAQ">
                  <div class="accordion-body">
                    <div v-html="faq.answer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import UserRoles from './../enums/UserRoles'

export default {
  name: 'PartnerInfoPage',
  components: {
    CardData
  },
  data () {
    return {
      handlingAtPosData: {},
      partnerFAQs: [],
      contactPerson: '',
      email: '',
      phone: '',
      regionPersonName: ''
    }
  },
  methods: {
    async fetchPartnerFAQs () {
      this.$store.commit('SET_LOADING_STATUS', true)
      let requestToDispatch = 'getPartnerFAQs'
      if (this.isInterested) {
        requestToDispatch = 'getInterestPartnerFAQs'
      }
      await this.$store.dispatch(requestToDispatch, { regionID: this.$store.state.region.id }).then(response => {
        if (this.isInterested) {
          this.partnerFAQs = response.acf.interest_partner_faqs
        } else {
          this.partnerFAQs = response.acf.partner_faqs
        }
        this.contactPerson = response.acf.contact_person
        this.email = response.acf.email
        this.phone = response.acf.phone
        this.regionPersonName = response.acf.personenbezeichnung
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async checkEcTerminal () {
      if (!this.handlingAtPosData.ecManufacturer || this.handlingAtPosData.ecManufacturer === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen den Hersteller des EC-Geräts angeben.' })
        return
      }
      if (!this.handlingAtPosData.ecType || this.handlingAtPosData.ecType === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen den Typ des EC-Geräts angeben.' })
        return
      }
      if (!this.handlingAtPosData.ecTerminalID || this.handlingAtPosData.ecTerminalID === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Terminal-ID des EC-Geräts angeben.' })
        return
      }

      if (!this.handlingAtPosData.ecSerialNumber || this.handlingAtPosData.ecSerialNumber === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Seriennummer des EC-Geräts angeben.' })
        return
      }

      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('checkEcTerminal', this.handlingAtPosData).then(() => {
      }).finally(() => {
        this.$store.commit('SET_LOADING_STATUS', false)
      })
    }
  },
  computed: {
    isInterested () {
      return this.$store.state.user.role === UserRoles.INTERESTED
    }
  },
  created () {
    this.fetchPartnerFAQs()
  }
}
</script>
