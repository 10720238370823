<template>
  <div v-if="hasAlerts" class="toast-container position-fixed top-0 end-0 mt-5 p-3">
    <div v-for="(alert, index) in alerts" :key="'alertToast' + index" class="toast show">
      <div class="toast-header" :class="alert.category === 'Fehler' ? 'bg-danger' : 'bg-success'">
        <strong class="text-white me-auto">{{ alert.category }}</strong>
        <button @click="deleteAlert(index)" type="button" class="btn-close btn-close-white" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ alert.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertComponent',
  data () {
    return {
      alerts: this.$store.state.alerts
    }
  },
  computed: {
    hasAlerts () {
      return this.$store.state.alerts.length
    }
  },
  methods: {
    deleteAlert (index) {
      this.$store.commit('DELETE_ALERT', index)
    }
  }
}
</script>
