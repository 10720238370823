<template>
  <header v-if="isLoggedIn">
    <nav class="navbar navbar-expand-xxl navbar-light bg-white" v-if="!this.$route.name || this.$route.name !== 'Booking'">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img :src="require(`../assets/img/cards-logos/${cardImage}-logo.png`)" alt="Logo" width="180">
        </a>
        <button id="navbar-toggler-button" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mainMenu">
          <div class="navbar-nav mt-1 ms-auto">
            <router-link :to="{ name: 'PushNotifications' }" class="nav-link" v-if="isTrolleymaker">Push-Benachrichtigungen</router-link>
            <router-link :to="{ name: 'PasswordResetList' }" class="nav-link" v-if="isTrolleymaker">Passwort zurücksetzen Liste</router-link>
            <router-link :to="{ name: 'CheckBalanceLinkGenerator' }" class="nav-link" v-if="isTrolleymaker">Guthabenabfrage-Link Generator</router-link>
            <router-link :to="{ name: 'Dashboard' }" class="nav-link" v-if="isCustomer">Übersicht</router-link>
            <router-link :to="{ name: 'Booking' }" class="nav-link" v-if="isPartner">Buchung</router-link>
            <div class="dropdown" v-if="isInterestedToNotEmployer">
              <span class="nav-link dropdown-toggle" id="demoDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Demos
              </span>
              <ul class="dropdown-menu" aria-labelledby="demoDropdown">
                <li><router-link :to="{ name: 'DemoBooking' }" class="dropdown-item" v-if="isInterestedToNotEmployer">Demo-Buchung</router-link></li>
                <li><router-link :to="{ name: 'DemoTransactions' }" class="dropdown-item" v-if="isInterestedToNotEmployer">Demo-Transaktionen</router-link></li>
                <li><router-link :to="{ name: 'InterestDemoStatistics' }" class="dropdown-item" v-if="isInterestedToNotEmployer">Demo-Auswertungen</router-link></li>
              </ul>
            </div>
           <!--<router-link :to="{ name: 'EmployerManageCards' }" class="nav-link" v-if="isEmployer">Kartenverwaltung</router-link> -->
            <div class="dropdown" v-if="isInterested || isPartner || isEmployer">
              <span class="nav-link dropdown-toggle" id="infosDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Infos
              </span>
              <ul class="dropdown-menu" aria-labelledby="infosDropdown">
                <li><router-link :to="{ name: 'PartnerInfo' }" class="dropdown-item" v-if="isInterested || isPartner || isEmployer">für Partner</router-link></li>
                <li><router-link :to="{ name: 'EmployerInfo' }" class="dropdown-item" v-if="isInterested || isPartner || isEmployer">für Arbeitgeber</router-link></li>
              </ul>
            </div>
            <router-link :to="{ name: 'BonusInfo' }" class="nav-link" v-if="isInterestedToNotEmployer">Bonus Infos</router-link>
            <div class="dropdown" v-if="isPartner">
              <span class="nav-link dropdown-toggle" id="bonusDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Bonus
              </span>
              <ul class="dropdown-menu" aria-labelledby="bonusDropdown">
                <li><router-link :to="{ name: 'BonusInfo' }" class="dropdown-item" v-if="isPartner">Bonus-Info</router-link></li>
                <li><router-link :to="{ name: 'Bonus' }" class="dropdown-item" v-if="isPartner && isPartnerAdmin">Meinen Bonus verwalten</router-link></li>
              </ul>
            </div>
            <router-link :to="{ name: 'EmployerManageCards' }" class="nav-link" v-if="isEmployer && isRegionLeinfeldenEchterdingen">MitarbeiterCARDs verwalten</router-link>
            <router-link :to="{ name: 'PersonalData' }" class="nav-link" v-if="isCustomer">Persönliche Daten</router-link>
            <router-link :to="{ name: 'PartnerPersonalData' }" class="nav-link" v-if="isPartner && isPartnerAdmin">Persönliche Daten</router-link>
            <router-link :to="{ name: 'EmployerPersonalData' }" class="nav-link" v-if="isEmployer && isPartnerAdmin">Persönliche Daten</router-link>
            <router-link :to="{ name: 'UpdatePassword' }" class="nav-link" v-if="isCustomer">Passwort ändern</router-link>
            <router-link :to="{ name: 'BookingOverview' }" class="nav-link" v-if="isCustomer">Buchungsübersicht</router-link>
            <div class="dropdown border border-2 border-primary" v-if="isInterested">
              <span class="nav-link dropdown-toggle" id="registrationDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Teilnehmen
              </span>
              <ul class="dropdown-menu" aria-labelledby="registrationDropdown">
                <li><router-link :to="{ name: 'PartnerWerden' }" class="dropdown-item" v-if="isInterestedToNotEmployer">als Partner</router-link></li>
                <li><router-link :to="{ name: 'ArbeitgeberWerden' }" class="dropdown-item" v-if="isInterested">als Arbeitgeber</router-link></li>
                <li><router-link :to="{ name: 'PartnerWerden' }" class="dropdown-item" v-if="isInterestedToNotEmployer">Beides</router-link></li>
              </ul>
            </div>
            <router-link :to="{ name: 'HelpFAQ' }" class="nav-link" v-if="isCustomer">Hilfe / FAQ</router-link>
            <router-link :to="{ name: 'LockCard' }" class="nav-link" v-if="isCustomer">{{ this.$store.state.user.cardName}} sperren / übertragen</router-link>
            <div class="dropdown" v-if="isPartner || isEmployer">
              <span class="nav-link dropdown-toggle" id="transactionsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Transaktionen
              </span>
              <ul class="dropdown-menu" aria-labelledby="transactionsDropdown">
                <li><router-link :to="{ name: 'PartnerTransactions' }" class="dropdown-item" v-if="isPartner || isEmployer">Transaktionenübersicht</router-link></li>
                <li><router-link :to="{ name: 'PartnerStatistics' }" class="dropdown-item" v-if="isPartner || isEmployer">Auswertungen</router-link></li>
                <li><router-link :to="{ name: 'PartnerEmployerDocuments' }" class="dropdown-item" v-if="(isPartner && isPartnerAdminOrBuchhaltung) || isEmployer">Dokumentenarchiv</router-link></li>
              </ul>
            </div>
            <router-link :to="{ name: 'PartnerCorrectionBooking' }" class="nav-link" v-if="isPartner">Korrekturbuchung</router-link>
            <router-link :to="{ name: 'Terminbuchung' }" class="nav-link" v-if="isPartner || isInterested || isEmployer">Terminbuchung</router-link>
            <router-link :to="{ name: 'Contact' }" class="nav-link"  v-if="isCustomer">Kontakt</router-link>
            <router-link :to="{ name: 'PartnerContact' }" class="nav-link" v-if="isPartner || isInterested || isEmployer">Kontakt</router-link>
            <span @click="logout" class="nav-link" role="button">Logout</span>
          </div>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-light bg-white" v-if="this.$route.name && this.$route.name === 'Booking'">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img :src="require(`../assets/img/cards-logos/${cardImage}-logo.png`)" alt="Logo" width="180">
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item dropdown booking-menu-dropdown">
            <a class="nav-link" data-bs-toggle="dropdown" id="bookingMenu" href="#" role="button" aria-expanded="false"><span class="navbar-toggler-icon"></span></a>
            <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="bookingMenu">
              <li><router-link :to="{ name: 'Booking' }" class="dropdown-item" v-if="isPartner">Buchung</router-link></li>
              <li><router-link :to="{ name: 'PartnerInfo' }" class="dropdown-item" v-if="isInterested || isPartner || isEmployer">Mein Portal</router-link></li>
              <li><router-link :to="{ name: 'PartnerTransactions' }" class="dropdown-item" v-if="isPartner">Transaktionen</router-link></li>
              <li><router-link :to="{ name: 'PartnerCorrectionBooking' }" class="dropdown-item" v-if="isPartner">Korrekturbuchung</router-link></li>
              <li><router-link :to="{ name: 'Contact' }" class="dropdown-item"  v-if="isCustomer">Kontakt</router-link></li>
              <li><router-link :to="{ name: 'PartnerContact' }" class="dropdown-item" v-if="isPartner || isInterested || isEmployer">Kontakt</router-link></li>
              <li><span @click="logout" class="dropdown-item" role="button">Logout</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import UserRoles from './../enums/UserRoles'
import PartnerUserRoles from './../enums/PartnerUserRoles'
import router from '../router'
const slugify = require('slugify')

export default {
  name: 'AppHeader',
  computed: {
    isLoggedIn () {
      return this.$store.state.loginStatus
    },
    isCustomer () {
      return this.$store.state.user.role === UserRoles.CUSTOMER
    },
    isPartner () {
      return this.$store.state.user.role === UserRoles.PARTNER
    },
    isInterested () {
      return this.$store.state.user.role === UserRoles.INTERESTED
    },
    isInterestedToNotEmployer () {
      return this.$store.state.user.role === UserRoles.INTERESTED && this.$store.state.user.partner_interest_in !== 'Arbeitgeber'
    },
    isInterestedOnlyEmployer () {
      return this.$store.state.user.role === UserRoles.INTERESTED && this.$store.state.user.partner_interest_in === 'Arbeitgeber'
    },
    isEmployer () {
      return this.$store.state.user.role === UserRoles.EMPLOYER
    },
    isPartnerAdmin () {
      return this.$store.state.user.partner_role === PartnerUserRoles.ADMIN
    },
    isPartnerAdminOrBuchhaltung () {
      return this.$store.state.user.partner_role === PartnerUserRoles.ADMIN || this.$store.state.user.partner_role === PartnerUserRoles.BUCHHALTUNG
    },
    isPartnerUser () {
      return this.$store.state.user.partner_role === PartnerUserRoles.ADMIN || this.$store.state.user.partner_role === PartnerUserRoles.USER
    },
    isPartnerBuchhaltung () {
      return this.$store.state.user.partner_role === PartnerUserRoles.ADMIN || this.$store.state.user.partner_role === PartnerUserRoles.USER || this.$store.state.user.partner_role === PartnerUserRoles.BUCHHALTUNG
    },
    isTrolleymaker () {
      return this.$store.state.user.role === UserRoles.TROLLEYMAKER
    },
    isRegionLeinfeldenEchterdingen() {
      return this.$store.state.region.name === 'Leinfelden-Echterdingen'
    },
    cardImage () {
      return slugify(this.$store.state.region.cardName && typeof this.$store.state.region.cardName !== 'undefined' ? this.$store.state.region.cardName : 'alle-regionen-logos', { lower: true, locale: 'de', trim: true })
    }
  },
  methods: {
    logout () {
      let redirectTo = 'Login'
      if (this.$store.state.user.role === UserRoles.PARTNER || this.$store.state.user.role === UserRoles.INTERESTED) {
        redirectTo = 'PartnerLogin'
      } else if (this.$store.state.user.role === UserRoles.EMPLOYER) {
        redirectTo = 'EmployerLogin'
      }
      this.$store.dispatch('logout').then(() => {
        router.push({ name: redirectTo })
      })
    }
  }
}
</script>

<style scoped>
.dropdown {
  cursor: pointer;
}
nav .nav-link {
  font-weight: 600;
  color: #878787;
}

#booking-menu-container {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 558px) {
  .dropdown.booking-menu-dropdown .dropdown-menu {
    left: -120px;
  }
}

@media (min-width: 558px) and (max-width: 1551px) {
  #dropdown.booking-menu-dropdown{
    left: -80px;
  }

  .dropdown.booking-menu-dropdown .dropdown-menu {
    left: -80px;
  }
}

</style>
