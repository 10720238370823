<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 text-center mb-5 mg-lg-0">
        <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1>Neues Passwort <span class="fw-bold">eingeben</span></h1>
          <div class="col-11 col-md-6 col-lg-8">
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <label for="inputPassword" class="form-label label-view">Neues Passwort</label>
                  <div class="input-group flex-nowrap">
                    <input v-model="password" @input="passwordChecker" :type="passwordInputType" class="form-control" id="inputPassword" placeholder="Neues Passwort eingeben..." />
                    <span class="input-group-text"  id="changePasswordType" @click="togglePasswordVisibility()"><img :src="require(`../assets/img/${showPasswordIcon}.svg`)" width="20" height="20" /></span>
                  </div>
                  <div id="passwordNote" class="form-text error-note"></div>
                  <div id="passwordChecker" class="form-text text-start">
                      <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                      <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                      <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                      <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <label for="inputPasswordRepeated" class="form-label label-view">Neues Passwort wiederholen</label>
                  <input v-model="passwordRepeated" type="password" id="passwordRepeated" class="form-control input-view" placeholder="Neues Passwort wiederholen">
                  <div id="passwordRepeatedNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <label for="inputCaptcha">Captcha-Code</label>
                  <VueClientRecaptcha
                    :value="captchaInputValue"
                    chars="abcdefghkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789"
                    @isValid="checkValidCaptcha"
                  >
                    <template #icon>
                      <img width="60" style="width: 60px;" :src="require(`../assets/img/black-refresh-icon.png`)">
                    </template>
                  </VueClientRecaptcha>
                </div>
              </div>
            </div>

            <div class="form my-3 fs-3">
              <input v-model="captchaInputValue" type="text" class="form-control" id="inputCaptcha" placeholder="Captcha-Code eingeben..." />
            </div>
            <div class="row justify-content-end">
              <div class="col-lg-11">
                <button @click="savePassword" class="w-100 btn btn-lg btn-primary">Neues Passwort speichern >></button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Kunden</strong>konto? <router-link :to="{ name: 'Login' }">Hier geht es zum Login</router-link></p>
              </div>
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Partner</strong>konto? <router-link :to="{ name: 'PartnerLogin' }">Hier geht es zum Login</router-link></p>
              </div>
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Arbeitgeber</strong>konto? <router-link :to="{ name: 'EmployerLogin' }">Hier geht es zum Login</router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import VueClientRecaptcha from 'vue-client-recaptcha'
const slugify = require('slugify')

export default {
  name: 'NewPasswordPage',
  data () {
    return {
      token: '',
      password: '',
      passwordRepeated: '',
      captchaInputValue: '',
      isValidCaptcha: false,
      passwordInputType: 'password',
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg'
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    showPasswordIcon () {
      return this.passwordInputType === 'password' ? 'eye-icon' : 'eye-hide-icon'
    }
  },
  created() {
    this.token = this.$route.query.t
  },
  methods: {
    savePassword() {
      if (!this.token || this.token === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Der Link ist ungültig!' })
        return
      }
      if (!this.password || this.password === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen Ihr neues Passwort eingeben.' })
        return
      }
      if (!this.passwordRepeated || this.passwordRepeated === '') {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen Ihr neues Passwort wiederholen eingeben.' })
        return
      }
      if (this.password !== this.passwordRepeated) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die beiden Passwörter stimmen nicht überein.' })
        return
      }
      if (!this.isValidCaptcha) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Der eingegebene Captcha ist falsch.' })
        return
      }

      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('newPassword', { t: this.token, password: this.password, passwordRepeated: this.passwordRepeated })
        .then(() => {
          this.token = ''
          this.password = ''
          this.passwordRepeated = ''
          this.captchaInputValue = ''
        })
        .catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    passwordChecker () {
      if (/[A-ZÄÖÜ]+/.test(this.password)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(this.password)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(this.password)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (this.password.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    togglePasswordVisibility () {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    checkValidCaptcha (value) {
      this.isValidCaptcha = value
    }
  },
  components: {
    VueClientRecaptcha
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }
</style>
