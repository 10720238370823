<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>FAQ-<span class="fw-bold">Übersicht</span></h1>
        <p>Antworten auf häufig gestellte Fragen</p>
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Wieviel kostet die {{ this.$store.state.user.cardName}}?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Die {{ this.$store.state.user.cardName}} ist für Sie kostenlos bei teilnehmenden Partnern erhältlich.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Wie kann ich mit der {{ this.$store.state.user.cardName}} Bonus sammeln?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Sie können mit Ihrer {{ this.$store.state.user.cardName}} ganz einfach Bonus sammeln. Legen Sie diese bei jedem Einkauf bei den teilnehmenden Partnern vor und erhalten so automatisch den Bonus auf Ihren Einkauf. Der Bonus kann in Form einer Gutschrift auf Ihre {{ this.$store.state.user.cardName}} erfolgen oder in sonstiger Form. Welchen Bonus der Partner vergibt, sehen Sie auf der Homepage der {{ this.$store.state.user.cardName}} oder in der {{ this.$store.state.user.cardName}} APP.
                Bitte beachten Sie, dass nicht jeder Partner einen Bonus vergeben kann, da eventuell gesetzliche Regelungen Boni oder sonstige Zugaben verbieten.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Ich habe meine {{ this.$store.state.user.cardName}} verloren, wie kann ich mein Guthaben übertragen lassen?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Eine Guthabenübertragung ist nur möglich, wenn Sie Ihre {{ this.$store.state.user.cardName}} bereits registriert haben. Sollte Ihre {{ this.$store.state.user.cardName}} bereits registriert sein, wenden Sie sich bitte direkt per E-Mail an <a href="mailto:verlust@trolleymaker.com">verlust@trolleymaker.com</a>, um die Karte sperren zu lassen. Bis zu Sperrung der Karte kann diese leider von jedem Inhaber der Karte eingesetzt und das Guthaben eingelöst werden. Etwaiges Guthaben kann nach der Sperrung auf eine neue Karte übertragen werden. Holen Sie sich hierzu bei einem der Partnerunternehmen einfach eine neue {{ this.$store.state.user.cardName}} und wenden Sie sich unter obengenannten E-Mail wieder an uns.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Wofür kann ich die {{ this.$store.state.user.cardName}} verwenden?
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Die {{ this.$store.state.user.cardName}} ist GutscheinCARD und BonusCARD in einem. Sie können die {{ this.$store.state.user.cardName}} als Gutschein erwerben und mit bis zu 250 EUR Guthaben beladen lassen. Außerdem kann mit der {{ this.$store.state.user.cardName}} bei den teilnehmenden Partnern zusätzlich Bonus gesammelt werden. So profitieren Sie von jedem Einkauf und sammeln zusätzliches Guthaben an. Bonus- und Gutschein-Guthaben können ganz oder teilweise auch wieder bei jedem Partner zur Bezahlung eingesetzt werden.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Wo kann ich einen {{ this.$store.state.user.cardName}} Gutschein kaufen?
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Sie können die {{ this.$store.state.user.cardName}} kostenlos bei jedem teilnehmenden Partner erhalten und zahlen lediglich den Betrag der als Gutschein aufgeladen werden soll.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Welchen Gutscheinwert kann ich auf die {{ this.$store.state.user.cardName}} aufladen lassen?
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Sie können die {{ this.$store.state.user.cardName}} mit jedem beliebigen Wert zwischen 1 und 250 EUR als Gutschein Guthaben aufladen lassen. Das aufgebucht Guthaben kann ganz oder teilweise zur Bezahlung bei den Partnerunternehmen eingesetzt werden. Die {{ this.$store.state.user.cardName}} kann immer wieder neu beladen werden.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                Kann ich die {{ this.$store.state.user.cardName}} auch online bestellen?
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Sie erhalten die {{ this.$store.state.user.cardName}} kostenlos bei jedem der teilnehmenden Partnerunternehmen vor Ort.
Wenn es mal schnell gehen muss und Sie noch einen Gutschein kaufen wollen, können Sie alternativ auch den Online-Gutschein der {{ this.$store.state.user.cardName}} über den Gutschein-Shop auf der Homepage erwerben.
Der Gutschein kann rund um die Uhr bestellt werden und wird Ihnen im Anschluss an den Kauf direkt per E-Mail als PDF zugesandt. Alternativ können Sie den Gutschein dem Beschenkten mit einer Nachricht per E-Mail zusenden lassen.
Der PDF-Gutschein der {{ this.$store.state.user.cardName}} kann genau wie die physische {{ this.$store.state.user.cardName}} bei jedem Partner zum Bezahlen verwendet und ganz oder teilweise eingelöst werden.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                Ich habe bereits eine {{ this.$store.state.user.cardName}} und habe eine neue als Gutschein geschenkt bekommen.
Kann ich das Guthaben übertragen lassen?
              </button>
            </h2>
            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Eine Guthabenübertragung ist nur möglich, wenn Ihre „Hauptkarte“, welche sie zukünftig verwenden wollen, registriert ist. Ihre {{ this.$store.state.user.cardName }} können Sie <router-link :to="{ name: 'Registration' }">hier registrieren</router-link> (Sie müssen ausgeloggt sein, damit der Link funktioniert).
<br />
Wenn Ihre {{ this.$store.state.user.cardName}} bereits registriert ist, können Sie <router-link :to="{ name: 'LockCard' }">hier das Guthaben übertragen lassen</router-link>.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingNine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                Wie lange ist das Guthaben auf der {{ this.$store.state.user.cardName}} gültig?
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Das Guthaben verfällt nur, wenn die Karte über einen Zeitraum von 3 vollen Kalenderjahren nicht genutzt wurde, d.h. keine Buchung mit der Karte getätigt wurde, zum 31.12. des 3. Jahres. Sofern die Karte registriert wurde, informieren wir den Karteninhaber mindestens 6 Monate vorher über seine bei der Registrierung angegebene E-Mail-Adresse.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTen">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                Mein Lieblingsgeschäft ist laut {{ this.$store.state.user.cardName}} Homepage kein Partner der {{ this.$store.state.user.cardName}}. Was kann ich tun?
              </button>
            </h2>
            <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Wenn Ihr Lieblingsgeschäft noch kein Partner der {{ this.$store.state.user.cardName}} ist, sprechen Sie das gerne direkt im Geschäft an. Alternativ können Sie uns auch gerne Ihre Wunsch-Shops per E-Mail mitteilen. Wir lassen dem Unternehmen dann gerne weitere Infos zukommen. Bitte senden Sie Ihre E-Mail dafür an <a href="mailto:support@trolleymaker.com">support@trolleymaker.com</a>.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEleven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                Muss ich mit der {{ this.$store.state.user.cardName}} bezahlen, um einen Einkaufsbonus zu erhalten?
              </button>
            </h2>
            <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Nein, um einen Einkaufsbonus zu erhalten, spielt es keine Rolle wie Sie bezahlen.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-sm-9">
            <span>Sie haben die Antwort auf Ihre Frage nicht gefunden? Dann nehmen Sie ganz einfach Kontakt zu uns auf.</span>
          </div>
          <div class="col-12 col-sm-3 text-start text-sm-end pt-3 pt-sm-0">
            <router-link to="/contact" class="btn btn-primary" role="button">Kontakt >></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'HelpFAQPage',
  components: {
    CardData
  }
}
</script>

<style scoped>
#accordionFAQ .accordion-item h2 {
  margin-top: 0;
}
</style>
