<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row">
      <div class="col-12">
        <h1>Kartenliste <span class="fw-bold"> hochladen & Guthabenabfrage-Link generieren</span></h1>
        <strong>Beispiel der hochzuladenden CSV Datei:</strong><br />
        <span class="fst-italic">(CSV-Datei mit nur einer Spalte mit den Kartennummern und <strong>ohne</strong> erste Zeile Tabellenkopf)</span>
      </div>
      <div class="col-auto">
        <table class="table table-bordered">
          <tr>
            <td>176123456789101</td>
          </tr>
          <tr>
            <td>176123456789102</td>
          </tr>
          <tr>
            <td>176123456789103</td>
          </tr>
          <tr>
            <td>.....</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 pt-2">
        <label for="inputLogo" class="form-label label-view fw-bold fs-3">
          CSV-Datei mit Liste der Kartennummern hochladen
        </label>
        <input type="file" class="form-control" @change="onCardsListFileSet" ref="inputCardsListFile" accept=".csv">
      </div>
      <div class="col-12 col-lg-8">
        <div class="text-end mt-4 mt-sm-2">
          <button type="submit" @click="sendUploadCardsListFile()" class="btn btn-primary">Kartenliste hochladen & Guthabenabfrage-Links generieren >></button>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
  name: 'CheckBalanceLinkGeneratorPage',
  data () {
    return {
      cardsListFile: undefined
    }
  },
  methods: {
    onCardsListFileSet (event) {
      this.cardsListFile = event.target.files[0]
    },
    async sendUploadCardsListFile () {
      if (!this.cardsListFile) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Datei zum Hochladen angegeben.' })
        return
      }

      this.$store.commit('DELETE_ALERTS')

      this.$store.commit('SET_LOADING_STATUS', true)
      const formData = new FormData()
      formData.append('cardsListFile', this.cardsListFile)
      this.$store.dispatch('uploadGenerateCheckBalanceURLs', formData)
        .then(() => {
          // clear form
          this.cardsListFile = undefined
          this.$refs.inputCardsListFile.value = null
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  }
}
</script>
