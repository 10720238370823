<template>
  <div class="container py-1 py-md-5">
    <div id="barcodeReader"></div>
    <div class="content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-3 text-center">
          <CardData />
        </div>
      </div>
      <div class="row justify-content-center text-center">
        <div class="col-12 col-lg-6">
          <p class="fs-4">Kartennummer <strong>{{ this.cardIDText }}</strong></p>
          <p class="fs-4 mt-2">Eingabefeld/bzw. Scan durch Kamera/Barcode-Scanner</p>
          <div class="input-group mb-3">
            <input v-model="cardID" @keyup="enableGuthabenAufladenButton()" @keydown.enter="checkBalance()" ref="inputCardID" type="text" class="form-control" id="inputCardID" aria-describedby="check-balance-button" placeholder="Eingabefeld Nummer">
            <button @click="startScanBarcode()" class="btn btn-secondary mx-2" id="scan-barcode-button" type="button"><img class="d-block img-fluid" :src="require('../assets/img/booking/barcode.svg')" width="30"></button>
            <button @click="checkBalance()" class="btn btn-primary" id="check-balance-button" type="button">Guthaben abfragen</button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center text-center" v-if="this.balance">
        <span class="fs-1 fw-light">Guthaben <strong>{{ this.balance }}</strong> Euro</span>
        <span class="fs-4 mt-2" :class="isCardRegistered === true? 'text-green' : 'text-red'">{{ this.isCardRegisteredText }}</span>
        <span class="fs-4 mt-2 text-danger" v-if="isTestcard === true">Achtung: Dies ist eine Testkarte</span>
        <span class="fs-4 mt-2" :class="isGuthabenAufladenButtonActive === true && this.isTestcard === false? 'text-green' : 'text-red'">{{ this.remainingAmountToAddVoucherThisMonthText }}</span>
      </div>
      <div class="row justify-content-center text-center mt-4">
        <div class="col-12 col-lg-3">
          <div class="col-12 col-xxl-11 px-3 px-lg-2 px-xxl-3 pt-1 pb-3 rounded border border-3" style="border-color: #e30513 !important;">
            <img class="d-block img-fluid float-end" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Ihr Kunde bezahlt mit der Karte. Bitte den <strong>Zahlbetrag</strong> eingeben.">
            <img class="d-block img-fluid mx-auto mt-3" :src="require('../assets/img/booking/guthaben-einloesen-icon.png')" width="60">
            <p class="fw-bold mt-2">Guthaben einlösen</p>
            <input v-model.trim="guthabenEinloesenBetrag" @keyup="formatGuthabenEinloesenBetrag()" type="text" class="form-control" placeholder="Eingabefeld Betrag xx,xx" :disabled="isRequestRunning" :readonly="isRequestRunning">
            <div class="d-grid mt-2">
              <button @click="guthabenEinloesen()" class="btn btn-primary fw-bold" id="check-balance-button" type="button" style="background-color: #e30513; border: none;" :disabled="isRequestRunning" :readonly="isRequestRunning">Guthaben einlösen</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-3 mt-lg-0">
          <div class="col-12 col-xxl-11 px-3 px-lg-2 px-xxl-3 pt-1 pb-3 rounded border border-3" :class="isBonusActive === false ? 'disabled-kundenbonus' : ''" style="border-color: #f8ac00 !important;">
            <img class="d-block img-fluid float-end" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Ihr Kunde erhält einen Bonus auf seinen Einkauf. Bitte den <strong>Einkaufsbetrag</strong> eingeben.">
            <img class="d-block img-fluid mx-auto mt-3" :src="require('../assets/img/booking/kundenbonus-aufladen-icon.png')" width="60">
            <p class="fw-bold mt-2">Kundenbonus vergeben</p>
            <input v-model.trim="kundenbonusAufladenBetrag" @keyup="formatKundenbonusAufladenBetrag()" :disabled="!isBonusActive || isRequestRunning" :readonly="!isBonusActive || isRequestRunning" type="text" class="form-control" placeholder="Eingabefeld Betrag xx,xx">
            <div class="d-grid mt-2">
              <button @click="kundenbonusAufladen()" :disabled="!isBonusActive || isRequestRunning" :readonly="!isBonusActive || isRequestRunning" class="btn btn-primary fw-bold" id="check-balance-button" type="button" style="background-color: #f8ac00; border: none;">Kundenbonus vergeben</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-3 mt-lg-0">
          <div class="col-12 col-xxl-11 px-3 px-lg-2 px-xxl-3 pt-1 pb-3 rounded border border-3" style="border-color: #5db12f !important;">
            <img class="d-block img-fluid float-end" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Ihr Kunde möchte Guthaben auf die Karte aufladen. Bitte den <strong>Aufladebetrag</strong> eingeben.">
            <img class="d-block img-fluid mx-auto mt-3" :src="require('../assets/img/booking/guthaben-aufladen-icon.png')" width="60">
            <p class="fw-bold mt-2">Guthaben aufladen</p>
            <input v-model.trim="guthabenAufladenBetrag" @keyup="formatGuthabenAufladenBetrag()" type="text" class="form-control" placeholder="Eingabefeld Betrag xx,xx" :disabled="this.requestIsRunning || !isGuthabenAufladenButtonActive">
            <div class="d-grid mt-2">
              <button @click="guthabenAufladen()" class="btn btn-primary fw-bold" id="check-balance-button" type="button" style="background-color: #5db12f; border: none;" :disabled="isRequestRunning || !isGuthabenAufladenButtonActive" :readonly="isRequestRunning || !isGuthabenAufladenButtonActive">Guthaben aufladen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="resultModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-black">{{ this.result.bookingType }} für Kartennummer {{ this.cardID }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <img class="d-block img-fluid mx-auto" :src="this.result.responseType === 'success' ? require('../assets/img/booking/success-icon.png') : require('../assets/img/booking/error-icon.png')" width="60">
            <p class="fs-1 fw-bold" :class="{'text-success' : this.result.responseType === 'success', 'text-danger' : this.result.responseType === 'error'}"> {{ this.result.responseType === 'success' ? 'Buchung erfolgreich!' : 'Buchung fehlgeschlagen!' }}</p>
            <p v-if="this.result.responseType === 'success' && this.result.successMessage" class="fs-3 text-success" v-html="this.result.successMessage"></p>
            <p v-if="this.result.responseType === 'error' && this.result.errorMessage" class="fs-3 text-danger" v-html="this.result.errorMessage"></p>
          </div>
          <div class="modal-footer">
            <!-- <button :disabled="this.result.responseType !== 'success'" type="button" class="btn btn-primary btn-lg" @click="printCustomerReceipt()">Kundenbeleg drucken</button>
            <button :disabled="this.result.responseType !== 'success'" type="button" class="btn btn-primary btn-lg" @click="printDealerReceipt()">Händlerbeleg drucken</button> -->
            <button type="button" class="btn btn-primary btn-lg float-end" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
    <div id="print-placeholder" ref="printPlaceholder"></div>
  </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import { Html5Qrcode, Html5QrcodeSupportedFormats, Html5QrcodeScanType } from 'html5-qrcode'
const bootstrap = require('bootstrap')

export default {
  name: 'PartnerBookingPage',
  components: {
    CardData
  },
  data () {
    return {
      cardID: '',
      cardIDText: 'scannen oder eingeben',
      balance: undefined,
      isCardRegistered: undefined,
      isCardRegisteredText: undefined,
      isTestcard: undefined,
      remainingAmountToAddVoucherThisMonthText: undefined,
      guthabenEinloesenBetrag: undefined,
      kundenbonusAufladenBetrag: undefined,
      guthabenAufladenBetrag: undefined,
      isGuthabenAufladenButtonActive: true,
      requestIsRunning: false,
      result: {
        bookingType: undefined,
        successMessage: undefined,
        errorMessage: undefined,
        responseType: undefined
      },
      resultModalBootstrapEl: undefined,
      bonusActive: false,
      html5QrCode: undefined
    }
  },
  computed: {
    isBonusActive () {
      return this.bonusActive
    },
    isRequestRunning () {
      return this.requestIsRunning
    }
  },
  methods: {
    formatGuthabenEinloesenBetrag () {
      this.kundenbonusAufladenBetrag = undefined
      this.guthabenAufladenBetrag = undefined
      this.guthabenEinloesenBetrag = this.formatBetrag(this.guthabenEinloesenBetrag)
    },
    formatKundenbonusAufladenBetrag () {
      this.guthabenEinloesenBetrag = undefined
      this.guthabenAufladenBetrag = undefined
      this.kundenbonusAufladenBetrag = this.formatBetrag(this.kundenbonusAufladenBetrag)
    },
    formatGuthabenAufladenBetrag () {
      this.guthabenEinloesenBetrag = undefined
      this.kundenbonusAufladenBetrag = undefined
      this.guthabenAufladenBetrag = this.formatBetrag(this.guthabenAufladenBetrag)
    },
    formatBetrag (betrag) {
      if (betrag) {
        if (betrag.replaceAll(',', '').length > 2) {
          if (betrag.includes(',')) {
            betrag = betrag.replaceAll(',', '')
          }

          betrag = betrag.slice(0, betrag.length - 2) +
                      ',' +
                      betrag.slice(betrag.length - 2, betrag.length)
        } else {
          betrag = betrag.replaceAll(',', '')
        }
        if (betrag.replaceAll('.', '').length > 6) {
          if (betrag.includes('.')) {
            betrag = betrag.replaceAll('.', '')
          }

          betrag = betrag.slice(0, betrag.length - 6) +
                      '.' +
                      betrag.slice(betrag.length - 6, betrag.length)
        } else {
          betrag = betrag.replaceAll('.', '')
        }
      }
      return betrag
    },
    validateBooking (bookingBetrag) {
      this.$store.commit('DELETE_ALERTS')
      let isValid = true
      const amountCent = this.getAmountCentForBetragInput(bookingBetrag)

      if (!this.cardID) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer eingegeben!' })
        isValid = false
      }

      if (!bookingBetrag) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag eingegeben!' })
        isValid = false
      }

      if (!(/^[0-9,.]+$/.test(bookingBetrag))) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es dürfen nur Zahlen (und Komma und Punkt) eingegeben werden!' })
        isValid = false
      }

      if ((bookingBetrag && bookingBetrag < 0) || (amountCent && amountCent < 0)) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es dürfen nur positive Beträge eingegeben werden!' })
        isValid = false
      }

      if (this.getAmountCentForBetragInput(this.guthabenAufladenBetrag) > (250 * 100)) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es dürfen nur maximal 250€ pro Kalendermonat aufgebucht werden!' })
        isValid = false
      }
      if (isValid === false) {
        this.requestIsRunning = false
      }
      return isValid
    },
    validateCheckBalance () {
      this.$store.commit('DELETE_ALERTS')
      if (!this.cardID) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer eingegeben!' })
        return false
      }
      if (!(/\d/.test(this.cardID))) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es dürfen nur Zahlen eingegeben werden!' })
        return false
      }
      if (!String(this.cardID).startsWith('176') || String(this.cardID).length > 15) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültige Kartennummer!' })
        return false
      }
      return true
    },
    async checkBalance () {
      if (this.validateCheckBalance()) {
        this.cardIDText = this.cardID
        this.$store.commit('SET_LOADING_STATUS', true)
        await this.$store.dispatch('partnerCheckBalance', { inputCardID: this.cardID })
          .then(response => {
            this.balance = response.balanceFormattedDE
            this.isCardRegistered = response.isCardRegistered
            if (response.isCardRegistered === true) {
              this.isCardRegisteredText = 'Diese CARD ist registriert 😊'
            } else {
              this.isCardRegisteredText = 'Ihr Kunde / Ihre Kundin sollte die CARD noch registrieren'
            }
            if (typeof response.isTestcard !== 'undefined' && response.isTestcard === true) {
              this.isTestcard = true
            } else {
              this.isTestcard = false
            }
            if (typeof response.remainingAmountCentToAddVoucherThisMonth !== 'undefined') {
              if (response.remainingAmountCentToAddVoucherThisMonth <= 0) {
                this.remainingAmountToAddVoucherThisMonthText = '250€ Aufladungsgrenze in diesem Monat erreicht'
                if (this.isTestcard === false) {
                  this.disableGuthabenAufladenButton()
                } else {
                  this.enableGuthabenAufladenButton()
                }
              } else {
                this.remainingAmountToAddVoucherThisMonthText = 'Verbleibende Aufladung diesen Monat: ' + response.remainingAmountToAddVoucherThisMonthFormattedDE + '€'
                this.enableGuthabenAufladenButton()
              }
            }
            this.$store.commit('SET_LOADING_STATUS', false)
          }).catch(() => {
            this.balance = undefined
            this.isCardRegistered = undefined
            this.isCardRegisteredText = undefined
            this.isTestcard = undefined
            this.remainingAmountToAddVoucherThisMonthText = undefined
            this.cardID = undefined
            this.cardIDText = 'scannen oder eingeben'
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      }
    },
    async guthabenEinloesen () {
      this.cardIDText = this.cardID
      this.requestIsRunning = true
      if (this.validateBooking(this.guthabenEinloesenBetrag)) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.result.bookingType = 'Guthaben einlösen'
        await this.$store.dispatch('partnerGuthabenEinloesen', { inputCardID: this.cardID, guthabenEinloesenBetrag: this.guthabenEinloesenBetrag }).then(response => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'success'
          this.result.successMessage = 'Guthaben einlösen für ' + this.guthabenEinloesenBetrag + ' €'
          this.result.errorMessage = ''
          this.result.errorStatusCode = ''
          this.resultModalBootstrapEl.show()
        }).catch((error) => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'error'
          this.result.errorMessage = error.errorMessage
          this.result.errorStatusCode = error.errorStatusCode
          this.resultModalBootstrapEl.show()
        })
      }
    },
    async kundenbonusAufladen () {
      this.cardIDText = this.cardID
      this.requestIsRunning = true
      if (this.validateBooking(this.kundenbonusAufladenBetrag)) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.result.bookingType = 'Kundenbonus vergeben'
        await this.$store.dispatch('partnerKundenbonusAufladen', { inputCardID: this.cardID, kundenbonusAufladenBetrag: this.kundenbonusAufladenBetrag }).then(response => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'success'
          this.result.successMessage = 'Kundenbonus vergeben für ' + this.kundenbonusAufladenBetrag + ' €'
          this.result.errorMessage = ''
          this.result.errorStatusCode = ''
          this.resultModalBootstrapEl.show()
        }).catch((error) => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'error'
          this.result.errorMessage = error.errorMessage
          this.result.errorStatusCode = error.errorStatusCode
          this.resultModalBootstrapEl.show()
        })
      }
    },
    async guthabenAufladen () {
      this.cardIDText = this.cardID
      this.requestIsRunning = true
      if (this.validateBooking(this.guthabenAufladenBetrag)) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.result.bookingType = 'Guthaben aufladen'
        await this.$store.dispatch('partnerGuthabenAufladen', { inputCardID: this.cardID, guthabenAufladenBetrag: this.guthabenAufladenBetrag }).then(response => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'success'
          this.result.successMessage = 'Guthaben aufladen für ' + this.guthabenAufladenBetrag + ' €'
          this.result.errorMessage = ''
          this.result.errorStatusCode = ''
          this.resultModalBootstrapEl.show()
        }).catch((error) => {
          this.$store.commit('SET_LOADING_STATUS', false)
          this.requestIsRunning = false
          this.result.responseType = 'error'
          this.result.errorMessage = error.errorMessage
          this.result.errorStatusCode = error.errorStatusCode
          this.resultModalBootstrapEl.show()
        })
      }
    },
    enableGuthabenAufladenButton() {
      this.isGuthabenAufladenButtonActive = true
    },
    disableGuthabenAufladenButton() {
      this.isGuthabenAufladenButtonActive = false
    },
    printDealerReceipt () {
      this.$store.commit('DELETE_ALERTS')
      this.$store.commit('SET_LOADING_STATUS', true)
      const receiptData = {}
      receiptData.receiptType = 'merchant'
      this.printReceipt(receiptData)
    },
    printCustomerReceipt () {
      this.$store.commit('DELETE_ALERTS')
      this.$store.commit('SET_LOADING_STATUS', true)
      const receiptData = {}
      receiptData.receiptType = 'customer'
      this.printReceipt(receiptData)
    },
    printReceipt (receiptData) {
      receiptData.inputCardID = this.cardID
      if (this.guthabenAufladenBetrag !== undefined && this.guthabenAufladenBetrag > 0) {
        receiptData.amount = this.guthabenAufladenBetrag
        receiptData.bookingType = 'addVoucher'
      } else if (this.guthabenEinloesenBetrag !== undefined && this.guthabenEinloesenBetrag > 0) {
        receiptData.amount = this.guthabenEinloesenBetrag
        receiptData.bookingType = 'redeemVoucher'
      } else if (this.kundenbonusAufladenBetrag !== undefined && this.kundenbonusAufladenBetrag > 0) {
        receiptData.amount = this.kundenbonusAufladenBetrag
        receiptData.bookingType = 'addBonus'
      } else {
        this.$store.commit('SET_LOADING_STATUS', false)
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.' })
        return
      }

      this.$store.dispatch('generateReceipt', receiptData).then(response => {
        const blob = new Blob([response], { type: 'application/pdf' })
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = window.URL.createObjectURL(blob)
        iframe.id = 'printIframe'
        iframe.onload = this.openPrint()
        this.$refs.printPlaceholder.appendChild(iframe)
      }).finally(() => {
        this.$store.commit('SET_LOADING_STATUS', false)
      })
    },
    openPrint() {
      const printIframe = document.getElementById('printIframe')
      if (printIframe && typeof printIframe.src !== 'undefined' && printIframe.src !== '') {
        // printIframe.contentWindow.focus()
        printIframe.contentWindow.print()
      }
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    getAmountCentForBetragInput (inputBetrag) {
      if (inputBetrag) {
        let amountCent
        if (!inputBetrag.includes(',') && !inputBetrag.includes('.')) {
          amountCent = parseInt(inputBetrag) * 100
        } else {
          if (inputBetrag.includes(',') && !inputBetrag.includes('.')) {
            const explodedBetrag = inputBetrag.split(',')
            const euroBetrag = parseInt(explodedBetrag[0])
            const centBetrag = parseInt(explodedBetrag[1])
            amountCent = euroBetrag * 100 + centBetrag
          }
          if (inputBetrag.includes(',') && inputBetrag.includes('.')) {
            const explodedTausenderBetrag = inputBetrag.split('.')
            const tausenderBetrag = parseInt(explodedTausenderBetrag[0])
            const explodedEuroBetrag = explodedTausenderBetrag[1].split(',')
            const euroBetrag = parseInt(explodedEuroBetrag[0])
            const centBetrag = parseInt(explodedEuroBetrag[1])
            amountCent = tausenderBetrag * 1000 * 100 + euroBetrag * 100 + centBetrag
          }
        }
        return amountCent
      }
      return undefined
    },
    async fetchData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getBookingData').then(response => {
        this.bonusActive = response.bonusActive
        this.setFocusInCardIDInputField()
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    setFocusInCardIDInputField () {
      this.$refs.inputCardID.focus()
    },
    startScanBarcode () {
      /* const qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
        const minEdgePercentage = 0.88 // 70%
        const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
        const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
        return {
          width: qrboxSize,
          height: qrboxSize
        }
      } */
      this.enableGuthabenAufladenButton()
      this.html5QrCode = new Html5Qrcode('barcodeReader', {
        formatsToSupport:
        [
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.AZTEC,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.CODE_93,
          Html5QrcodeSupportedFormats.RSS_14,
          Html5QrcodeSupportedFormats.UPC_A,
          Html5QrcodeSupportedFormats.UPC_E,
          Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
          Html5QrcodeSupportedFormats.EAN_8,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.PDF_417
        ]
      })
      const scanCodeSuccessCallback = (decodedText, decodedResult) => {
        const cardIDFromScan = decodedText.replace('\n', '').replace('\r', '')
        this.cardIDText = cardIDFromScan
        this.cardID = cardIDFromScan
        this.checkBalance()
        this.html5QrCode.stop().then((ignore) => { }).catch(() => { })
      }
      const config = { fps: 60, qrbox: 250, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], useBarCodeDetectorIfSupported: true }

      this.html5QrCode
        .start({ facingMode: 'environment' }, config, scanCodeSuccessCallback)
        .catch((err) => {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kamera konnte nicht gestartet werden. ' + err })
        })

      /*
      Html5Qrcode.getCameras().then(devices => {
        if (devices && devices.length) {
          const cameraId = devices[devices.length - 1].id

          this.html5QrCode
            .start(cameraId, config, scanCodeSuccessCallback)
            .catch((err) => {
              this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kamera konnte nicht gestartet werden. ' + err })
            })
        } else {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kamera konnte nicht gestartet werden.' })
        }
      }).catch(err => {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kamera konnte nicht gestartet werden. ' + err })
      })
      */
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {
    const resultModal = document.getElementById('resultModal')
    this.resultModalBootstrapEl = new bootstrap.Modal(resultModal)

    const superThis = this

    resultModal.addEventListener('hide.bs.modal', function (event) {
      if (superThis.result.errorStatusCode !== 'not sufficient funds') {
        superThis.balance = undefined
        superThis.isCardRegistered = undefined
        superThis.isCardRegisteredText = undefined
        superThis.isTestcard = undefined
        superThis.remainingAmountToAddVoucherThisMonthText = undefined
        superThis.cardID = ''
        superThis.cardIDText = 'scannen oder eingeben'
        superThis.guthabenEinloesenBetrag = undefined
        superThis.kundenbonusAufladenBetrag = undefined
        superThis.guthabenAufladenBetrag = undefined
        superThis.result.errorMessage = undefined
        superThis.result.successMessage = undefined
        superThis.result.responseType = undefined
        superThis.result.bookingType = undefined
      }
    })

    resultModal.addEventListener('hidden.bs.modal', function (event) {
      const printIframe = document.getElementById('printIframe')
      if (printIframe) {
        printIframe.parentElement.removeChild(printIframe)
      }
      if (superThis.result.errorStatusCode !== 'not sufficient funds') {
        superThis.result.errorStatusCode = undefined
        superThis.setFocusInCardIDInputField()
      }
    })

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  },
  beforeUnmount () {
    if (typeof this.html5QrCode !== 'undefined' && this.html5QrCode) {
      this.html5QrCode.stop().then((ignore) => { }).catch(() => { })
    }
  }
}
</script>

<style scoped>
.disabled-kundenbonus {
  opacity: 0.2;
}

.text-green {
  color: #5db12f !important;
}

.text-red {
  color: #e30513 !important;
}

#resultModal .modal-footer {
justify-content: flex-end;
}

</style>
