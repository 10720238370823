<template>
  <AppHeader></AppHeader>
    <Alert></Alert>
    <Loading></Loading>
    <router-view/>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import Alert from './components/Alert.vue'
import Loading from './components/Loading.vue'

export default {
  name: 'App',
  computed: {
    primaryColor () {
      if (this.$store.state.region && this.$store.state.region.primaryColor) {
        return this.$store.state.region.primaryColor
      } else {
        return '#AE0C20'
      }
    }
  },
  components: {
    AppHeader,
    AppFooter,
    Alert,
    Loading
  }
}
</script>

<style lang="scss">

:root {
  --bs-primary: v-bind('primaryColor') !important;
  --bg-primary: v-bind('primaryColor') !important;
}

#app {
  --bs-primary: v-bind('primaryColor') !important;
  --bg-primary: v-bind('primaryColor') !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  --bs-heading-color: v-bind(primaryColor) !important;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: v-bind('primaryColor') !important;
}

#app .bg-primary {
  background-color: v-bind('primaryColor') !important;
  color: #ffffff;
}

#app .text-primary {
  color: v-bind('primaryColor') !important;
}

.pagination {
  --bs-pagination-active-border-color : v-bind('primaryColor') !important;
  --bs-pagination-active-bg: v-bind('primaryColor') !important;
  --bs-link-color: v-bind('primaryColor') !important;
}

::-ms-reveal {
    display: none;
}

@media (min-width: 1024px) {
  html {
    overflow-x: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: v-bind('primaryColor');
}

h2, h3, h4, h5, h6 {
  margin-top: 20px;
}

h2, .h2 {
  font-size: calc(1.545rem + .9vw);
}

html, body {
  background-color: #f6f6f6 !important;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #f6f6f6;
  color: #2c3e50;
}

#app .label-view {
  margin-bottom: 5px;
}

#app .form-check-input[type=checkbox],
.btn {
  border-radius: 0 !important;
}

#app .form-switch .form-check-input {
  border-radius: 0.5em !important;
}

#app .form-control,
#app .form-select {
  background-color: #fff !important;
  border: 1px;
  border-color: #003882;
  border-radius: 0;
  box-shadow: 0 0 0 0.1rem #003882;
  padding-left: 5px;
  font-weight: bold;
}

#app .form-control:disabled,
#app .form-control[readonly],
#app .form-select:disabled {
  background-color: #75797c !important;
  opacity: 0.6;
}

#app .form-control.form-element-danger,
#app .form-select.form-element-danger {
  background-color: #fff;
  border: 2px solid #ff0000;
  border-radius: 0;
  padding-left: 5px;
}

#app .form-text.error-note {
  color: #ff0000;
}

#app input.form-control::placeholder,
#app textarea.form-control::placeholder {
  color: #6c757d;
  opacity: 0.4;
}

#app input[readonly].form-control,
#app input[readonly].form-select  {
  background-color: #dedede !important;
}

a,
p a,
div a,
.voucher-link,
.download-link {
  color: v-bind('primaryColor');
}

#app .card-primary-color {
  color: v-bind('primaryColor');
}

#app .card-primary-background-color {
  background-color: v-bind('primaryColor');
}

#app .btn-primary {
  background-color: v-bind('primaryColor');
  border-color: v-bind('primaryColor');
}

#app .btn-outline-primary {
  background-color: transparent;
  border-color: v-bind('primaryColor');
  color: v-bind('primaryColor');
}

#app .navbar-light .navbar-nav .nav-link.router-link-active,
#app .navbar-light .navbar-nav .nav-link:focus,
#app .navbar-light .navbar-nav .nav-link:hover {
  color: v-bind('primaryColor');
}

#app .accordion-button {
  color: v-bind('primaryColor');
  background-color: #ffffff;
  font-weight: 500;
}

#app .accordion-button:focus {
 border-color: v-bind('primaryColor');
 box-shadow: none;
}

#app .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#app .bg-lighter-gray {
  background-color: #EFEFEF;
}

#app .bg-light-gray {
  background-color: #E3E3E3;
}

#app .border-primary {
  border-color: v-bind('primaryColor') !important;
}

@media (min-width: 768px) {
  .row-full-width {
    width: 200%;
    margin-left: -50%;
  }
}

@media (max-width: 768px) {
  .row-full-width {
    margin-right: -12px;
    margin-left: -12px;
  }
}

.fill-primary-color,
.fill-primary {
  fill: v-bind('primaryColor');
}

.toast-container {
  z-index: 999;
}

#paypal-button-container,
#paypal-button-container iframe {
  width: 100%;
  height: 100%;
}

.nav-tabs .nav-link:not(.active) {
  color: v-bind('primaryColor') !important;
}

@media (min-width: 1367px) {
  #scan-barcode-button {
    display: none;
  }
}

#employer-cards-table th,
#employer-cards-table td {
  font-size: 15px;
}

#employer-cards-table .edit-record-button {
  cursor: pointer;
  width: 22px;
}

#employer-cards-table table.dataTable > tbody > tr > td.select-checkbox::before,
#employer-cards-table table.dataTable > tbody > tr > td.select-checkbox::after,
#employer-cards-table table.dataTable > tbody > tr > th.select-checkbox::before,
#employer-cards-table table.dataTable > tbody > tr > th.select-checkbox::after {
  width: 15px;
  height: 15px;
}

#employer-cards-table table.dataTable > tbody > tr > td.select-checkbox::before,
#employer-cards-table table.dataTable > tbody > tr > th.select-checkbox::before {
  margin-top: -7px;
  margin-left: 4px;
  left: 0;
}

#employer-cards-table table.dataTable > tbody > tr.selected > td.select-checkbox::after,
#employer-cards-table table.dataTable > tbody > tr.selected > th.select-checkbox::after {
  margin-left: 4px;
  left: 0;
}

.passwordChecker-passed {
  color: #17a100;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('~@/assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-300.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('~@/assets/fonts/roboto-v30-latin-300italic.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-300italic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('~@/assets/fonts/roboto-v30-latin-italic.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-italic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('~@/assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-500.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('~@/assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
       url('~@/assets/fonts/roboto-v30-latin-700.ttf') format('truetype');
}

</style>
