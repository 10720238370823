<template>
 <div class="container-fluid py-1 py-md-2">
  <div class="content">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-2 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-5 pt-2">
        <label for="inputLogo" class="form-label label-view fw-bold fs-3">
          MitarbeiterCARDs Ladeliste hochladen
        </label>
        <input type="file" class="form-control" @change="onEmployerCardsListSet" ref="inputEmployerCardsList" accept=".xlsx, .csv, .xsl">
        <div class="text-end mt-4 mt-sm-2">
          <button type="submit" @click="sendUploadEmployerCardsList()" class="btn btn-primary">Ladeliste speichern >></button>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-12 ps-lg-3">
        <h1>MitarbeiterCARD-<span class="fw-bold">Verwaltung</span></h1>
        <p>Eine Auflistung aller Ihrer MitarbeiterCARDs</p>
        <div class="border border-radius rounded table-responsive p-3">
          <h3 class="mt-4">Ihre MitarbeiterCARDs</h3>
          <div class="row py-2 mb-2" id="batch-edit-row" v-if="isSelectionActive === true">
            <div class="col-12 mb-1">
              <strong>Für alle ausgewählten CARDs bearbeiten:</strong>
            </div>
            <div class="col-auto">
              <label class="form-label label-view" for="isChargingActiveAllInput">Beladung aktiv?</label>
              <select class="form-select input-view" v-model="editAllSelectedObject.isChargingActive" id="isChargingActiveAllInput">
                <option value="">-- bitte auswählen --</option>
                <option value="true">aktivieren</option>
                <option value="false">deaktivieren</option>
              </select>
              <div id="isChargingActiveAllNote" class="form-text error-note"></div>
            </div>
            <div class="col-auto">
              <label for="chargeAmountAllInput" class="form-label label-view">Ladebetrag</label>
              <div class="input-group">
                <input type="text" id="chargeAmountAllInput" v-model="editAllSelectedObject.chargeAmount" @keyup="onChargeAmountAllInputChanged()" class="form-control input-view" maxlength="6" size="6">
                <span class="input-group-text">€</span>
              </div>
              <div id="chargeAmountAllNote" class="form-text error-note"></div>
            </div>
            <div class="col-auto">
              <label for="chargeFrequenceAllInput" class="form-label label-view">Ladefrequenz</label>
              <select class="form-select input-view" v-model="editAllSelectedObject.chargeFrequence" @change="onChargeFrequenceAllInputChanged()" id="chargeFrequenceAllInput">
                <option value="">-- bitte auswählen --</option>
                <option value="Monat">Monat</option>
                <option value="Quartal">Quartal</option>
                <option value="Halbjahr">Halbjahr</option>
                <option value="Jahr">Jahr</option>
                <option value="unregelmäßig">unregelmäßig</option>
              </select>
              <div id="chargeFrequenceAllNote" class="form-text error-note"></div>
            </div>
            <div class="col-auto">
              <label for="chargeTimeAllInput" class="form-label label-view">Ladezeitpunkt <img class="img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Die Option individuell ist nur bei unregelmäßiger Ladefrequenz verfügbar."></label>
              <select class="form-select input-view" v-model="editAllSelectedObject.chargeTime" id="chargeTimeAllInput">
                <option value="">-- bitte auswählen --</option>
                <option value="Anfang des Monats">Anfang des Monats</option>
                <option value="Mitte des Monats">Mitte des Monats</option>
                <option value="Ende des Monats">Ende des Monats</option>
                <option value="Individuell" :disabled="editAllSelectedObject.chargeFrequence !== 'unregelmäßig'">Individuell</option>
              </select>
              <div id="chargeTimeAllNote" class="form-text error-note"></div>
              <div v-if="editAllSelectedObject.chargeTime === 'Individuell'">
                <label for="customChargeTimeDateAllInput" class="form-label label-view">Individueller Ladezeitpunkt</label>
                <input type="date" id="customChargeTimeDateAllInput" v-model="editAllSelectedObject.customChargeTimeDate" class="form-control input-view">
                <div id="customChargeTimeDateAllNote" class="form-text error-note"></div>
              </div>
            </div>
            <div class="col-auto pt-4">
              <button type="button" class="btn btn-primary btn-sm" @click="updateAllSelectedEmployerCards()">Für alle übernehmen</button>
            </div>
          </div>
          <DataTable ref="mitarbeitercardstable" id="employer-cards-table" class="table table-hover align-middle mb-0" :data="employerCardsData" :columns="columns" :options="datatableOptions" width="100%">
            <thead>
              <tr>
                <th><input type="checkbox" class="selectAll" name="selectAll" value="all"></th>
                <th>Beladung aktiv</th>
                <th>Kartennummer</th>
                <th>Nachname</th>
                <th>Vorname</th>
                <th>Personalnummer</th>
                <th>Ladebetrag</th>
                <th>Ladefrequenz</th>
                <th>Ladezeitpunkt</th>
                <th>Nächster Ladetermin</th>
                <th>Bemerkungen</th>
                <th></th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th><input type="checkbox" class="selectAll" name="selectAll" value="all"></th>
                <th>Beladung aktiv</th>
                <th>Kartennummer</th>
                <th>Nachname</th>
                <th>Vorname</th>
                <th>Personalnummer</th>
                <th>Ladebetrag</th>
                <th>Ladefrequenz</th>
                <th>Ladezeitpunkt</th>
                <th>Nächster Ladetermin</th>
                <th>Bemerkungen</th>
                <th></th>
              </tr>
            </tfoot>
          </DataTable>
          <h5 class="mt-2">Aktuelle Ansicht exportieren</h5>
          <div id="export-buttons-container"></div>
        </div>
      </div>
    </div>
  </div>
 </div>

 <div class="modal" id="editRecordModal" ref="editRecordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-black">Kartennummer {{ currentEditingRecord.cardID }} bearbeiten</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="pt-0">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-check">
                    <input class="form-check-input" v-model="currentEditingRecord.isChargingActive" type="checkbox" id="isChargingActiveInput">
                    <label class="form-check-label" for="isChargingActiveInput">
                      Beladung aktiv?
                    </label>
                  </div>
                  <div id="isChargingActiveNote" class="form-text error-note"></div>
                </div>
                <div class="col-lg-4">
                  <div class="form-check">
                    <input class="form-check-input" v-model="currentEditingRecord.isFlaggedForDeletion" type="checkbox" id="isFlaggedForDeletionInput">
                    <label class="form-check-label" for="isFlaggedForDeletionInput">
                      Mitarbeiter scheidet aus?
                    </label>
                  </div>
                  <div id="isFlaggedForDeletionNote" class="form-text error-note"></div>
                  <div v-if="currentEditingRecord.isFlaggedForDeletion === true">
                    <label for="deletionDateInput" class="form-label label-view">Austrittsdatum</label>
                    <input type="date" id="deletionDateInput" v-model="currentEditingRecord.deletionDate" class="form-control input-view" placeholder="Austrittsdatum eingeben">
                    <div id="deletionDateNote" class="form-text error-note"></div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-check">
                    <input class="form-check-input" v-model="currentEditingRecord.isCardLost" type="checkbox" id="isCardLostInput">
                    <label class="form-check-label" for="isCardLostInput">
                      Karte verloren/sperren?
                    </label>
                  </div>
                  <div id="isCardLostNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-4">
                <label for="firstNameInput" class="form-label label-view">Vorname</label>
                <input type="text" id="firstNameInput" v-model="currentEditingRecord.firstName" :disabled="currentEditingRecord.isCardRegistered === true" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                <div id="firstNameNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4">
                <label for="lastNameInput" class="form-label label-view">Nachname</label>
                <input type="text" id="lastNameInput" v-model="currentEditingRecord.lastName" :disabled="currentEditingRecord.isCardRegistered === true" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                <div id="lastNameNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4">
                <label for="personnelNumberInput" class="form-label label-view">Personalnummer</label>
                <input type="text" id="personnelNumberInput" v-model="currentEditingRecord.personnelNumber" class="form-control input-view" placeholder="Personalnummer eingeben" maxlength="30">
                <div id="personnelNumberNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-4">
                <label for="chargeAmountInput" class="form-label label-view">Ladebetrag</label>
                <div class="input-group">
                  <input type="text" id="chargeAmountInput" v-model="currentEditingRecord.chargeAmount" @keyup="onChargeAmountInputChanged()" class="form-control input-view" placeholder="Ladebetrag eingeben" maxlength="6" size="6">
                  <span class="input-group-text">€</span>
                </div>
                <div id="chargeAmountNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4">
                <label for="chargeFrequenceInput" class="form-label label-view">Ladefrequenz</label>
                <select class="form-select input-view" v-model="currentEditingRecord.chargeFrequence" @change="onChargeFrequenceInputChanged()" id="chargeFrequenceInput">
                  <option value="">-- bitte auswählen --</option>
                  <option value="Monat">Monat</option>
                  <option value="Quartal">Quartal</option>
                  <option value="Halbjahr">Halbjahr</option>
                  <option value="Jahr">Jahr</option>
                  <option value="unregelmäßig">unregelmäßig</option>
                </select>
                <div id="chargeFrequenceNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4">
                <label for="chargeTimeInput" class="form-label label-view">Ladezeitpunkt <img class="img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Die Option individuell ist nur bei unregelmäßiger Ladefrequenz verfügbar."></label>
                <select class="form-select input-view" v-model="currentEditingRecord.chargeTime" id="chargeTimeInput">
                  <option value="">-- bitte auswählen --</option>
                  <option value="Anfang des Monats">Anfang des Monats</option>
                  <option value="Mitte des Monats">Mitte des Monats</option>
                  <option value="Ende des Monats">Ende des Monats</option>
                  <option value="Individuell" :disabled="currentEditingRecord.chargeFrequence !== 'unregelmäßig'">Individuell</option>
                </select>
                <div id="chargeTimeNote" class="form-text error-note"></div>
                <div v-if="currentEditingRecord.chargeTime === 'Individuell'">
                  <label for="customChargeTimeDateInput" class="form-label label-view">Individueller Ladezeitpunkt</label>
                  <input type="date" id="customChargeTimeDateInput" v-model="currentEditingRecord.customChargeTimeDate" class="form-control input-view">
                  <div id="customChargeTimeDateNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="personnelNoteInput" class="form-label label-view">Bemerkung</label>
                <input type="text" id="personnelNoteInput" v-model="currentEditingRecord.personnelNote" class="form-control input-view" placeholder="Bemerkung eingeben" maxlength="150">
                <div id="personnelNoteNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light btn-lg" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-primary btn-lg float-end" @click="updateEmployerCard()">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import CardData from '@/components/CardData.vue'
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-select-bs5/css/select.bootstrap5.min.css'
const bootstrap = require('bootstrap')
DataTable.use(DataTablesLib)

export default {
  name: 'EmployerManageCards',
  components: {
    CardData,
    DataTable
  },
  data () {
    return {
      employerCardsListFile: undefined,
      iconX: 'icon-x.svg',
      iconCheck: 'icon-check.svg',
      editRecordModal: undefined,
      bootstrapEditRecordModal: undefined,
      dataTable: undefined,
      employerCardsData: [],
      currentEditingRecord: {},
      isSelectionActive: false,
      editAllSelectedObject: {
        isChargingActive: undefined,
        chargeAmount: '',
        chargeFrequence: '',
        chargeTime: ''
      },
      columns: [
        {
          targets: 0,
          className: 'select-checkbox dt-left',
          data: null,
          defaultContent: '',
          orderable: false,
          searchable: false,
          width: '20px'
        },
        {
          data: 'isChargingActive',
          targets: 0,
          orderable: false,
          searchable: false,
          width: '30px',
          className: 'dt-center',
          render: function(data, type, row, meta) {
            if (type === 'display') {
              const image = data === true ? require('../assets/img/icon-check.svg') : require('../assets/img/icon-x.svg')
              return '<img src="' + image + '" width="25">'
            } else {
              return data
            }
          },
          createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
            if (cellData === false) {
              $(cell).addClass('table-warning')
            }
          }
        },
        {
          data: 'cardID',
          width: '130px',
          render: function(data, type, row, meta) {
            return String(data)
          },
          createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
            if (rowData.isCardRegistered === true) {
              $(cell).addClass('table-success')
            }
          }
        },
        {
          data: 'lastName',
          createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
            if (rowData.isCardRegistered === true) {
              $(cell).addClass('table-success')
            }
          }
        },
        {
          data: 'firstName',
          createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
            if (rowData.isCardRegistered === true) {
              $(cell).addClass('table-success')
            }
          }
        },
        {
          data: 'personnelNumber',
          width: '100px',
          render: function(data, type, row, meta) {
            return String(data)
          }
        },
        {
          data: 'chargeAmount',
          width: '80px',
          render: function(data, type, row, meta) {
            if (data !== null && data !== '') {
              return data + '€'
            } else {
              return ''
            }
          }
        },
        {
          data: 'chargeFrequence',
          width: '100px'
        },
        {
          data: 'chargeTime',
          width: '140px'
        },
        {
          data: 'nextChargeTimestamp',
          width: '80px'
        },
        { data: 'personnelNote' },
        {
          data: null,
          width: '30px',
          className: 'dt-center',
          defaultContent: '<img class="edit-record-button" src="' + require('../assets/img/pencil.svg') + '" />',
          orderable: false
        }
      ],
      datatableOptions: {
        dom: 'frtlip',
        autoWidth: false,
        buttons: [
          {
            extend: 'csvHtml5',
            title: '',
            className: 'btn-primary',
            charset: 'utf-8',
            extension: '.csv',
            fieldSeparator: ';',
            fieldBoundary: '',
            bom: true,
            exportOptions: {
              orthogonal: 'export',
              columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            }
          },
          {
            extend: 'excelHtml5',
            title: '',
            className: 'btn-primary ms-2',
            exportOptions: {
              orthogonal: 'export',
              columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            },
            customize: function(xlsx, row) {
              var sheet = xlsx.xl.worksheets['Tabelle1.xml']
              $('row c[r^="B"]', sheet).attr('s', 50)
            }
          },
          {
            extend: 'pdfHtml5',
            title: '',
            className: 'btn-primary ms-2',
            orientation: 'landscape',
            exportOptions: {
              orthogonal: 'export',
              columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            }
          },
          {
            extend: 'print',
            title: '',
            className: 'btn-primary ms-2',
            text: 'Drucken',
            exportOptions: {
              orthogonal: 'export',
              columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            }
          }
        ],
        select: {
          style: 'multi',
          selector: 'td:first-child'
        },
        order: [[2, 'asc']],
        createdRow: function(row, rowData, index) {
          if (rowData.isCardActive === false) {
            $(row).addClass('table-danger')
          }
        },
        language: {
          search: 'Suchen',
          lengthMenu: 'Zeige _MENU_ MitarbeiterCARDs pro Seite',
          zeroRecords: 'Keine MitarbeiterCARDs gefunden',
          info: 'Zeige Einträge _START_ bis _END_ von _TOTAL_ Einträgen (Seite _PAGE_ von _PAGES_)',
          infoEmpty: 'Keine MitarbeiterCARDs gefunden',
          infoFiltered: '(gefiltert von _MAX_ MitarbeiterCARDs)',
          paginate: {
            first: 'Erste',
            last: 'Letzte',
            next: 'Nächste',
            previous: 'Vorherige'
          },
          select: {
            rows: {
              _: '%d Zeilen ausgewählt',
              1: '1 Zeile ausgewählt'
            }
          }
        }
      }
    }
  },
  methods: {
    async fetchEmployerCardsData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getEmployerCards').then(response => {
        this.employerCardsData = response
        this.$store.commit('SET_LOADING_STATUS', false)
      }).catch(() => { this.$store.commit('SET_LOADING_STATUS', false) })
        .finally(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    },
    async updateEmployerCard () {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (this.validateEditModalFields()) {
        await this.$store.dispatch('updateEmployerCard', this.currentEditingRecord)
          .then(response => {
            this.$store.commit('SET_LOADING_STATUS', false)
            this.bootstrapEditRecordModal.hide()
            this.fetchEmployerCardsData()
          }).catch(() => { this.$store.commit('SET_LOADING_STATUS', false) })
      } else {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    validateEditModalFields() {
      this.clearAllValidationErrorsFromEditModal()
      let isValid = true
      if (this.currentEditingRecord.isFlaggedForDeletion === true) {
        if (typeof this.currentEditingRecord.deletionDate === 'undefined' || this.currentEditingRecord.deletionDate === '' || this.currentEditingRecord.deletionDate.length === 0) {
          this.showValidationErrorFromEditModal('deletionDate', 'Wenn der Mitarbeiter/CARD das Unternehmen verlässt muss ein Austrittsdatum angegeben werden.')
          isValid = false
        }
      }
      if (!this.currentEditingRecord.firstName || this.currentEditingRecord.firstName === '') {
        this.showValidationErrorFromEditModal('firstName', 'Es muss ein Vorname angegeben werden.')
        isValid = false
      }
      if (!this.currentEditingRecord.lastName || this.currentEditingRecord.lastName === '') {
        this.showValidationErrorFromEditModal('lastName', 'Es muss ein Nachname angegeben werden.')
        isValid = false
      }
      if (!this.currentEditingRecord.personnelNumber || this.currentEditingRecord.personnelNumber === '') {
        this.showValidationErrorFromEditModal('personnelNumber', 'Es muss eine Personalnummer angegeben werden.')
        isValid = false
      }
      if (!this.currentEditingRecord.chargeAmount || this.currentEditingRecord.chargeAmount === '') {
        this.showValidationErrorFromEditModal('chargeAmount', 'Es muss ein Ladebetrag angegeben werden.')
        isValid = false
      }
      if (!this.currentEditingRecord.chargeFrequence || this.currentEditingRecord.chargeFrequence === '') {
        this.showValidationErrorFromEditModal('chargeFrequence', 'Es muss eine Ladefrequenz angegeben werden.')
        isValid = false
      }
      if (!this.currentEditingRecord.chargeTime || this.currentEditingRecord.chargeTime === '') {
        this.showValidationErrorFromEditModal('chargeTime', 'Es muss ein Ladezeitpunkt angegeben werden.')
        isValid = false
      } else {
        if (this.currentEditingRecord.chargeTime === 'Individuell') {
          if (typeof this.currentEditingRecord.customChargeTimeDate === 'undefined' || this.currentEditingRecord.customChargeTimeDate === '' || this.currentEditingRecord.customChargeTimeDate.length === 0) {
            this.showValidationErrorFromEditModal('customChargeTimeDate', 'Bei Ladezeitpunkt = individuell muss ein individueller Ladezeitpunkt als Datum angegeben werden.')
            isValid = false
          }
        }
      }

      return isValid
    },
    validateSelectAllFields() {
      let isValid = true

      if ((this.editAllSelectedObject.isChargingActive === null || this.editAllSelectedObject.isChargingActive === 'undefined' || this.editAllSelectedObject.isChargingActive === '') &&
      (typeof this.editAllSelectedObject.chargeAmount === 'undefined' || this.editAllSelectedObject.chargeAmount === '' || this.editAllSelectedObject.chargeAmount.length === 0) &&
      (typeof this.editAllSelectedObject.chargeFrequence === 'undefined' || this.editAllSelectedObject.chargeFrequence === '' || this.editAllSelectedObject.chargeFrequence.length === 0) &&
      (typeof this.editAllSelectedObject.chargeTime === 'undefined' || this.editAllSelectedObject.chargeTime === '' || this.editAllSelectedObject.chargeTime.length === 0)
      ) {
        this.showValidationErrorFromSelection('isChargingActive', 'Es wurde keine Eigenschaft zum Ändern angegeben.')
        this.showValidationErrorFromSelection('chargeAmount', 'Es wurde keine Eigenschaft zum Ändern angegeben.')
        this.showValidationErrorFromSelection('chargeFrequence', 'Es wurde keine Eigenschaft zum Ändern angegeben.')
        this.showValidationErrorFromSelection('chargeTime', 'Es wurde keine Eigenschaft zum Ändern angegeben.')
        isValid = false
      }

      if (this.editAllSelectedObject.chargeTime === 'Individuell') {
        if (typeof this.editAllSelectedObject.customChargeTimeDate === 'undefined' || this.editAllSelectedObject.customChargeTimeDate === '' || this.editAllSelectedObject.customChargeTimeDate.length === 0) {
          this.showValidationErrorFromSelection('customChargeTimeDate', 'Bei Ladezeitpunkt = individuell muss ein individueller Ladezeitpunkt als Datum angegeben werden.')
          isValid = false
        }
      }

      return isValid
    },
    async updateAllSelectedEmployerCards () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.clearAllValidationErrorsFromSelection()

      if (this.validateSelectAllFields() === false) {
        return
      }

      const dataToSend = {}
      dataToSend.cardIDsToUpdate = []
      dataToSend.fieldsToUpdate = {}

      for (const property in this.editAllSelectedObject) {
        if (this.editAllSelectedObject[property] !== undefined && this.editAllSelectedObject[property] !== null && this.editAllSelectedObject[property] !== false && this.editAllSelectedObject[property] !== '' && this.editAllSelectedObject[property] !== 0) {
          dataToSend.fieldsToUpdate[property] = this.editAllSelectedObject[property]
        }
      }

      if (Object.keys(dataToSend.fieldsToUpdate).length === 0) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Eigenschaft zum Ändern angegeben!' })
        for (const property in this.editAllSelectedObject) {
          this.showValidationErrorFromSelection(property, 'Es wurde keine Eigenschaft zum Ändern angegeben.')
        }
        this.$store.commit('SET_LOADING_STATUS', false)
        return
      }

      this.dataTable.rows({ selected: true, page: 'current' }).every(function (rowIdx, tableLoop, rowLoop) {
        const cardID = this.data().cardID
        dataToSend.cardIDsToUpdate.push(cardID)
        return null
      })

      await this.$store.dispatch('updateMultipleEmployerCards', dataToSend).then(response => {
        this.$store.commit('SET_LOADING_STATUS', false)
      }).catch(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    showValidationErrorFromSelection (fieldId, validationMessage = undefined) {
      const inputField = document.getElementById(fieldId + 'AllInput')
      if (typeof inputField !== 'undefined' && inputField !== null) {
        inputField.classList.add('form-element-danger')
      }
      if (validationMessage !== undefined && validationMessage !== null && validationMessage !== '') {
        const noteField = document.getElementById(fieldId + 'AllNote')
        if (typeof noteField !== 'undefined' && noteField !== null) {
          noteField.innerHTML = validationMessage
        }
      }
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    removeValidationErrorFromSelection (fieldId) {
      document.getElementById(fieldId + 'AllInput').classList.remove('form-element-danger')
      document.getElementById(fieldId + 'AllNote').innerHTML = ''
    },
    clearAllValidationErrorsFromSelection() {
      this.$store.commit('DELETE_ALERTS')
      for (const property in this.editAllSelectedObject) {
        this.removeValidationErrorFromSelection(property)
      }
    },
    showValidationErrorFromEditModal (fieldId, validationMessage = undefined) {
      document.getElementById(fieldId + 'Input').classList.add('form-element-danger')
      if (validationMessage !== undefined && validationMessage !== null && validationMessage !== '') {
        document.getElementById(fieldId + 'Note').innerHTML = validationMessage
      }
    },
    removeValidationErrorFromEditModal (fieldId) {
      const inputField = document.getElementById(fieldId + 'Input')
      if (inputField !== null && inputField !== undefined) {
        inputField.classList.remove('form-element-danger')
      }
      const noteField = document.getElementById(fieldId + 'Note')
      if (noteField !== null && noteField !== undefined) {
        noteField.innerHTML = ''
      }
    },
    clearAllValidationErrorsFromEditModal() {
      this.$store.commit('DELETE_ALERTS')
      this.removeValidationErrorFromEditModal('isChargingActive')
      this.removeValidationErrorFromEditModal('firstName')
      this.removeValidationErrorFromEditModal('lastName')
      this.removeValidationErrorFromEditModal('personnelNumber')
      this.removeValidationErrorFromEditModal('chargeAmount')
      this.removeValidationErrorFromEditModal('chargeFrequence')
      this.removeValidationErrorFromEditModal('chargeTime')
      this.removeValidationErrorFromEditModal('personnelNote')
      this.removeValidationErrorFromEditModal('isCardLost')
      this.removeValidationErrorFromEditModal('deletionDate')
      this.removeValidationErrorFromEditModal('isFlaggedForDeletion')
    },
    onChargeAmountAllInputChanged() {
      this.editAllSelectedObject.chargeAmount = this.fomatChargeAmount(this.editAllSelectedObject.chargeAmount)
    },
    onChargeAmountInputChanged() {
      this.currentEditingRecord.chargeAmount = this.fomatChargeAmount(this.currentEditingRecord.chargeAmount)
    },
    onChargeFrequenceInputChanged () {
      if (this.currentEditingRecord.chargeFrequence.toLowerCase() !== 'unregelmäßig' && this.currentEditingRecord.chargeTime.toLowerCase() === 'individuell') {
        this.currentEditingRecord.chargeTime = ''
      }
    },
    onChargeFrequenceAllInputChanged () {
      if (this.editAllSelectedObject.chargeFrequence.toLowerCase() !== 'unregelmäßig' && this.editAllSelectedObject.chargeTime.toLowerCase() === 'individuell') {
        this.editAllSelectedObject.chargeTime = ''
      }
    },
    fomatChargeAmount (amount) {
      amount = amount.replaceAll('€', '')
      amount = amount.replaceAll('$', '')
      amount = amount.replaceAll(' ', '')
      amount = amount.replace(/[A-Za-zöäüÖÄÜ"'\s=_@!#+*]/gi, '')

      if (amount) {
        if (amount.replaceAll(',', '').length > 2) {
          if (amount.includes(',')) {
            amount = amount.replaceAll(',', '')
          }

          amount = amount.slice(0, amount.length - 2) +
                      ',' +
                      amount.slice(amount.length - 2, amount.length)
        } else {
          amount = amount.replaceAll(',', '')
        }
        if (amount.replaceAll('.', '').length > 6) {
          if (amount.includes('.')) {
            amount = amount.replaceAll('.', '')
          }

          amount = amount.slice(0, amount.length - 6) +
                      '.' +
                      amount.slice(amount.length - 6, amount.length)
        } else {
          amount = amount.replaceAll('.', '')
        }
      }
      return amount
    },
    onEmployerCardsListSet (event) {
      this.employerCardsListFile = event.target.files[0]
    },
    sendUploadEmployerCardsList () {
      if (!this.employerCardsListFile) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Datei zum Hochladen angegeben.' })
        return
      }

      this.$store.commit('DELETE_ALERTS')

      this.$store.commit('SET_LOADING_STATUS', true)
      const formData = new FormData()
      formData.append('employerCardsListFile', this.employerCardsListFile)
      this.$store.dispatch('uploadEmployerCardsList', formData)
        .then(() => {
          // clear form
          this.employerCardsListFile = undefined
          this.$refs.inputEmployerCardsList.value = null
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    renderTooltips () {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    }
  },
  created () {
    this.fetchEmployerCardsData()
  },
  mounted () {
    this.renderTooltips()
    this.dataTable = this.$refs.mitarbeitercardstable.dt
    this.dataTable.buttons().container().appendTo($('#export-buttons-container'))

    this.editRecordModal = document.getElementById('editRecordModal')
    this.bootstrapEditRecordModal = new bootstrap.Modal(this.editRecordModal)

    const superThis = this

    $(document).on('click', '.edit-record-button', function() {
      var index = superThis.dataTable
        .rows({ search: 'applied', order: 'index', page: 'all' })
        .nodes()
        .to$()
        .index($(this).closest('tr'))
      superThis.currentEditingRecord = superThis.employerCardsData[index]
      superThis.bootstrapEditRecordModal.show()
    })

    this.editRecordModal.addEventListener('hide.bs.modal', function (event) {
      superThis.currentEditingRecord = {}
    })
    this.editRecordModal.addEventListener('shown.bs.modal', function (event) {
      superThis.clearAllValidationErrorsFromEditModal()
    })

    $(document).on('click', '.selectAll', function() {
      if ($(this).is(':checked')) {
        superThis.isSelectionActive = true
        superThis.dataTable.rows({ page: 'current' }).select()
      } else {
        superThis.isSelectionActive = false
        superThis.dataTable.rows({ page: 'current' }).deselect()
      }
    })

    this.dataTable.on('select', function (e, dt, type, indexes) {
      superThis.isSelectionActive = true
      // this.dataTable[ type ]( indexes ).nodes().to$().addClass( 'custom-selected' )
    })

    this.dataTable.on('deselect', function (e, dt, type, indexes) {
      var amountOfSelectedRows = superThis.dataTable.rows({ selected: true, page: 'current' }).count()
      if (amountOfSelectedRows === 0) {
        superThis.isSelectionActive = false
      }
    })
  }
}
</script>

<style scoped>
@import 'datatables.net-bs5';
@media (max-width: 500px) {
  #employer-cards-table td,
  #employer-cards-table th {
    font-size: 14px;
  }
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

#editRecordModal .modal-footer {
  justify-content: space-between;
}

#batch-edit-row {
  border: 2px solid var(--bs-border-color);
  border-radius:10px;
}

#batch-edit-row:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 5px;
  border-top: 60px solid blue;
  border-top-color: inherit;
  border-left: 20px solid transparent;
  border-right: 50px solid transparent;
}

</style>
