<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData :currentCardID="currentCardID" />
      </div>
      <div class="col-12 col-lg-8 ps-lg-5">
        <h1>Buchungs<span class="fw-bold">übersicht</span></h1>
        <div class="alert alert-warning" role="alert">
            Aktuelle Buchungen erscheinen nach ca. 5 Minuten in der Übersicht.
          </div>
        <p>Eine Auflistung aller Buchungen für Kartennummer:</p>
        <select class="form-select input-view mb-3" @change="onCurrentCardIDChanged($event)">
          <option v-for="k, cardID in transactionsData" :key="cardID" :value="cardID" :selected="parseInt(cardID) === parseInt(Object.keys(this.transactionsData)[0])">{{cardID}}</option>
        </select>
        <div class="border border-radius rounded table-responsive">
          <table id="transactions-table" class="table table-hover mb-0">
            <thead>
              <tr>
                <th scope="col" class="py-2">Datum</th>
                <th scope="col" class="py-2">Partner</th>
                <th scope="col" class="py-2">Buchungsart</th>
                <th scope="col" class="py-2">Betrag in Euro</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in currentTransactions" :key="index">
                <td>{{ transaction.dateFormattedDE }} {{ transaction.dateFormattedDE ? 'Uhr' : '' }}</td>
                <td>{{ transaction.partner }}</td>
                <td>{{ transaction.text }}</td>
                <td>{{ transaction.amountFormattedDE }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'BookingOverviewPage',
  components: {
    CardData
  },
  data () {
    return {
      transactionsData: [],
      currentTransactions: [],
      currentCardID: ''
    }
  },
  methods: {
    async fetchTransactionData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getTransactions').then(response => {
        this.transactionsData = response
        if (Object.keys(this.transactionsData).length > 0) {
          this.currentTransactions = this.transactionsData[Object.keys(this.transactionsData)[0]]
          this.currentCardID = Object.keys(this.transactionsData)[0]
        }
      }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    onCurrentCardIDChanged (event) {
      this.currentTransactions = this.transactionsData[event.target.value]
      this.currentCardID = event.target.value
    }
  },
  created () {
    this.fetchTransactionData()
  }
}
</script>

<style scoped>

@media (max-width: 500px) {
  #transactions-table td,
  #transactions-table th {
    font-size: 14px;
  }
}

</style>
