<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-8 ps-lg-5">
        <h1><span class="fw-bold">Korrektur</span>buchung</h1>
        <div class="alert alert-warning" role="alert">
          Korrekturbuchungen können nur innerhalb von 24 Stunden ab Buchung durchgeführt werden.
        </div>
        <div class="mt-0 mt-sm-3">
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-12">
                <label for="inputCardID" class="form-label label-view">
                  Kartennummer*
                </label>
                <div class="row">
                  <div class="col-lg-12">
                    <input type="text" id="inputcardID" class="form-control" v-model="cardID" placeholder="Kartennummer eingeben">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-12">
                Welche Buchung soll korrigiert werden?*
              </div>
            </div>
          </div>
          <div class="pt-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="isAddVoucher">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Guthaben aufgebucht</span>
                  <input type="text" v-model="addVoucherAmount" class="form-control" :disabled="!isAddVoucher" :readonly="!isAddVoucher">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="isRedeemVoucher">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Guthaben eingelöst</span>
                  <input type="text" v-model="redeemVoucherAmount" class="form-control" :disabled="!isRedeemVoucher" :readonly="!isRedeemVoucher">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="isAddBonus">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Einkauf gebucht (Bonus)</span>
                  <input type="text" v-model="addBonusAmount" class="form-control" :disabled="!isAddBonus" :readonly="!isAddBonus">
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputBookingTimestamp" class="form-label label-view">
                  Buchungszeitpunkt*
                </label>
                <input type="datetime-local" id="inputBookingTimestamp" class="form-control" v-model="bookingTimestamp" placeholder="Buchungszeitpunkt eingeben" step="1">
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-12">
                Welche Buchung wäre korrekt gewesen?*
              </div>
            </div>
          </div>
          <div class="pt-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="shouldAddVoucher">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Guthaben aufgebucht</span>
                  <input type="text" v-model="shouldAddVoucherAmount" class="form-control" :disabled="!shouldAddVoucher" :readonly="!shouldAddVoucher">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="shouldRedeemVoucher">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Guthaben eingelöst</span>
                  <input type="text" v-model="shouldRedeemVoucherAmount" class="form-control" :disabled="!shouldRedeemVoucher" :readonly="!shouldRedeemVoucher">
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group">
                  <div class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" v-model="shouldAddBonus">
                  </div>
                  <span class="input-group-text" style="width: 220px;">Einkauf gebucht (Bonus)</span>
                  <input type="text" v-model="shouldAddBonusAmount" class="form-control" :disabled="!shouldAddBonus" :readonly="!shouldAddBonus">
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-12 col-lg-3">
                <label for="inputContractID" class="form-label">Partnervertragsnummer*</label>
                <input type="text" id="inputContractID" class="form-control" v-model="contractID" placeholder="Partnervertragsnummer eingeben" />
              </div>
              <div class="col-12 col-lg-9">
                <label for="inputPartnerName" class="form-label">Partnername*</label>
                <input type="text" id="inputPartnerName" class="form-control" v-model="partnerName" placeholder="Partnername eingeben" />
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputMessage" class="form-label">Erläuterung</label>
                <textarea class="form-control" id="inputMessage" v-model="message" rows="7"></textarea>
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2 border-top">
            <div class="row">
              <div class="col-12 col-lg-4">
                <label for="inputContactEmail" class="form-label">Kontakt E-Mail-Adresse*</label>
                <input type="email" id="inputContactEmail" class="form-control" v-model="contactEmail" placeholder="E-Mail-Adresse eingeben" />
              </div>
              <div class="col-12 col-lg-4">
                <label for="inputContactName" class="form-label">Kontakt Name*</label>
                <input type="text" id="inputContactName" class="form-control" v-model="contactName" placeholder="Name eingeben" />
              </div>
              <div class="col-12 col-lg-4">
                <label for="inputContactPhone" class="form-label">Telefon für Rückfragen</label>
                <input type="text" id="inputContactPhone" class="form-control" v-model="contactPhone" placeholder="Telefonnummer eingeben" />
              </div>
            </div>
          </div>
          <div class="text-end mt-4 mt-sm-3">
            <button type="submit" @click="sendCorrectionBookingForm()" class="btn btn-primary">Absenden &gt;&gt;</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import moment from 'moment'

export default {
  name: 'ContactPage',
  components: {
    CardData
  },
  data () {
    return {
      cardID: '',
      isAddVoucher: false,
      isRedeemVoucher: false,
      isAddBonus: false,
      addVoucherAmount: '',
      redeemVoucherAmount: '',
      addBonusAmount: '',
      bookingTimestamp: '',
      shouldAddVoucher: false,
      shouldRedeemVoucher: false,
      shouldAddBonus: false,
      shouldAddVoucherAmount: '',
      shouldRedeemVoucherAmount: '',
      shouldAddBonusAmount: '',
      contractID: '',
      partnerName: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      message: ''
    }
  },
  methods: {
    sendCorrectionBookingForm () {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (this.validateForm()) {
        this.$store.dispatch('correctionBookingForm', {
          cardID: this.cardID,
          isAddVoucher: this.isAddVoucher,
          isRedeemVoucher: this.isRedeemVoucher,
          isAddBonus: this.isAddBonus,
          addVoucherAmount: this.addVoucherAmount,
          redeemVoucherAmount: this.redeemVoucherAmount,
          addBonusAmount: this.addBonusAmount,
          bookingTimestamp: this.bookingTimestamp,
          shouldAddVoucher: this.shouldAddVoucher,
          shouldRedeemVoucher: this.shouldRedeemVoucher,
          shouldAddBonus: this.shouldAddBonus,
          shouldAddVoucherAmount: this.shouldAddVoucherAmount,
          shouldRedeemVoucherAmount: this.shouldRedeemVoucherAmount,
          shouldAddBonusAmount: this.shouldAddBonusAmount,
          contractID: this.contractID,
          partnerName: this.partnerName,
          contactName: this.contactName,
          contactEmail: this.contactEmail,
          contactPhone: this.contactPhone,
          message: this.message
        })
          .then(() => {
            // clear form
            this.cardID = ''
            this.isAddVoucher = false
            this.isRedeemVoucher = false
            this.isAddBonus = false
            this.addVoucherAmount = ''
            this.redeemVoucherAmount = ''
            this.addBonusAmount = ''
            this.bookingTimestamp = ''
            this.shouldAddVoucher = false
            this.shouldRedeemVoucher = false
            this.shouldAddBonus = false
            this.shouldAddVoucherAmount = ''
            this.shouldRedeemVoucherAmount = ''
            this.shouldAddBonusAmount = ''
            this.contractID = ''
            this.partnerName = ''
            this.contactName = ''
            this.contactPhone = ''
            this.contactEmail = ''
            this.message = ''
          }).catch(() => {})
          .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
      } else {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    validateForm () {
      this.$store.commit('DELETE_ALERTS')
      let isValid = true

      if (!this.cardID) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer eingegeben!' })
        isValid = false
      } else if (!this.cardID.startsWith('1761')) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kartennummer ist ungültig!' })
        isValid = false
      }

      if (!this.bookingTimestamp || this.bookingTimestamp.length === 0) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Buchungszeitpunkt eingegeben!' })
        isValid = false
      } else {
        this.bookingTimestamp = moment(this.bookingTimestamp).format('YYYY-MM-DDTHH:mm:ss')
      }

      if (!this.contactEmail) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Kontakt E-Mail-Adresse eingegeben!' })
        isValid = false
      }

      if (!this.contactName) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Kontakt Name eingegeben!' })
        isValid = false
      }

      if (!this.contractID) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Partnervertragsnummer eingegeben!' })
        isValid = false
      }

      if (!this.partnerName) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Partnername eingegeben!' })
        isValid = false
      }

      if (this.isAddVoucher === false && this.isRedeemVoucher === false && this.isAddBonus === false) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein "Welche Buchung soll korrigiert werden?" angegeben!' })
        isValid = false
      } else {
        if (this.isAddVoucher === true && this.addVoucherAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Guthaben aufgebucht" angegeben!' })
          isValid = false
        }

        if (this.isRedeemVoucher === true && this.redeemVoucherAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Guthaben eingelöst" angegeben!' })
          isValid = false
        }

        if (this.isAddBonus === true && this.addBonusAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Einkauf gebucht (Bonus)" angegeben!' })
          isValid = false
        }
      }

      if (this.shouldAddVoucher === false && this.shouldRedeemVoucher === false && this.shouldAddBonus === false) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein "Welche Buchung wäre korrekt gewesen?" angegeben!' })
        isValid = false
      } else {
        if (this.shouldAddVoucher === true && this.shouldAddVoucherAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Guthaben aufgebucht" angegeben!' })
          isValid = false
        }

        if (this.shouldRedeemVoucher === true && this.shouldRedeemVoucherAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Guthaben eingelöst" angegeben!' })
          isValid = false
        }

        if (this.shouldAddBonus === true && this.shouldAddBonusAmount === '') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Betrag für "Einkauf gebucht (Bonus)" angegeben!' })
          isValid = false
        }
      }

      return isValid
    },
    fetchCorrectionBookingForm () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('getCorrectionBookingForm').then(response => {
        this.contractID = response.contractID
        this.partnerName = response.partnerName
        this.contactName = response.contactName
        this.contactPhone = response.contactPhone
        this.contactEmail = response.contactEmail
      }).catch(() => {
        this.deactivateForm()
      })
        .finally(() => {
          if (Object.keys(this.$route.query).length > 0) {
            if (typeof this.$route.query.cardID !== 'undefined' && this.$route.query.cardID !== null) {
              this.cardID = this.$route.query.cardID
            }
            if (typeof this.$route.query.date !== 'undefined' && this.$route.query.date !== null) {
              const date = new Date(Number(this.$route.query.date) * 1000)

              var day = date.getDate()
              var month = date.getMonth() + 1
              var year = date.getFullYear()
              var hour = date.getHours()
              var minute = date.getMinutes()
              var second = date.getSeconds()

              if (month.toString().length < 2) month = '0' + month
              if (day.toString().length < 2) day = '0' + day
              if (hour.toString().length < 2) hour = '0' + hour
              if (minute.toString().length < 2) minute = '0' + minute
              if (second.toString().length < 2) second = '0' + second
              this.bookingTimestamp = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second
            }
            if (typeof this.$route.query.text !== 'undefined' && this.$route.query.text !== null) {
              if (this.$route.query.text.toLowerCase() === 'einlösung') {
                this.isRedeemVoucher = true
                this.redeemVoucherAmount = this.$route.query.amount
              } else if (this.$route.query.text.toLowerCase() === 'aufladung') {
                this.isAddVoucher = true
                this.addVoucherAmount = this.$route.query.amount
              } else if (this.$route.query.text.toLowerCase() === 'kundenbonus') {
                this.isAddBonus = true
                this.addBonusAmount = this.$route.query.amount
              }
            }
          }
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    }
  },
  created () {
    this.fetchCorrectionBookingForm()
  }
}
</script>
