<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-8 ps-lg-5">
        <h1><span class="fw-bold">Logo</span> hochladen</h1>
        <div class="mt-0 mt-sm-3">
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputLogo" class="form-label label-view">
                  Aktuelles Logo
                </label>
                <img v-if="this.currentLogo" :src="this.currentLogo" style="max-width: 30%; margin-bottom: 10px;" class="d-block" />
                <i v-if="noLogoSet" class="d-block">Es wurde noch kein Logo hochgeladen.</i>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputLogo" class="form-label label-view">
                  Neues Logo hochladen
                </label>
                <input type="file" class="form-control" @change="onLogoSet" ref="inputLogo" accept=".png, .jpg, .jpeg">
                <div v-if="this.previewLogo">
                  <span class="h5 d-block mt-2">Vorschau:</span>
                  <img :src="this.previewLogoImage" style="max-width: 30%; margin-bottom: 20px" />
                </div>
              </div>
            </div>
          </div>
          <div class="text-end mt-4 mt-sm-2">
            <button type="submit" @click="sendUploadLogo" class="btn btn-primary">Logo speichern >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'EditLogo',
  components: {
    CardData
  },
  data () {
    return {
      noLogoSet: false,
      currentLogo: undefined,
      previewLogo: undefined,
      previewLogoImage: undefined
    }
  },
  methods: {
    onLogoSet (event) {
      this.previewLogo = event.target.files[0]
      this.createImage(this.previewLogo)
    },
    createImage (file) {
      const reader = new FileReader()
      const vm = this
      reader.onload = (e) => {
        vm.previewLogoImage = e.target.result
      }
      reader.readAsDataURL(file)
    },
    sendUploadLogo () {
      if (!this.previewLogo) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde kein Bild angegeben.' })
        return
      }

      this.$store.commit('DELETE_ALERTS')

      this.$store.commit('SET_LOADING_STATUS', true)
      const formData = new FormData()
      formData.append('logo', this.previewLogo)
      this.$store.dispatch('uploadLogo', formData)
        .then(() => {
          // clear form
          this.currentLogo = this.previewLogoImage
          this.previewLogo = undefined
          this.previewLogoImage = undefined
          this.$refs.inputLogo.value = null
          this.noLogoSet = false
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    async fetchCurrentLogo () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getLogo').then(response => {
        if (response.status === 200) {
          this.currentLogo = 'data:image/png;base64,' + response.data
        } else if (response.status === 404) {
          this.noLogoSet = true
        }
      }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.fetchCurrentLogo()
  }
}
</script>
