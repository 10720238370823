<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 ps-lg-5">
        <h1><span class="fw-bold">Push-Benachrichtigung</span> auslösen</h1>
        <div class="mt-0 mt-sm-3">
          <div class="">
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-12">
                  Typ der Push Benachrichtigung
                  <select v-model="pushNotificationToSend.pushNotificationType" class="form-select input-view" id="pushNotificationType">
                    <option v-for="pushNotificationType in types" :key="pushNotificationType" :value="pushNotificationType">{{pushNotificationType}}</option>
                  </select>
                  <div id="pushNotificationTypeNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="pushNotificationToSend.pushNotificationType" class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <div class="fw-bold">Empfänger auswählen</div>
                <div class="row">
                  <div class="col-lg-5">
                    Region
                    <select v-model="pushNotificationToSend.recipientRegion" class="form-select input-view" id="recipientRegion">
                      <option v-for="region in regions" :key="region" :value="region">{{region}}</option>
                    </select>
                    <div id="recipientRegionNote" class="form-text error-note"></div>
                  </div>
                  <div class="col-lg-5">
                    Typ
                    <select v-model="pushNotificationToSend.recipientPartnerOrCustomer" class="form-select input-view" id="recipientPartnerOrCustomer">
                      <option v-for="cluster in recipientCluster" :key="cluster" :value="cluster">{{cluster}}</option>
                    </select>
                    <div id="recipientPartnerOrCustomerNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row mt-2" v-if="pushNotificationToSend.recipientPartnerOrCustomer === 'Kunden' || pushNotificationToSend.recipientPartnerOrCustomer === 'alle' || pushNotificationToSend.recipientPartnerOrCustomer === 'Alle'">
                  <div class="col-12">
                    <div class="fw-bold">Kunden-Empfänger konfigurieren</div>
                  </div>
                  <div class="col-lg-5">
                    Registriert / nicht registriert
                    <select v-model="pushNotificationToSend.recipientCardsRegistered" class="form-select input-view" id="recipientCardsRegistered">
                      <option v-for="cardRegisteredOption in recipientCardsRegistered" :key="cardRegisteredOption" :value="cardRegisteredOption">{{cardRegisteredOption}}</option>
                    </select>
                    <div id="recipientCardsRegisteredNote" class="form-text error-note"></div>
                  </div>
                  <div class="col-lg-5">
                    Kunden, die sich interessieren für die Partner-Kategorien...
                    <div class="form-check" v-for="(partnerCategory, index) in recipientPartnerCategories" :key="index">
                      <input class="form-check-input" type="checkbox" :id="'partnerCategory' + index" :value="partnerCategory" v-model="pushNotificationToSend.recipientPartnerCategories">
                      <label class="form-check-label" :for="'partnerCategory' + index">{{ partnerCategory }}</label>
                    </div>
                    <div id="recipientPartnerCategoriesNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="pushNotificationToSend.pushNotificationType === 'Nachricht' || pushNotificationToSend.pushNotificationType === 'Testnachricht'" class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-4">
                <label for="sendImmediately" class="form-label label-view">Versandzeitpunkt</label>
                <select v-model="pushNotificationToSend.sendImmediately" class="form-select input-view" id="sendImmediately">
                  <option value="immediately">Sofort</option>
                  <option value="later">Später - Zeitpunkt auswählen</option>
                </select>
                <div id="sendImmediatelyNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4" v-if="pushNotificationToSend.sendImmediately === 'later'">
                <label for="dateToSend" class="form-label label-view">Versanddatum festlegen</label>
                <input type="date" id="dateToSend" v-model="pushNotificationToSend.dateToSend" class="form-control input-view" placeholder="Versanddatum eingeben">
                <div id="dateToSendNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-4" v-if="pushNotificationToSend.sendImmediately === 'later'">
                <label for="timeToSend" class="form-label label-view">Versanduhrzeit festlegen</label>
                <input type="time" id="timeToSend" v-model="pushNotificationToSend.timeToSend" class="form-control input-view" placeholder="Versanduhrzeit eingeben">
                <div id="timeToSendNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div v-if="pushNotificationToSend.pushNotificationType === 'Georeferenzierung'" class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="startDate" class="form-label label-view">Gültig ab (Tag)</label>
                <input type="date" id="startDate" v-model="pushNotificationToSend.startDate" class="form-control input-view" placeholder="Gültig ab Tag eingeben">
                <div id="startDateNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-6">
                <label for="startTime" class="form-label label-view">Gültig ab (Uhrzeit)</label>
                <input type="time" id="startTime" v-model="pushNotificationToSend.startTime" class="form-control input-view" placeholder="Gültig ab Uhrzeit eingeben">
                <div id="startTimeNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-6">
                <label for="endDate" class="form-label label-view">Gültig bis (Tag)</label>
                <input type="date" id="endDate" v-model="pushNotificationToSend.endDate" class="form-control input-view" placeholder="Gültig bis Tag eingeben">
                <div id="endDateNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-6">
                <label for="endTime" class="form-label label-view">Gültig bis (Uhrzeit)</label>
                <input type="time" id="endTime" v-model="pushNotificationToSend.endTime" class="form-control input-view" placeholder="Gültig bis Uhrzeit eingeben">
                <div id="endTimeNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div v-if="pushNotificationToSend.pushNotificationType === 'Georeferenzierung'" class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="latitude" class="form-label label-view">Breitengrad</label>
                <input type="text" id="latitude" class="form-control" v-model="pushNotificationToSend.latitude" placeholder="Breitengrad eingeben" maxlength="8">
                <div id="latitudeNote" class="form-text error-note"></div>
              </div>
              <div class="col-lg-6">
                <label for="longitude" class="form-label label-view">Längengrad</label>
                <input type="text" id="longitude" class="form-control" v-model="pushNotificationToSend.longitude" placeholder="Längengrad eingeben" maxlength="8">
                <div id="longitudeNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div v-if="pushNotificationToSend.pushNotificationType === 'Nachricht' || pushNotificationToSend.pushNotificationType === 'Testnachricht'" class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-2">
                Dringlichkeit
                <select v-model="pushNotificationToSend.priority" class="form-select input-view" id="priority">
                  <option v-for="priority in priorities" :key="priority" :value="priority">{{priority}}</option>
                </select>
                <div id="priorityNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputTitle" class="form-label label-view">Titel</label>
                <input type="text" id="title" class="form-control" v-model="pushNotificationToSend.title" placeholder="Titel eingeben" maxlength="100">
                <div id="titleNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputMessage" class="form-label">Nachricht</label>
                <textarea class="form-control" id="message" v-model="pushNotificationToSend.message" rows="3" placeholder="Nachricht eingeben" maxlength="255"></textarea>
                <div id="messageNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="text-end mt-4 mt-sm-2">
            <button type="submit" @click="savePushNotification()" class="btn btn-primary">Push Benachrichtigung versenden / speichern >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
export default {
  name: 'PushNotificationsPage',
  data () {
    return {
      types: [],
      regions: [],
      recipientCluster: [],
      recipientPartnerCategories: [],
      recipientCardsRegistered: [],
      priorities: [],
      pushNotificationToSend: {
        pushNotificationType: '',
        recipientRegion: '',
        recipientPartnerOrCustomer: '',
        recipientPartnerCategories: [],
        recipientCardsRegistered: '',
        sendImmediately: '',
        dateToSend: '',
        timeToSend: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        latitude: '',
        longitude: '',
        priority: '',
        title: '',
        message: ''
      }
    }
  },
  methods: {
    savePushNotification() {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.clearAllValidationErrors()
      if (this.validateRegistrationData()) {
        this.$store.dispatch('savePushNotification', this.pushNotificationToSend)
          .then(() => {
            this.pushNotificationToSend.pushNotificationType = ''
            this.pushNotificationToSend.recipientRegion = ''
            this.pushNotificationToSend.recipientPartnerOrCustomer = ''
            this.pushNotificationToSend.recipientPartnerCategories = []
            this.pushNotificationToSend.recipientCardsRegistered = ''
            this.pushNotificationToSend.sendImmediately = ''
            this.pushNotificationToSend.dateToSend = ''
            this.pushNotificationToSend.timeToSend = ''
            this.pushNotificationToSend.priority = ''
            this.pushNotificationToSend.title = ''
            this.pushNotificationToSend.message = ''
          }).catch(() => {})
          .finally(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Das Formular enthält Fehler.' })
      }
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    validateRegistrationData () {
      this.validationStatus = true
      Object.keys(this.pushNotificationToSend).forEach(key => {
        if (key === 'pushNotificationType' && !this.pushNotificationToSend.pushNotificationType) {
          this.showValidationError('pushNotificationType', 'Das Feld muss ausgewählt werden.')
        }
        if (key === 'title' && !this.pushNotificationToSend.title) {
          this.showValidationError('title', 'Das Feld muss ausgewählt werden.')
        }
        if (key === 'message' && !this.pushNotificationToSend.message) {
          this.showValidationError('message', 'Das Feld muss ausgewählt werden.')
        }
        if (this.pushNotificationToSend.pushNotificationType === 'Nachricht' || this.pushNotificationToSend.pushNotificationType === 'Testnachricht') {
          if (!this.pushNotificationToSend[key] && (key === 'recipientRegion' || key === 'recipientPartnerOrCustomer' || key === 'sendImmediately' || key === 'priority')) {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
          } else if (key === 'sendImmediately' && this.pushNotificationToSend[key] === 'later') {
            if (!this.pushNotificationToSend.dateToSend) {
              this.showValidationError('dateToSend', 'Das Feld muss ausgefüllt werden.')
            }
            if (!this.pushNotificationToSend.timeToSend) {
              this.showValidationError('timeToSend', 'Das Feld muss ausgefüllt werden.')
            }
          } else if (key === 'recipientPartnerOrCustomer' && (this.pushNotificationToSend[key].toLowerCase() === 'kunden' || this.pushNotificationToSend[key].toLowerCase() === 'alle')) {
            if (!this.pushNotificationToSend.recipientCardsRegistered) {
              this.showValidationError('recipientCardsRegistered', 'Das Feld muss ausgefüllt werden.')
            }
          }
        } else if (this.pushNotificationToSend.pushNotificationType === 'Georeferenzierung') {
          if (!this.pushNotificationToSend[key] && (key === 'latitude' || key === 'longitude' || key === 'recipientRegion' || key === 'recipientPartnerOrCustomer')) {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
          }
        }
      })

      return this.validationStatus
    },
    clearAllValidationErrors() {
      Object.keys(this.pushNotificationToSend).forEach(key => {
        const element = document.getElementById(key)
        if (element) {
          element.classList.remove('form-element-danger')
        }
        const elementNote = document.getElementById(key + 'Note')
        if (elementNote) {
          elementNote.innerHTML = ''
        }
      })
    },
    showValidationError(key, validationMessage) {
      const element = document.getElementById(key)
      if (element) {
        element.classList.add('form-element-danger')
      }
      const elementNote = document.getElementById(key + 'Note')
      if (elementNote) {
        elementNote.innerHTML = validationMessage
      }
      this.validationStatus = false
    },
    fetchPushNotificationsData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('getPushNotificationsData')
        .then((response) => {
          this.types = response.types
          this.regions = response.regions
          this.recipientCluster = response.recipientCluster
          this.recipientPartnerCategories = response.recipientPartnerCategories
          this.recipientCardsRegistered = response.recipientCardsRegistered
          this.priorities = response.priorities
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.fetchPushNotificationsData()
  }
}
</script>
