<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <div v-html="this.text">
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
  name: 'DeclarationOfConsentPage',
  data () {
    return {
      text: ''
    }
  },
  methods: {
    loadDeclarationOfConsentText () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('loadDeclarationOfConsentText')
        .then((responseText) => {
          this.text = responseText
        })
        .catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.loadDeclarationOfConsentText()
  }
}
</script>
