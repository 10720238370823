<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 text-center mb-5 mg-lg-0">
        <router-link :to="{ name: 'PartnerLogin' }">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
        </router-link>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1><span class="fw-bold">Registrierung</span> als Interessent</h1>
          <p>Hier können Sie sich als Interessent registrieren. Nach der Registrierung und manueller Überprüfung wird Ihr Account freigeschaltet.</p>
            <h3>Firmendaten</h3>
              <div class="pt-3">
                <div class="pt-2 border-top" v-if="!cardNameExists">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCardName" class="form-label label-view">Region</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.cardName" @change="onRegionChange" class="form-select input-view" id="cardName">
                            <option v-for="region in regions" :key="region" :value="region.acf.card_name">{{region.acf.card_name}}</option>
                          </select>
                        </div>
                      </div>
                      <div id="cardNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyName" class="form-label label-view">Firmenname</label>
                      <input v-model="registrationData.companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                      <div id="companyNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyAddressAdditional" class="form-label label-view">Adresse-Zusatz</label>
                      <input v-model="registrationData.companyAddressAdditional" type="text" id="companyAddressAdditional" class="form-control input-view" placeholder="Adress-Zusatz eingeben (optional)">
                      <div id="companyAddressAdditionalNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="companyStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyZip" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="companyZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyCity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="companyCityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyCountry" class="form-label label-view">Land</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyCountry" class="form-select input-view" id="companyCountry">
                            <option value="DE">Deutschland</option>
                            <option value="CH">Schweiz</option>
                            <option value="AT">Österreich</option>
                            <option value="FR">Frankreich</option>
                            <option value="BE">Belgien</option>
                            <option value="LU">Luxemburg</option>
                            <option value="DK">Dänemark</option>
                            <option value="NO">Norwegen</option>
                            <option value="SE">Schweden</option>
                            <option value="FI">Finnland</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="NL">Niederlande</option>
                            <option value="PL">Polen</option>
                            <option value="PT">Portugal</option>
                            <option value="RO">Rumänien</option>
                            <option value="RU">Russland</option>
                            <option value="GR">Griechenland</option>
                            <option value="AL">Albanien</option>
                            <option value="AD">Andorra</option>
                            <option value="BG">Bulgarien</option>
                            <option value="IT">Italien</option>
                            <option value="IE">Irland</option>
                            <option value="EE">Estland</option>
                            <option value="LV">Lettland</option>
                            <option value="LT">Litauen</option>
                            <option value="KZ">Kasachstan</option>
                            <option value="SM">San Marino</option>
                            <option value="RS">Serbien</option>
                            <option value="SK">Slowakei</option>
                            <option value="SI">Slowenien</option>
                            <option value="ES">Spanien</option>
                            <option value="CZ">Tschechien</option>
                            <option value="TR">Türkei</option>
                            <option value="UA">Ukraine</option>
                            <option value="HU">Ungarn</option>
                            <option value="GB">Vereinigtes Königreich</option>
                            <option value="MT">Malta</option>
                            <option value="MC">Monaco</option>
                            <option value="MK">Nordmazedonien</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyCountryNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top" v-if="communities.length > 1">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="community" class="form-label label-view">Gemeindezugehörigkeit</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.community" class="form-select input-view" id="community">
                            <option v-for="community in communities" :key="community.value" :value="community.value">{{community.label}}</option>
                          </select>
                        </div>
                      </div>
                      <div id="communityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top" v-if="!(registrationData.community === 'Leinfelden-Echterdingen' || registrationData.region === 'Leinfelden-Echterdingen')">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="companyGewerbeverein" class="form-label label-view">Mitglied Gewerbeverein (Info)</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyGewerbeverein" class="form-select input-view" id="companyGewerbeverein">
                            <option v-for="vereinsname in regionCheckoutData.requirement_to_join_club_names" :key="vereinsname.requirement_to_join_club_name" :value="vereinsname.requirement_to_join_club_name">{{vereinsname.requirement_to_join_club_name}}</option>
                            <option value="Kein Mitglied, möchte aber Infos">Kein Mitglied, möchte aber Infos</option>
                            <option value="Kein Mitglied">Kein Mitglied</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyGewerbevereinNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-9">
                      <label for="partnerInterestedIn" class="form-label label-view">Ich bin interessiert an...</label>
                      <div class="row">
                        <div class="col-auto">
                          <select v-model="registrationData.partnerInterestedIn" class="form-select input-view" id="partnerInterestedIn">
                            <option value="Akzeptanzpartner">Akzeptanzpartner</option>
                            <option value="Arbeitgeber">Arbeitgeber</option>
                            <option value="Beides">Beides</option>
                          </select>
                        </div>
                      </div>
                      <div id="partnerInterestedInNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-9">
                      <label for="companySector" class="form-label label-view">Ihre Branche</label>
                      <div class="row">
                        <div class="col-12">
                          <select v-model="registrationData.companySector" class="form-select input-view" id="companySector">
                            <option v-for="sector in sectors" :key="sector" :value="sector">{{sector}}</option>
                          </select>
                        </div>
                      </div>
                      <div id="companySectorNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <br />
                <h3>Ansprechpartner</h3>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonGender" class="form-label label-view">Geschlecht</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.contactPersonGender" class="form-select input-view" id="contactPersonGender">
                            <option value="männlich">männlich</option>
                            <option value="weiblich">weiblich</option>
                            <option value="divers">divers</option>
                            <option value="sonstige">sonstige</option>
                          </select>
                        </div>
                      </div>
                      <div id="contactPersonGenderNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonFirstName" class="form-label label-view">Vorname</label>
                      <input v-model="registrationData.contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                      <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonLastName" class="form-label label-view">Nachname</label>
                      <input v-model="registrationData.contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                      <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonEmail" class="form-label label-view">E-Mail-Adresse</label>
                      <input v-model="registrationData.contactPersonEmail" type="email" id="contactPersonEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                      <div id="contactPersonEmailNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonEmailRepeated" class="form-label label-view">E-Mail-Adresse wiederholen</label>
                      <input v-model="registrationData.contactPersonEmailRepeated" type="email" id="contactPersonEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                      <div id="contactPersonEmailRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="ceoPhone" class="form-label label-view">Telefonnummer</label>
                      <input v-model="registrationData.ceoPhone" type="text" id="ceoPhone" class="form-control input-view" placeholder="Telefonnummer eingeben" maxlength="30">
                      <div id="ceoPhoneNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonPassword" class="form-label label-view">Passwort</label>
                      <input v-model="registrationData.contactPersonPassword" @input="passwordChecker" type="password" id="contactPersonPassword" class="form-control input-view" placeholder="Passwort eingeben" maxlength="50">
                      <div id="contactPersonPasswordNote" class="form-text error-note"></div>
                      <div id="passwordChecker" class="form-text text-start">
                          <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                          <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                          <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                          <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonPasswordRepeated" class="form-label label-view">Passwort wiederholen</label>
                      <input v-model="registrationData.contactPersonPasswordRepeated" type="password" id="contactPersonPasswordRepeated" class="form-control input-view" placeholder="Passwort wiederholen" maxlength="50">
                      <div id="contactPersonPasswordRepeatedNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="contactPersonRole" class="form-label label-view">Funktion</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.contactPersonRole" class="form-select input-view" id="contactPersonRole">
                            <option v-for="role in contactPersonRoles" :key="role" :value="role">{{role}}</option>
                          </select>
                        </div>
                      </div>
                      <div id="contactPersonRoleNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 mb-3 border-top">
                  <div class="row">
                    <div class="col-lg-12">
                      <p>Im Rahmen der Registrierung und Nutzung des Interessenten-, Partner-, &amp; Arbeitgeberportals verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }">Datenschutzerkärung</router-link>.</p>
                    </div>
                  </div>
                </div>
                <div class="pt-2 mb-3 border-top border-bottom">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCaptcha" class="form-label label-view">Captcha-Code</label>
                      <VueClientRecaptcha
                          :value="captchaInputValue"
                          :count="3"
                          chars="abcdefghkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789"
                          @isValid="checkValidCaptcha"
                        >
                        <template #icon>
                          <img width="60" style="width: 60px;" :src="require(`../assets/img/black-refresh-icon.png`)">
                        </template>
                      </VueClientRecaptcha>
                        <div class="form my-3 fs-3">
                          <input v-model="captchaInputValue" type="text" class="form-control" id="inputCaptcha" placeholder="Captcha-Code eingeben..." />
                        </div>
                      <div id="captchaNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button @click="saveRegistrationData" class="btn btn-primary">Jetzt registrieren &gt;&gt;</button>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import VueClientRecaptcha from 'vue-client-recaptcha'
const slugify = require('slugify')

export default {
  name: 'InterestRegistrationPage',
  data () {
    return {
      registrationData: {
        companyName: '',
        companyAddressAdditional: '',
        companyStreet: '',
        companyZip: '',
        companyCity: '',
        companyCountry: '',
        companyGewerbeverein: '',
        companySector: '',
        partnerInterestedIn: '',
        contactPersonGender: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        contactPersonEmail: '',
        contactPersonEmailRepeated: '',
        contactPersonRole: '',
        ceoPhone: '',
        contactPersonPassword: '',
        contactPersonPasswordRepeated: '',
        region: this.$store.state.region.name,
        cardName: this.$store.state.region.cardName
      },
      communities: [],
      sectors: [],
      contactPersonRoles: [],
      regionCheckoutData: {},
      captchaInputValue: '',
      isValidCaptcha: false,
      validationStatus: false,
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg',
      cardNameExists: this.$store.state.region.cardName,
      regions: []
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    }
  },
  methods: {
    async saveRegistrationData () {
      Object.keys(this.registrationData).forEach(key => {
        if (key !== 'region') {
          if (key === 'cardName' && this.cardNameExists) {
            return
          }
          const element = document.getElementById(key)
          if (element) {
            element.classList.remove('form-element-danger')
          }
          const elementNote = document.getElementById(key + 'Note')
          if (elementNote) {
            elementNote.innerHTML = ''
          }
        }
      })
      if (!this.registrationData.region) {
        for (const region of this.regions) {
          if (region.acf.card_name === this.registrationData.cardName) {
            this.registrationData.region = region.acf.region_name
          }
        }
      }
      document.getElementById('inputCaptcha').classList.remove('form-element-danger')
      document.getElementById('captchaNote').innerHTML = ''
      if (this.validateRegistrationData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        if (typeof this.registrationData.cardName === 'undefined' || this.registrationData.cardName === '' || typeof this.registrationData.region === 'undefined' || this.registrationData.region === '') {
          this.showValidationError('cardName', 'Es ist ein Fehler bei der Region aufgetreten. Bitte kontaktieren Sie den Support.')
          return
        }
        this.$store.dispatch('interestRegistration', this.registrationData)
          .then(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
            if (this.registrationData.partnerInterestedIn === 'Akzeptanzpartner' || this.registrationData.partnerInterestedIn === 'Partner') {
              this.$router.push({ name: 'PartnerLogin' })
            } else if (this.registrationData.partnerInterestedIn === 'Arbeitgeber') {
              this.$router.push({ name: 'EmployerLogin' })
            } else {
              this.$router.push({ name: 'PartnerLogin' })
            }
          }).catch(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Das Formular enthält Fehler.' })
      }
    },
    validateRegistrationData () {
      this.validationStatus = true
      Object.keys(this.registrationData).forEach(key => {
        if (key === 'region' || (key === 'companyGewerbeverein' && this.registrationData.region === 'Leinfelden-Echterdingen')) {
          return
        }
        if (!this.registrationData[key] && key !== 'companyAddressAdditional') {
          this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
        } else if (key !== 'contactPersonFirstName' && key !== 'contactPersonLastName' && key !== 'companyCountry' && key !== 'contactPersonRole' && key !== 'companyAddressAdditional' && this.registrationData[key].length < 3) {
          this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
        } else if (key === 'companyCountry' && this.registrationData[key].length < 2) {
          this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
        } else if ((key === 'contactPersonGender' || key === 'contactPersonFirstName' || key === 'contactPersonLastName' || key === 'companyCity' || key === 'companyCountry') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
        } else if (key === 'companyStreet' && !(/\d/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
        } else if (key === 'ceoPhone' && !(/^[+]?[0-9 ]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Die Telefonnummer ist ungültig. Sie darf nur Ziffern oder am Anfang das "+" für die Ländervorwahl enthalten.')
        } else if (key === 'companyZip' && !(/^\d+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
        } else if ((key === 'companyZip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
          this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
        } else if (key === 'contactPersonPassword' && !(/^[a-zA-ZäöüßÄÖÜ0-9!$%()*,-.?@^_~]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Passwort enthält ungültige Zeichen. Neben Buchstaben und Ziffern sind nur folgende Sonderzeichen erlaubt:     !$%()*,-.?@^_~')
        } else if (key === 'contactPersonPassword' && (this.registrationData[key].length < 8 || !/[!$%()*,-.?@^_~]+/.test(this.registrationData[key]) || !/[0-9]+/.test(this.registrationData[key]) || !/[A-ZÄÖÜ]+/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Nummer, 1 Großbuchstaben und 1 Sonderzeichen (!$%()*,-.?@^_~) enthalten.')
        } else if ((key === 'contactPersonPassword' || key === 'contactPersonPasswordRepeated') && this.registrationData.contactPersonPassword !== this.registrationData.contactPersonPasswordRepeated) {
          this.showValidationError(key, 'Die Passwörter stimmen nicht überein.')
        } else if (key === 'contactPersonEmail' && !this.registrationData.contactPersonEmail.includes('@')) {
          this.showValidationError(key, 'Die E-Mail-Adresse ist ungültig.')
        } else if ((key === 'contactPersonEmail' || key === 'contactPersonEmailRepeated') && this.registrationData.contactPersonEmail !== this.registrationData.contactPersonEmailRepeated) {
          this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
        } else if (key === 'contactPersonPassword' && this.registrationData.contactPersonPassword.length > 50) {
          this.showValidationError(key, 'Das Passwort darf maximal 50 Zeichen lang sein.')
        }
      })
      if (!this.captchaInputValue) {
        document.getElementById('inputCaptcha').classList.add('form-element-danger')
        document.getElementById('captchaNote').innerHTML = 'Sie müssen das Captcha ausfüllen'
        this.validationStatus = false
      } else if (!this.isValidCaptcha) {
        document.getElementById('inputCaptcha').classList.add('form-element-danger')
        document.getElementById('captchaNote').innerHTML = 'Das Captcha ist nicht korrekt'
        this.validationStatus = false
      }
      return this.validationStatus
    },
    showValidationError(key, validationMessage) {
      const element = document.getElementById(key)
      if (element) {
        element.classList.add('form-element-danger')
      }
      const elementNote = document.getElementById(key + 'Note')
      if (elementNote) {
        elementNote.innerHTML = validationMessage
      }
      this.validationStatus = false
    },
    passwordChecker () {
      if (/[A-ZÄÖÜ]+/.test(this.registrationData.contactPersonPassword)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(this.registrationData.contactPersonPassword)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(this.registrationData.contactPersonPassword)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (this.registrationData.contactPersonPassword.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    checkValidCaptcha (value) {
      this.isValidCaptcha = value
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    onRegionChange() {
      this.registrationData.community = undefined
      for (const region of this.regions) {
        if (region.acf.card_name === this.registrationData.cardName) {
          this.registrationData.region = region.acf.region_name
          this.fetchRegionPartnerCheckoutData(region.acf.region_name, region.slug)
          this.fetchCommunities(region.acf.region_name, region.slug)
        }
      }
    },
    async fetchRegionPartnerCheckoutData (regionName, regionSlug) {
      this.$store.commit('SET_LOADING_STATUS', true)
      regionName = typeof regionName !== 'undefined' ? regionName : this.$store.state.region.name
      regionSlug = typeof regionSlug !== 'undefined' ? regionSlug : this.$store.state.region.slug
      await this.$store.dispatch('getRegionPartnerCheckoutData', { regionName, regionSlug }).then(response => {
        this.regionCheckoutData = response[0].acf
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async fetchCommunities (regionName, regionSlug) {
      this.$store.commit('SET_LOADING_STATUS', true)
      regionName = typeof regionName !== 'undefined' ? regionName : this.$store.state.region.name
      regionSlug = typeof regionSlug !== 'undefined' ? regionSlug : this.$store.state.region.slug
      await this.$store.dispatch('getCommunitiesForRegion', { regionName, regionSlug }).then(response => {
        this.communities = response
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async fetchFormSelectValues (regionName, regionSlug) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getInterestRegistrationFormValues', { regionName, regionSlug }).then(response => {
        this.sectors = response.sectors
        this.contactPersonRoles = response.contact_person_roles
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    }
  },
  created () {
    if (typeof this.registrationData.cardName === 'undefined' || this.registrationData.cardName === '' || typeof this.registrationData.region === 'undefined' || this.registrationData.region === '') {
      this.$store.dispatch('getAllRegions')
        .then(response => {
          this.regions = response.data.filter(function(region) {
            return region.acf.card_name !== 'Bad Säckingen CARD'
          })
        })
        .catch(() => {
          this.deactivateForm()
        })
    } else {
      this.fetchRegionPartnerCheckoutData()
      this.fetchCommunities()
    }
    this.fetchFormSelectValues()
  },
  components: {
    VueClientRecaptcha
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }

  .label-view {
    margin-bottom: 5px;
  }

  #gender {
    min-width: 130px;
  }

  #country {
    min-width: 220px;
  }

  #companyGewerbeverein {
    min-width: 350px;
  }

  .input-view {
    border-radius: 0;
  }

  .form-control:focus, .form-select:focus {
    border: 1px solid #003882;
    box-shadow: 0 0 0 0.1rem #003882;
    padding-left: 5px;
  }

  .form-select {
    width: 290px;
  }
</style>
