<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 text-center mb-5 mg-lg-0">
        <router-link :to="{ name: 'Login' }">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
        </router-link>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1><span class="fw-bold">Registrierung</span> Ihrer CARD</h1>
          <p>Hier können Sie Ihre CARD registrieren</p>
            <div class="pt-3">
              <div class="pt-2 border-top">
                <div class="row" ref="formstart">
                  <div class="col-lg-7">
                    <label for="inputCardID" class="form-label label-view">Kartennummer</label>
                    <div id="barcodeReader" width="300px"></div>
                    <div class="input-group flex-nowrap">
                      <input v-model="registrationData.cardID" type="text" id="cardID" class="form-control input-view" placeholder="Kartennummer eingeben">
                      <button @click="startScanBarcode()" class="btn btn-secondary" id="scan-barcode-button" type="button"><img class="d-block img-fluid" :src="require('../assets/img/booking/barcode.svg')" width="20" ></button>
                    </div>
                    <div id="cardIDNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputEmail" class="form-label label-view">E-Mail-Adresse</label>
                    <input v-model="registrationData.email" type="email" id="email" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                    <div id="emailNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputEmailRepeated" class="form-label label-view">E-Mail-Adresse wiederholen</label>
                    <input v-model="registrationData.emailRepeated" type="email" id="emailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                    <div id="emailRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputPassword" class="form-label label-view">Passwort</label>
                    <input v-model="registrationData.password" @input="passwordChecker" type="password" id="password" class="form-control input-view" placeholder="Passwort eingeben">
                    <div id="passwordNote" class="form-text error-note"></div>
                    <div id="passwordChecker" class="form-text text-start">
                        <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                        <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                        <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                        <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputPasswordRepeated" class="form-label label-view">Passwort wiederholen</label>
                    <input v-model="registrationData.passwordRepeated" type="password" id="passwordRepeated" class="form-control input-view" placeholder="Passwort wiederholen">
                    <div id="passwordRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="title" class="form-label label-view">Titel</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.title" class="form-select input-view" id="title">
                          <option value=""></option>
                          <option v-for="title in titles" :key="title" :value="title">{{title}}</option>
                        </select>
                      </div>
                    </div>
                    <div id="titleNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="gender" class="form-label label-view">Geschlecht</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.gender" class="form-select input-view" id="gender">
                          <option v-for="gender in genders" :key="gender" :value="gender">{{gender}}</option>
                        </select>
                      </div>
                    </div>
                    <div id="genderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="firstName" class="form-label label-view">Vorname</label>
                    <input v-model="registrationData.firstName" type="text" id="firstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                    <div id="firstNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="lastName" class="form-label label-view">Nachname</label>
                    <input v-model="registrationData.lastName" type="text" id="lastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                    <div id="lastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="street" class="form-label label-view">Straße und Hausnummer</label>
                    <input v-model="registrationData.street" type="text" id="street" class="form-control input-view" placeholder="Straße und Hausnummer eingeben" maxlength="45">
                    <div id="streetNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="zip" class="form-label label-view">Postleitzahl</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <input v-model="registrationData.zip" type="text" maxlength="5" id="zip" class="form-control input-view" placeholder="PLZ">
                      </div>
                    </div>
                    <div id="zipNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="city" class="form-label label-view">Ort</label>
                    <input v-model="registrationData.city" type="text" id="city" class="form-control input-view" placeholder="Ort eingeben" maxlength="30">
                    <div id="cityNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="country" class="form-label label-view">Land</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.country" class="form-select input-view" id="country">
                          <option value="DE">Deutschland</option>
                          <option value="CH">Schweiz</option>
                          <option value="AT">Österreich</option>
                          <option value="FR">Frankreich</option>
                          <option value="BE">Belgien</option>
                          <option value="LU">Luxemburg</option>
                          <option value="DK">Dänemark</option>
                          <option value="NO">Norwegen</option>
                          <option value="SE">Schweden</option>
                          <option value="FI">Finnland</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="NL">Niederlande</option>
                          <option value="PL">Polen</option>
                          <option value="PT">Portugal</option>
                          <option value="RO">Rumänien</option>
                          <option value="RU">Russland</option>
                          <option value="GR">Griechenland</option>
                          <option value="AL">Albanien</option>
                          <option value="AD">Andorra</option>
                          <option value="BG">Bulgarien</option>
                          <option value="IT">Italien</option>
                          <option value="IE">Irland</option>
                          <option value="EE">Estland</option>
                          <option value="LV">Lettland</option>
                          <option value="LT">Litauen</option>
                          <option value="KZ">Kasachstan</option>
                          <option value="SM">San Marino</option>
                          <option value="RS">Serbien</option>
                          <option value="SK">Slowakei</option>
                          <option value="SI">Slowenien</option>
                          <option value="ES">Spanien</option>
                          <option value="CZ">Tschechien</option>
                          <option value="TR">Türkei</option>
                          <option value="UA">Ukraine</option>
                          <option value="HU">Ungarn</option>
                          <option value="GB">Vereinigtes Königreich</option>
                          <option value="MT">Malta</option>
                          <option value="MC">Monaco</option>
                          <option value="MK">Nordmazedonien</option>
                        </select>
                      </div>
                    </div>
                    <div id="countryNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="phone" class="form-label label-view">Telefon (optional)</label>
                    <input v-model="registrationData.phone" type="text" id="phone" class="form-control input-view" placeholder="+49 12345678910" maxlength="30">
                    <div id="phoneNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 mb-3 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="birthdate" class="form-label label-view">Geburtsdatum (TT.MM.JJJJ)</label>
                    <input v-model="registrationData.birthdate" type="text" id="birthdate" class="form-control input-view" placeholder="Geburtsdatum eingeben (TT.MM.JJJJ)">
                    <div id="birthdateNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 mb-3 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-check text-start">
                      <input v-model="registrationData.conditionsConsent" type="checkbox" class="form-check-input p-2" id="conditionsConsent">
                      <label class="form-check-label" for="conditionsConsent">
                        Mit der Registierung und Nutzung meiner CARD akzeptiere ich die zugrundeliegenden Teilnahmebedingungen. <router-link :to="{ name: 'ConditionsOfParticipation' }">Unsere Teilnahmebedingungen finden Sie hier</router-link>.*
                      </label>
                      <div id="conditionsConsentNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-check text-start">
                      <input v-model="registrationData.marketingAdsConsent" type="checkbox" class="form-check-input p-2" id="marketingAdsConsent">
                      <label class="form-check-label" for="marketingAdsConsent">
                        Ich bin damit einverstanden, dass ich per E-Mail persönliche auf mich ausgerichtete Informationen und Werbung, wie z.B. Geburtstagsbonus, Sonderangebote, Bonus- und Rabattaktionen, lokale Events der teilnehmenden Geschäfte, erhalte. Ich habe die <router-link :to="{ name: 'DeclarationOfConsent' }">Einwilligungserklärung</router-link> gelesen. Ihre Einwilligung können Sie nach erfolgter Registrierung jederzeit in Ihrem Kundenkonto widerrufen.
                      </label>
                      <div id="marketingAdsConsentNote" class="form-text error-note"></div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-check text-start">
                      <input v-model="registrationData.newsletterConsent" type="checkbox" class="form-check-input p-2" id="newsletterConsent">
                      <label class="form-check-label" for="newsletterConsent">
                        Ich abonniere den kostenlosen Newsletter zu meiner CARD<br />
                        (Versand maximal einmal im Monat). Bitte bestätigen Sie den Empfang in der separat zugesandten E-Mail.<br />
                        Eine Abmeldung vom Newsletter ist jederzeit innerhalb Ihres Kundenkontos möglich. <br />
                        <br />
                        Im Rahmen der Registrierung und Nutzung Ihrer CARD verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }">Datenschutzerkärung</router-link>.
                      </label>
                      <div id="newsletterConsentNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 mb-3 border-top border-bottom">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCaptcha" class="form-label label-view">Captcha-Code</label>
                     <VueClientRecaptcha
                        :value="captchaInputValue"
                        :count="3"
                        chars="abcdefghkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789"
                        @isValid="checkValidCaptcha"
                      >
                      <template #icon>
                        <img width="60" style="width: 60px;" :src="require(`../assets/img/black-refresh-icon.png`)">
                      </template>
                    </VueClientRecaptcha>
                      <div class="form my-3 fs-3">
                        <input v-model="captchaInputValue" type="text" class="form-control" id="inputCaptcha" placeholder="Captcha-Code eingeben..." />
                      </div>
                    <div id="captchaNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button @click="saveRegistrationData" class="btn btn-primary">Registrierung absenden >></button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import Helper from '../helper'
import VueClientRecaptcha from 'vue-client-recaptcha'
import { Html5Qrcode, Html5QrcodeSupportedFormats, Html5QrcodeScanType } from 'html5-qrcode'
const slugify = require('slugify')

export default {
  name: 'RegistrationPage',
  data () {
    return {
      registrationData: {
        cardID: '',
        email: '',
        emailRepeated: '',
        password: '',
        passwordRepeated: '',
        title: '',
        gender: '',
        firstName: '',
        lastName: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        birthdate: '',
        conditionsConsent: false,
        marketingAdsConsent: false,
        newsletterConsent: false
      },
      genders: [],
      titles: [],
      captchaInputValue: '',
      isValidCaptcha: false,
      validationStatus: false,
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg'
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    }
  },
  methods: {
    async fetchFormSelectValues (regionName, regionSlug) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getCustomerRegistrationFormValues', { regionName, regionSlug }).then(response => {
        this.genders = response.genders
        this.titles = response.titles
      }).catch(() => {
        this.deactivateForm()
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    async saveRegistrationData () {
      Object.keys(this.registrationData).forEach(key => {
        Helper.removeValidationError(key)
      })
      if (this.validateRegistrationData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.$store.dispatch('customerRegistration', {
          cardID: this.registrationData.cardID,
          email: this.registrationData.email,
          emailRepeated: this.registrationData.emailRepeated,
          password: this.registrationData.password,
          passwordRepeated: this.registrationData.passwordRepeated,
          title: this.registrationData.title,
          gender: this.registrationData.gender,
          firstName: this.registrationData.firstName,
          lastName: this.registrationData.lastName,
          street: this.registrationData.street,
          zip: this.registrationData.zip,
          city: this.registrationData.city,
          country: this.registrationData.country,
          phone: this.registrationData.phone,
          birthdate: this.registrationData.birthdate,
          conditionsConsent: this.registrationData.conditionsConsent,
          marketingAdsConsent: this.registrationData.marketingAdsConsent,
          newsletterConsent: this.registrationData.newsletterConsent
        })
          .then(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
            this.$router.push({ name: 'Login' })
          }).catch(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      } else {
        const formstart = this.$refs.formstart
        if (formstart) {
          formstart.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    validateRegistrationData () {
      this.validationStatus = true
      Object.keys(this.registrationData).forEach(key => {
        if (!this.registrationData[key] && key !== 'conditionsConsent' && key !== 'marketingAdsConsent' && key !== 'newsletterConsent' && key !== 'phone' && key !== 'title') {
          this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
        } else if (key !== 'firstName' && key !== 'lastName' && key !== 'phone' && key !== 'country' && key !== 'title' && this.registrationData[key].length < 3) {
          this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
        } else if (key === 'country' && this.registrationData[key].length < 2) {
          this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
        } else if ((key === 'gender' || key === 'firstName' || key === 'lastName' || key === 'city' || key === 'country') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
        } else if (key === 'street' && !(/\d/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
        } else if ((key === 'zip' || key === 'cardID') && !(/^\d+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
        } else if ((key === 'zip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
          this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
        } else if (key === 'password' && !(/^[a-zA-ZäöüßÄÖÜ0-9!$%()*,-.?@^_~]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Passwort enthält ungültige Zeichen. Neben Buchstaben und Ziffern sind nur folgende Sonderzeichen erlaubt:     !$%()*,-.?@^_~')
        } else if (key === 'password' && (this.registrationData[key].length < 8 || !/[!$%()*,-.?@^_~]+/.test(this.registrationData[key]) || !/[0-9]+/.test(this.registrationData[key]) || !/[A-ZÄÖÜ]+/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Nummer, 1 Großbuchstaben und 1 Sonderzeichen (!$%()*,-.?@^_~) enthalten.')
        } else if ((key === 'password' || key === 'passwordRepeated') && this.registrationData.password !== this.registrationData.passwordRepeated) {
          this.showValidationError(key, 'Die Passwörter stimmen nicht überein.')
        } else if (key === 'email' && !this.registrationData.email.includes('@')) {
          this.showValidationError(key, 'Die E-Mail-Adresse ist ungültig.')
        } else if ((key === 'email' || key === 'emailRepeated') && this.registrationData.email !== this.registrationData.emailRepeated) {
          this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
        } else if (key === 'birthdate' && !(/^(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Dies ist kein gültiges Geburtsdatum. Es muss das Format TT.MM.JJJJ (mit führenden Nullen) haben.')
        } else if ((key === 'cardID') && (this.registrationData[key].length !== 15 || !this.registrationData[key].startsWith('1761'))) {
          this.showValidationError(key, 'Die Kartennummer ist ungültig.')
        } else if (key === 'phone' && this.registrationData[key].length > 0 && (this.registrationData[key].length < 4 || this.registrationData[key].length > 29 || !/^[+ 0-9]+$/.test(this.registrationData[key]))) {
          this.showValidationError(key, 'Die Telefonnummer ist ungültig.')
        }
        if (key === 'conditionsConsent') {
          if (!this.registrationData.conditionsConsent) {
            this.showValidationError(key, 'Sie müssen den Teilnahmebedingungen zustimmen')
          } else {
            Helper.removeValidationError(key)
          }
        }
      })
      if (!this.captchaInputValue) {
        document.getElementById('inputCaptcha').classList.add('form-element-danger')
        document.getElementById('captchaNote').innerHTML = 'Sie müssen das Captcha ausfüllen'
        this.validationStatus = false
      } else if (!this.isValidCaptcha) {
        document.getElementById('inputCaptcha').classList.add('form-element-danger')
        document.getElementById('captchaNote').innerHTML = 'Das Captcha ist nicht korrekt'
        this.validationStatus = false
      }
      return this.validationStatus
    },
    showValidationError (key, validationMessage) {
      document.getElementById(key).classList.add('form-element-danger')
      document.getElementById(key + 'Note').innerHTML = validationMessage
      this.validationStatus = false
    },
    passwordChecker () {
      if (/[A-ZÄÖÜ]+/.test(this.registrationData.password)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(this.registrationData.password)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(this.registrationData.password)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (this.registrationData.password.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    checkValidCaptcha (value) {
      this.isValidCaptcha = value
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    startScanBarcode () {
      this.html5QrCode = new Html5Qrcode('barcodeReader', {
        formatsToSupport:
        [
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.AZTEC,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.CODE_93,
          Html5QrcodeSupportedFormats.RSS_14,
          Html5QrcodeSupportedFormats.UPC_A,
          Html5QrcodeSupportedFormats.UPC_E,
          Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
          Html5QrcodeSupportedFormats.EAN_8,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.PDF_417
        ]
      })
      const scanCodeSuccessCallback = (decodedText, decodedResult) => {
        const cardIDFromScan = decodedText.replace('\n', '').replace('\r', '')
        this.registrationData.cardID = cardIDFromScan
        this.html5QrCode.stop().then((ignore) => { }).catch(() => { })
      }
      const config = { fps: 60, qrbox: 250, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], useBarCodeDetectorIfSupported: true }

      this.html5QrCode
        .start({ facingMode: 'environment' }, config, scanCodeSuccessCallback)
        .catch((err) => {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kamera konnte nicht gestartet werden. ' + err })
        })
    }
  },
  created () {
    this.fetchFormSelectValues()
  },
  components: {
    VueClientRecaptcha
  },
  beforeUnmount () {
    if (typeof this.html5QrCode !== 'undefined' && this.html5QrCode) {
      this.html5QrCode.stop().then((ignore) => { }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }

  .label-view {
    margin-bottom: 5px;
  }

  #gender {
    min-width: 130px;
  }

  #country {
    min-width: 220px;
  }

  .input-view {
    border-radius: 0;
  }

  .form-control:focus, .form-select:focus {
    border: 1px solid #003882;
    box-shadow: 0 0 0 0.1rem #003882;
    padding-left: 5px;
  }
</style>
