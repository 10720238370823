<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 text-center mb-5 mg-lg-0">
        <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1>Passwort <span class="fw-bold">zurücksetzen</span></h1>
          <div class="col-11 col-md-6 col-lg-8">
            <div class="form my-3 fs-3">
              <label for="inputEmail">E-Mail-Adresse</label>
              <input v-model="email" type="text" class="form-control" id="inputEmail" placeholder="E-Mail-Adresse...">
            </div>
            <label for="inputCaptcha">Captcha-Code</label>
            <VueClientRecaptcha
              :value="captchaInputValue"
              chars="abcdefghkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789"
              @isValid="checkValidCaptcha"
            >
              <template #icon>
                <img width="60" style="width: 60px;" :src="require(`../assets/img/black-refresh-icon.png`)">
              </template>
            </VueClientRecaptcha>
            <div class="form my-3 fs-3">
              <input v-model="captchaInputValue" type="text" class="form-control" id="inputCaptcha" placeholder="Captcha-Code eingeben..." />
            </div>
            <div class="row justify-content-end">
              <div class="col-lg-8">
                <button @click="resetPassword" class="w-100 btn btn-lg btn-primary">Passwort zurücksetzen >></button>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Kunden</strong>konto? <router-link :to="{ name: 'Login' }">Hier geht es zum Login</router-link></p>
              </div>
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Partner</strong>konto? <router-link :to="{ name: 'PartnerLogin' }">Hier geht es zum Login</router-link></p>
              </div>
              <div class="col-12">
                <p>Einloggen in Ihr <strong>Arbeitgeber</strong>konto? <router-link :to="{ name: 'EmployerLogin' }">Hier geht es zum Login</router-link></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>Sie haben Ihre CARD noch nicht registriert? <router-link :to="{ name: 'Registration' }">Jetzt hier registrieren</router-link>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import VueClientRecaptcha from 'vue-client-recaptcha'
const slugify = require('slugify')

export default {
  name: 'ResetPasswordPage',
  data () {
    return {
      email: '',
      captchaInputValue: '',
      isValidCaptcha: false
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    }
  },
  methods: {
    resetPassword () {
      if (!this.email) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen Ihre registrierte E-Mail-Adresse angeben.' })
        return
      }
      if (!this.isValidCaptcha) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Der eingegebene Captcha ist falsch.' })
        return
      }

      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('resetPassword', { email: this.email })
        .then(() => {
          this.email = ''
          this.captchaInputValue = ''
        })
        .catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    checkValidCaptcha (value) {
      this.isValidCaptcha = value
    }
  },
  components: {
    VueClientRecaptcha
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }
</style>
