<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>{{ this.$store.state.user.cardName}} <span class="fw-bold">sperren / übertragen</span></h1>
        <div class="mt-5">
          <h3>{{ this.$store.state.user.cardName}} sperren</h3>
          <div class="pt-2 border-top">
            <div class="py-3">
              <p>Die Karte zum Sperren auswählen:</p>
              <select class="form-select input-view mb-3" @change="oncurrentCardIDToUpdateLockChanged($event)">
                <option v-for="cardID, index in cardIDs" :key="cardID" :value="cardID" :selected="index === 0">{{cardID}}</option>
              </select>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="lockCardCheckbox" role="switch" id="lockCardCheckbox">
                <label class="form-check-label" for="lockCardCheckbox">{{ this.$store.state.user.cardName}} {{currentCardIDToUpdateLock}} sperren</label>
              </div>
            </div>
          </div>
          <div class="pt-2 mb-3 border-top border-bottom">
            <div class="row">
              <div class="col-lg-6">
                <label for="inputPassword" class="form-label label-view">
                  Bitte die Sperre mit Ihrem Passwort bestätigen
                </label>
                <input v-model="lockCardPassword" type="password" id="inputPassword" class="form-control" placeholder="Passwort eingeben">
              </div>
            </div>
          </div>
          <div id="lockHelpBlock" class="form-text error-note py-3 mb-3 border-top border-bottom">
            Nach Ausführung der Kartensperre sind keine Transaktionen mit der Karte mehr möglich und es erfolgt ein automatischer Logout aus dem Kundenportal. Sie können sich aber anschließend wieder einloggen.
          </div>
          <div class="text-end">
            <button @click="updateLockCard" class="btn btn-primary">Speichern >></button>
          </div>
        </div>
        <div class="mt-5 pt-3">
          <h3>Guthaben übertragen</h3>
          <div class="pt-2 mb-3 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputOldCardID" class="form-label label-view">
                  Das Guthaben von folgender/m Kartennummer/Online-Gutschein:
                </label>
              </div>
            </div>
            <div class="row" v-for="(cardIdToTransfer, index) in cardIDsToTransfer" :key="index">
              <div class="col-lg-6">
                <input type="text" :id="('inputCardIDsToTransfer' + index)" v-model="cardIDsToTransfer[index]" class="form-control" placeholder="Kartennummer, die übertragen werden soll">
                <div :id="('cardIDsToTransferNote' + index)" class="form-text error-note"></div>
              </div>
              <div class="col-3">
                <img class="d-inline me-2 enabled-minus-button" v-if="index > 0" @click="removeCardIDToTransfer(index)" width="30" :src="require(`../assets/img/circle-minus.svg`)">
                <img class="d-inline" @click="addNewCardIDToTransfer(index + 1)" v-if="(cardIDsToTransfer.length - 1 === index)" width="30" :src="require(`../assets/img/${ cardIDsToTransfer.length - 1 === index ? 'circle-plus.svg' : 'circle-plus.svg' }`)" :class="cardIDsToTransfer[index] === '' ? 'disabled-plus-button': 'enabled-plus-button'">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-lg-6">
                ...auf folgende Kartennummer übertragen
                <select id="currentCardIDToTransferTo" class="form-select input-view mb-3" @change="onCurrentCardIDToTransferToChanged($event)">
                  <option v-for="cardID, index in cardIDs" :key="cardID" :value="cardID" :selected="index === 0">{{cardID}}</option>
                </select>
                <div id="currentCardIDToTransferToNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div id="transferHelpBlock" class="form-text error-note py-3 mb-3 border-top border-bottom">
            Aus Sicherheitsgründen müssen wir die Übertragung manuell vornehmen.<br />
            In der Regel geschieht dies innerhalb von 72 Stunden. Nach erfolgter Übertragung erhalten Sie eine Bestätigung von uns. Bitte beachten Sie, dass die übertragene Karte danach nicht mehr verwendet werden kann.
          </div>
          <div class="text-end">
            <button @click="sendTransferBalance" class="btn btn-primary">Abschicken >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
require('../assets/img/circle-plus.svg')

export default {
  name: 'LockCardPage',
  components: {
    CardData
  },
  data () {
    return {
      cardIDs: [],
      lockCardCheckbox: false,
      lockCardPassword: '',
      cardIDsToTransfer: [''],
      currentCardIDToUpdateLock: '',
      currentCardIDToTransferTo: ''
    }
  },
  methods: {
    async fetchCardIDs () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getCardIDs').then(response => {
        this.cardIDs = response
        this.currentCardIDToUpdateLock = this.cardIDs[0]
        this.currentCardIDToTransferTo = this.cardIDs[0]
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    oncurrentCardIDToUpdateLockChanged (event) {
      this.currentCardIDToUpdateLock = event.target.value
    },
    onCurrentCardIDToTransferToChanged (event) {
      this.currentCardIDToTransferTo = event.target.value
    },
    updateLockCard() {
      if (this.lockCardCheckbox && this.lockCardPassword !== '' && this.currentCardIDToUpdateLock !== '' && typeof this.currentCardIDToUpdateLock !== 'undefined') {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.$store.dispatch('updateLockCard', { cardIDToLock: this.currentCardIDToUpdateLock, lockCardCheckbox: this.lockCardCheckbox, password: this.lockCardPassword })
          .then(() => {
            this.lockCardCheckbox = false
            this.lockCardPassword = ''
          })
          .catch(() => {})
          .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
      } else {
        if (!this.lockCardCheckbox && !this.lockCardPassword) {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Checkbox bestätigen, dass Sie Ihre CARD sperren möchten und Ihr Passwort eingeben.' })
        } else if (!this.lockCardPassword) {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen Ihr Passwort eingeben, um die Karte zu sperren.' })
        } else if (!this.lockCardCheckbox) {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Checkbox bestätigen, dass Sie Ihre CARD sperren möchten.' })
        } else if (this.currentCardIDToUpdateLock === '' || typeof this.currentCardIDToUpdateLock === 'undefined') {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer zum Sperren ausgewählt.' })
        }
      }
    },
    sendTransferBalance () {
      if (!this.cardIDsToTransfer || this.cardIDsToTransfer.length === 0) {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Kartennummer von der Ihr Guthaben übertragen werden soll eingeben.' })
      } else {
        let isValid = true
        const that = this
        this.cardIDsToTransfer.forEach(function (cardIDToTransfer, index) {
          document.getElementById('inputCardIDsToTransfer' + index).classList.remove('form-element-danger')
          document.getElementById('cardIDsToTransferNote' + index).innerHTML = ''
          document.getElementById('currentCardIDToTransferTo').classList.remove('form-element-danger')
          document.getElementById('currentCardIDToTransferToNote').innerHTML = ''

          if (!cardIDToTransfer || cardIDToTransfer === '') {
            isValid = false
            document.getElementById('inputCardIDsToTransfer' + index).classList.add('form-element-danger')
            document.getElementById('cardIDsToTransferNote' + index).innerHTML = 'Es wurde keine Kartennummer eingegeben.'
            that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer eingegeben.' })
          }

          if (cardIDToTransfer.length !== 15 || !cardIDToTransfer.startsWith('1761')) {
            isValid = false
            document.getElementById('inputCardIDsToTransfer' + index).classList.add('form-element-danger')
            document.getElementById('cardIDsToTransferNote' + index).innerHTML = 'Die Kartennummer ist ungültig.'
            that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kartennummer ist ungültig.' })
          }
        })

        if (this.currentCardIDToTransferTo === '' || typeof this.currentCardIDToTransferTo === 'undefined') {
          isValid = false
          document.getElementById('currentCardIDToTransferTo').classList.add('form-element-danger')
          document.getElementById('currentCardIDToTransferToNote').innerHTML = 'Es wurde keine Kartennummer ausgewählt.'
          that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es wurde keine Kartennummer, auf welche die Karte übertragen werden soll, ausgewählt.' })
        }

        if (isValid === true) {
          this.$store.commit('SET_LOADING_STATUS', true)
          this.$store.dispatch('transferBalance', {
            cardIDsToTransfer: this.cardIDsToTransfer
          })
            .then(() => {
              // clear form
              this.cardIDsToTransfer = ['']
            }).catch(() => {})
            .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
        }
      }
    },
    addNewCardIDToTransfer (index) {
      if (this.cardIDsToTransfer[index - 1] !== '') {
        this.cardIDsToTransfer[index] = ''
      }
    },
    removeCardIDToTransfer (index) {
      this.cardIDsToTransfer.splice(index, 1)
    }
  },
  created () {
    this.fetchCardIDs()
  }
}
</script>

<style scoped>
.disabled-plus-button {
  opacity: 0.4;
}

.enabled-plus-button,
.enabled-minus-button {
  cursor: pointer;
}
</style>
