<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>Kontakt<span class="fw-bold">formular</span></h1>
        <div class="mt-0 mt-sm-3">
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="inputTitle" class="form-label label-view">
                  Anrede
                </label>
                <div class="row">
                  <div class="col-lg-5">
                    <select class="form-select input-view" v-model="salutation" id="inputSalutation">
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="inputFirstName" class="form-label label-view">
                  Vorname
                </label>
                <input type="text" id="inputFirstName" class="form-control" v-model="firstName" placeholder="Vorname eingeben">
              </div>
              <div class="col-lg-6">
                <label for="inputLastName" class="form-label label-view">
                  Nachname
                </label>
                <input type="text" id="inputLastName" class="form-control" v-model="lastName" placeholder="Nachname eingeben">
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputMail" class="form-label label-view">
                  E-Mail-Adresse
                </label>
                <input type="email" id="inputMail" class="form-control" v-model="email" placeholder="E-Mail-Adresse eingeben">
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                <label for="inputMessage" class="form-label">Nachricht</label>
                <textarea class="form-control" id="inputMessage" v-model="message" rows="7" placeholder="Nachricht eingeben"></textarea>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-12">
                Die Übermittlung der Daten erfolgt über eine verschlüsselte Verbindung. Wir verwenden Ihre Daten ausschließlich zur Bearbeitung Ihrer Anfrage. Weitere Informationen zur Verarbeitung Ihrer Daten und Widerrufshinweise finden Sie in der Datenschutzerklärung dieser Webseite. <router-link :to="{ name: 'DataProtection' }">Die Datenschutzerklärung finden Sie hier</router-link>.
              </div>
            </div>
          </div>
          <div class="text-end mt-4 mt-sm-2">
            <button type="submit" @click="sendContactForm" class="btn btn-primary">Formular versenden >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'ContactPage',
  components: {
    CardData
  },
  data () {
    return {
      salutation: this.$store.state.user.salutation,
      firstName: this.$store.state.user.firstName,
      lastName: this.$store.state.user.lastName,
      email: this.$store.state.user.email,
      emailRepeated: '',
      message: ''
    }
  },
  methods: {
    sendContactForm () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('contactForm', { salutation: this.salutation, firstName: this.firstName, lastName: this.lastName, contactEmail: this.email, message: this.message })
        .then(() => {
          // clear form
          this.salutation = ''
          this.firstName = ''
          this.lastName = ''
          this.email = ''
          this.message = ''
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  }
}
</script>
