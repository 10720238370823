<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center mb-5 mg-lg-0">
        <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <div class="col-12">
            <h1 v-html="maintenanceData.headline"></h1>
            <div v-html="maintenanceData.content"></div>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-12">
          <button class="btn btn-primary btn-large" @click="checkIfMaintenance()">Neu laden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
const slugify = require('slugify')

export default {
  name: 'MaintenanceBlockerPage',
  computed: {
    cardName () {
      return this.$store.state.region.cardName && typeof this.$store.state.region.cardName !== 'undefined' ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    }
  },
  data () {
    return {
      maintenanceData: {}
    }
  },
  methods: {
    async checkIfMaintenance () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('checkIfMaintenance')
        .then(response => {
          this.maintenanceData = response
          if (!Object.prototype.hasOwnProperty.call(this.maintenanceData, 'isMaintenanceActive') || this.maintenanceData.isMaintenanceActive === false || !Object.prototype.hasOwnProperty.call(this.maintenanceData, 'type') || !this.maintenanceData.type.toLowerCase().startsWith('block')) {
            this.$router.push('/')
          }
        })
        .catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.checkIfMaintenance()
  }
}
</script>

<style lang="scss" scoped>
  .vue_client_recaptcha {
    justify-content: flex-start;
  }

  .input-group-text {
    border-radius: 0;
  }

  #changePasswordType {
    cursor: pointer;
  }
</style>
