<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-2 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-10 ps-lg-3">
        <h1>Dokumenten<span class="fw-bold">archiv</span></h1>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link active" href="#" data-bs-toggle="tab" data-bs-target="#abrechnungen" type="button" role="tab" aria-controls="abrechnungen" aria-selected="false">Abrechnungen</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#rechnungen" type="button" role="tab" aria-controls="rechnungen" aria-selected="false">Rechnungen</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="abrechnungen" role="tabpanel" aria-labelledby="abrechnungen-tab">
            <p class="mt-2">Hier finden Sie Ihre Abrechnungen (Transaktionsabrechnungen)</p>
            <div v-for="(abrechnung, index) in abrechnungen" :key="index" class="d-inline">
              <span @click="this.currentAbrechnungenSelectedYear = Number(abrechnung.year)" class="me-2 fw-bold fs-5" :class="Number(this.currentAbrechnungenSelectedYear) !== Number(abrechnung.year) ? 'clickable card-primary-color' : ''">{{ abrechnung.year }}</span>
            </div>
            <div class="border table-responsive mt-3">
              <table id="abrechnungen-table" class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Typ</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(document, index) in this.filteredAbrechnungen.documents" :key="index">
                    <td>{{ document.name }}</td>
                    <td>{{ document.documentType }}</td>
                    <td>{{ document.date }}</td>
                    <td><span class="download-link" @click="downloadDocument(document.gguid, document.name)">Download</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="rechnungen" role="tabpanel" aria-labelledby="rechnungen-tab">
            <p class="mt-2">Hier finden Sie zukünftig Ihre Rechnungen. Wir bitten noch um etwas Geduld.</p>
            <div v-for="(rechnung, index) in rechnungen" :key="index" class="d-inline">
              <span @click="this.currentRechnungenSelectedYear = Number(rechnung.year)" class="me-2 fw-bold fs-5" :class="Number(this.currentRechnungenSelectedYear) !== Number(rechnung.year)? 'clickable card-primary-color' : ''">{{ rechnung.year }}</span>
            </div>
            <div class="border table-responsive mt-3">
              <table id="rechnungen-table" class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Typ</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(document, index) in filteredRechnungen.documents" :key="index">
                    <td>{{ document.name }}</td>
                    <td>{{ document.documentType }}</td>
                    <td>{{ document.date }}</td>
                    <td><span class="download-link" @click="downloadDocument(document.gguid, document.name)">Download</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'PartnerEmployerDocumentsPage',
  components: {
    CardData
  },
  data () {
    return {
      abrechnungen: [],
      rechnungen: [],
      currentAbrechnungenSelectedYear: 2023,
      currentRechnungenSelectedYear: 2023
    }
  },
  computed: {
    filteredAbrechnungen() {
      return this.abrechnungen.find(x => Number(x.year) === Number(this.currentAbrechnungenSelectedYear)) !== undefined ? this.abrechnungen.find(x => Number(x.year) === Number(this.currentAbrechnungenSelectedYear)) : []
    },
    filteredRechnungen() {
      return this.rechnungen.find(x => Number(x.year) === Number(this.currentRechnungenSelectedYear)) !== undefined ? this.rechnungen.find(x => Number(x.year) === Number(this.currentRechnungenSelectedYear)) : []
    }
  },
  methods: {
    async fetchDocumentsData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getPartnerEmployerDocuments').then(response => {
        if (response.length > 0) {
          for (const document of response) {
            const splittedDocDate = document.date.split('.')
            const documentYear = splittedDocDate[splittedDocDate.length - 1]

            if (document.documentType.toLowerCase() === 'rechnung') {
              let found = false
              this.rechnungen.forEach(element => {
                if (element.year === documentYear) {
                  element.documents.push(document)
                  found = true
                }
              })
              if (!found) {
                const tempArray = []
                tempArray.push(document)
                this.rechnungen.push({ year: documentYear, documents: tempArray })
              }
            } else if (document.documentType.toLowerCase() === 'abrechnung') {
              let found = false
              this.abrechnungen.forEach(element => {
                if (element.year === documentYear) {
                  element.documents.push(document)
                  found = true
                }
              })
              if (!found) {
                const tempArray = []
                tempArray.push(document)
                this.abrechnungen.push({ year: documentYear, documents: tempArray })
              }
            }
          }
        }
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async downloadDocument (documentGguid, name) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('downloadDocument', { document: documentGguid }).then(response => {
        const blob = new Blob([response], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = name + '.pdf'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  created () {
    this.fetchDocumentsData()
  }
}
</script>

<style scoped>

.clickable {
  cursor: pointer;
}

.download-link {
  cursor: pointer;
}

.download-link:hover {
  text-decoration: underline;
}

.nav-tabs .nav-link {
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

</style>
