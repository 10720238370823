<template>
  <div class="content">
    <div class="container py-1 py-md-5">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 text-center">
          <CardData />
        </div>
        <div class="col-12 col-lg-8 ps-lg-5">
          <h1>Die <span class="fw-bold">Mitarbeiter</span>CARD - Vorteile für alle</h1>
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>Sie wollen Ihren Beschäftigten eine Freude machen, sie motivieren oder Wertschätzung zeigen? Mit der MitarbeiterCARD haben Sie die Möglichkeit, Ihren Beschäftigten steuer- sowie auch abgabenfreie Sachzuwendungen auf die CARD zu laden. Dabei entscheiden Sie flexibel, ob Sie beispielsweise bis zu 50 Euro regelmäßig und/oder bis zu 60 Euro gelegentlich zu besonderen Anlässen wählen.</p>
              <p>Die MitarbeiterCARD ist wiederaufladbar ohne Gültigkeitsbeschränkungen. Einlösen können Ihre Beschäftigten das Guthaben dann bei allen Partnergeschäften und -unternehmen in {{ this.$store.state.user.region }}. So entsteht ein Geldkreislauf, der Ihre Region stärkt und die Nahversorgung erhält.</p>
            </div>
            <div class="col-12 col-lg-6">
              <h4>Sparen Sie Lohnnebenkosten</h4>
              <p>Mit der Einzahlung auf die MitarbeiterCARD sparen Sie die sonst für Barlohn fälligen Lohnnebenkosten*.</p>
              <h4>Stärken Sie die lokale Wirtschaft</h4>
              <p>Das Guthaben der MitarbeiterCARD kann ausschließlich bei den teilnehmenden Partnern der {{ this.$store.state.user.cardName }} ausgegeben werden.</p>
              <h4>Steigern Sie Ihre Attraktivität</h4>
              <p>Zusatzleistungen wie z.B. 50 € als mtl. Sachzuwendung werten das Image Ihres Unternehmens auf.</p>
            </div>
             <div class="col-12 col-lg-offset-6">
              <span class="fst-italic fs-6">* Für steuerliche, sozialversicherungsrechtliche und rechtliche Auswirkungen geben wir keine verbindliche Auskunft und übernehmen keine Haftung. Hierzu kontaktieren Sie bitte Ihren Steuerberater/Rechtsanwalt.</span>
             </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3 text-center" v-if="isInterested">
      <div class="col-12">
        <router-link v-if="isInterestedOnlyEmployer" :to="{ name: 'ArbeitgeberWerden' }" class="btn btn-primary btn-lg ms-lg-4 mt-4 mt-lg-0">Jetzt Partnervertrag abschließen &gt;&gt;</router-link>
        <router-link v-else :to="{ name: 'PartnerWerden' }" class="btn btn-primary btn-lg ms-lg-4 mt-4 mt-lg-0">Jetzt Partnervertrag abschließen &gt;&gt;</router-link>
      </div>
    </div>
    <div class="row-full-width bg-light-gray py-5 mt-5 card-primary-color" v-if="isInterested">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="fw-bold">Sie sind Arbeitgeber und benötigen weitere Infos zur MitarbeiterCARD dann buchen Sie einen unverbindlichen Beratungstermin oder nehmen Sie Kontakt mit uns auf.</p>
            <router-link :to="{ name: 'Terminbuchung' }" class="btn btn-primary me-4">Beratungstermin vereinbaren &gt;&gt;</router-link>
            <router-link :to="{ name: 'PartnerContact' }" class="btn btn-primary">Kontakt aufnehmen &gt;&gt;</router-link>
            <p class="fw-bold mt-5">Sie haben bereits alle Informationen und möchten Partner der MitarbeiterCARD werden, dann können Sie hier Ihren Partnervertrag online abschließen.</p>
            <router-link :to="{ name: 'ArbeitgeberWerden' }" class="btn btn-primary btn-lg">Partner werden &gt;&gt;</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-1 py-md-5">
      <div class="row mt-5 g-0">
        <div class="col-12 col-lg-4 card-primary-background-color text-white rounded-start p-4">
          <h2 class="text-white">FAQ</h2>
          <p>Sie haben Fragen rund um die MitarbeiterCARD? In unseren FAQ finden Sie die Antwort auf die meisten Fragen.</p>
          <p>Wurde ihre Frage nicht beantwortet? Dann können Sie sich über das Kontaktformular gerne an uns wenden.</p>
        </div>
        <div class="col-12 col-lg-8">
          <img class="img-fluid" :src="require('../assets/img/employer-info/personen-daumen-nach-oben.jpg')">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="accordion" id="accordionFAQ">
            <div v-for="(faq, index) in employerFAQs" :key="index">
              <div class="accordion-item">
                <h2 class="accordion-header" :id="`heading${index}`">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" :aria-expanded="index === 0? 'true' : 'false'" :aria-controls="`collapse${index}`">
                    {{ faq.question }}
                  </button>
                </h2>
                <div :id="`collapse${index}`" class="accordion-collapse collapse" :class="index === 0? 'show' : ''" :aria-labelledby="`heading${index}`" data-bs-parent="#accordionFAQ">
                  <div class="accordion-body">
                    <div v-html="faq.answer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import UserRoles from './../enums/UserRoles'

export default {
  name: 'EmployerInfoPage',
  components: {
    CardData
  },
  data () {
    return {
      employerFAQs: [],
      contactPerson: '',
      email: '',
      phone: ''
    }
  },
  methods: {
    async fetchEmployerFAQs () {
      this.$store.commit('SET_LOADING_STATUS', true)
      let requestToDispatch = 'getEmployerFAQs'
      if (this.isInterested) {
        requestToDispatch = 'getInterestEmployerFAQs'
      }
      await this.$store.dispatch(requestToDispatch, { regionID: this.$store.state.region.id }).then(response => {
        if (this.isInterested) {
          this.employerFAQs = response.acf.interest_employer_faqs
        } else {
          this.employerFAQs = response.acf.employer_faqs
        }
        this.contactPerson = response.acf.contact_person
        this.email = response.acf.email
        this.phone = response.acf.phone
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  computed: {
    isInterested () {
      return this.$store.state.user.role === UserRoles.INTERESTED
    },
    isInterestedOnlyEmployer () {
      return this.$store.state.user.role === UserRoles.INTERESTED && this.$store.state.user.partner_interest_in === 'Arbeitgeber'
    }
  },
  created () {
    this.fetchEmployerFAQs()
  }
}
</script>
