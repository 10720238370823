<template>
  <div class="container py-1 py-md-5">
    <div class="content">
      <div id="demo-watermark">
        Demoversion
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-2 text-center">
          <CardData />
        </div>
        <div class="col-12 col-lg-10 ps-lg-3">
          <h1>Transaktions<span class="fw-bold">übersicht</span></h1>
          <p>Eine Auflistung aller Transaktionen</p>
          <div class="border border-radius rounded table-responsive p-3">
            <h3>Filter
              <span v-if="activeFiltersCount > 0"> ({{ activeFiltersCount }})</span>
              <img width="28" v-if="!showFilters" @click="showOrHideFilter()" :src="require(`../assets/img/chevron-down.svg`)">
              <img width="28" v-if="showFilters" @click="showOrHideFilter()" :src="require(`../assets/img/chevron-up.svg`)"></h3>
              <div id="filter-row" class="row" :class="showFilters ? 'expanded': 'collapsed'">
                <div class="col-12 mb-3">
                  <h4 class="h6">Schnellfilter</h4>
                  <div class="row">
                    <div class="col-6 col-md-3">
                      <span class="quick-filter-button" :class="currentDayFilterActive ? 'badge bg-primary' : ''" @click.stop="activateCurrentDayFilter">aktueller Tag <span v-if="!currentDayFilterActive">&gt;&gt;</span><span v-if="currentDayFilterActive" @click.stop="clearAllFilter()" class="delete-quicklfilter-btn border-start ms-1 ps-1" role="button">x</span></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <span class="quick-filter-button" :class="yesterdayFilterActive ? 'badge bg-primary' : ''" @click.stop="activateYesterdayFilter">gestriger Tag <span v-if="!yesterdayFilterActive">&gt;&gt;</span><span v-if="yesterdayFilterActive" @click.stop="clearAllFilter()" class="delete-quicklfilter-btn border-start ms-1 ps-1" role="button">x</span></span>
                    </div>
                    <div class="col-6 col-md-3 pt-2 pt-md-1">
                      <span class="quick-filter-button" :class="currentMonthFilterActive ? 'badge bg-primary' : ''" @click.stop="activateCurrentMonthFilter">aktueller Monat <span v-if="!currentMonthFilterActive">&gt;&gt;</span><span v-if="currentMonthFilterActive" @click.stop="clearAllFilter()" class="delete-quicklfilter-btn border-start ms-1 ps-1" role="button">x</span></span>
                    </div>
                    <div class="col-6 col-md-3 pt-2 pt-md-1">
                      <span class="quick-filter-button" :class="lastMonthFilterActive ? 'badge bg-primary' : ''" @click.stop="activateLastMonthFilter">letzter Monat <span v-if="!lastMonthFilterActive">&gt;&gt;</span><span v-if="lastMonthFilterActive" @click.stop="clearAllFilter()" class="delete-quicklfilter-btn border-start ms-1 ps-1" role="button">x</span></span>
                    </div>
                  </div>
                </div>
                <div class="col-7 col-md-3">
                  <h4 class="h6">Datum</h4>
                  <label for="minDateInput" class="filter-from-label">Von</label>
                  <input type="date" class="mb-2" id="minDateInput" size="10" /><br />
                  <label for="maxDateInput" class="filter-to-label">Bis</label>
                  <input type="date" id="maxDateInput" size="10" />
                </div>
                <div class="col-5 col-md-3">
                  <h4 class="h6">Uhrzeit</h4>
                  <label for="minTimeInput" class="filter-from-label">Von</label>
                  <input type="time" class="mb-2" id="minTimeInput" size="10" /><br />
                  <label for="maxTimeInput" class="filter-to-label">Bis</label>
                  <input type="time" id="maxTimeInput" size="10" />
                </div>
                <div class="col-7 col-md-3 pt-3 pt-md-1">
                  <h4 class="h6">Buchungsart</h4>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="Aufladung" id="filterBuchungsartGuthabenAufgeladen">
                    <label class="form-check-label" for="filterBuchungsartGuthabenAufgeladen">
                      Guthaben aufgeladen
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="Einlösung" id="filterBuchungsartGuthabenEingeloest">
                    <label class="form-check-label" for="filterBuchungsartGuthabenEingeloest">
                      Guthaben eingelöst
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="Kundenbonus" id="filterBuchungsartKundenbonus">
                    <label class="form-check-label" for="filterBuchungsartKundenbonus">
                      Kundenbonus
                    </label>
                  </div>
                </div>
                <div class="col-5 col-md-3 pt-3 pt-md-1">
                  <h4 class="h6">Betrag in Euro</h4>
                  <label for="minAmountInput" class="filter-from-label">Von</label>
                  <input type="number" class="mb-2" id="minAmountInput" size="50" /><br />
                  <label for="maxAmountInput" class="filter-to-label">Bis</label>
                  <input type="number" id="maxAmountInput" size="10" />
                </div>
                <div class="col-12">
                  <button class="btn btn-primary mt-2" @click="onApplyFilters()">Filter <span v-if="activeFiltersCount > 0"> ({{ activeFiltersCount }})</span> anwenden</button>
                  <button v-if="activeFiltersCount > 0" class="btn btn-secondary mt-2 ms-3" @click="clearAllFilter()">Alle Filter <span v-if="activeFiltersCount > 0"> ({{ activeFiltersCount }})</span> löschen</button>
                </div>
              </div>
            <h3 class="mt-4">Transaktionen</h3>
            <DataTable ref="transactionstable" id="partner-transactions-table" class="table table-hover mb-0" :data="transactions" :columns="columns" :options="datatableOptions" width="100%">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Uhrzeit</th>
                  <th>Kartennummer</th>
                  <th>Buchungsart</th>
                  <th>Betrag in Euro</th>
                  <th>Korrektur</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Datum</th>
                  <th>Uhrzeit</th>
                  <th>Kartennummer</th>
                  <th>Buchungsart</th>
                  <th>Betrag in Euro</th>
                  <th>Korrektur</th>
                </tr>
              </tfoot>
            </DataTable>
            <h5 class="mt-2">Aktuelle Auswahl exportieren</h5>
            <div id="export-buttons-container"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import CardData from '@/components/CardData.vue'
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import jsZip from 'jszip'
import pdfMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
// import Chart from 'chart.js/auto'
import demoTransactionsJson from '../assets/demo-transactions.json'
pdfMake.vfs = pdfFonts.pdfMake.vfs
window.JSZip = jsZip
DataTable.use(DataTablesLib)

export default {
  name: 'InterestDemoTransactionsPage',
  components: {
    CardData,
    DataTable
  },
  data () {
    return {
      showFilters: false,
      activeFiltersCount: 0,
      dataTable: undefined,
      transactions: typeof demoTransactionsJson !== 'undefined' && demoTransactionsJson !== '' ? demoTransactionsJson : [],
      buttonCommon: {
        exportOptions: {
          format: {
            body: function(data, row, column, node) {
              if (typeof data !== 'undefined') {
                if (data !== null) {
                  if (column === 4) {
                    var arr = data.split(',')
                    // subsequently replace all the periods with spaces
                    arr[0] = arr[0].toString().replace(/[.]/g, '')
                    // join the pieces together with a period if not empty
                    if (arr[0] > '' || arr[1] > '') {
                      data = arr[0] + '.' + arr[1]
                    } else {
                      return ''
                    }
                  }
                }
              }
              return data
            }
          }
        }
      },
      columns: [
        { data: null, render: { _: 'date', filter: 'date', display: 'dateFormatted' } },
        { data: 'time' },
        { data: 'cardID' },
        { data: 'text' },
        { data: 'amount', render: $.fn.dataTable.render.number('.', ',', 2) },
        {
          data: null,
          render: function(data, type, row, meta) {
            return '<a style="text-decoration: underline;" href="javascript:void(0)" class="correction-link">Korrektur</a'
          }
        }
      ],
      datatableOptions: {
        autoWidth: true,
        order: [[0, 'desc']],
        buttons: [
          {
            extend: 'csvHtml5',
            className: 'btn-primary',
            charset: 'utf-8',
            extension: '.csv',
            fieldSeparator: ';',
            fieldBoundary: '',
            bom: true,
            exportOptions: {
              columns: [0, 1, 2, 3, 4]
            }
          },
          {
            extend: 'excelHtml5',
            className: 'btn-primary ms-2',
            exportOptions: {
              format: {
                body: function(data, row, column, node) {
                  if (typeof data !== 'undefined') {
                    if (data !== null) {
                      if (column === 4) {
                        var arr = data.split(',')
                        arr[0] = arr[0].toString().replace(/[.]/g, '')
                        if (arr[0] > '' || arr[1] > '') {
                          data = arr[0] + '.' + arr[1]
                        } else {
                          return ''
                        }
                      }
                    }
                  }
                  return data
                }
              },
              columns: [0, 1, 2, 3, 4]
            },
            customize: function(xlsx, row) {
              var sheet = xlsx.xl.worksheets['Tabelle1.xml']
              $('row c[r^="E"]', sheet).attr('s', 59)
            }
          },
          {
            extend: 'pdfHtml5',
            className: 'btn-primary ms-2',
            exportOptions: {
              columns: [0, 1, 2, 3, 4]
            }
          },
          {
            extend: 'print',
            className: 'btn-primary ms-2',
            text: 'Drucken',
            exportOptions: {
              columns: [0, 1, 2, 3, 4]
            }
          }
        ],
        language: {
          search: 'Suchen',
          lengthMenu: 'Zeige _MENU_ Transaktionen pro Seite',
          zeroRecords: 'Keine Transaktionen gefunden',
          info: 'Zeige Seite _PAGE_ von _PAGES_',
          infoEmpty: 'Keine Transaktionen gefunden',
          infoFiltered: '(gefiltert von _MAX_ Transaktionen)',
          paginate: {
            first: 'Erste',
            last: 'Letzte',
            next: 'Nächste',
            previous: 'Vorherige'
          }
        }
      },
      currentDayFilterActive: false,
      yesterdayFilterActive: false,
      currentMonthFilterActive: false,
      lastMonthFilterActive: false
    }
  },
  methods: {
    showCorrectionLinkDemoAlert () {
      this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Dies ist in der Demo nicht verfügbar.\nDieser Link würde zum Korrekturformular leiten und die Daten der jeweiligen Transaktion im Formular vorausfüllen.' })
    },
    activateCurrentDayFilter () {
      if (!this.currentDayFilterActive) {
        this.clearAllFilter()
        document.getElementById('minDateInput').value = moment().startOf('day').format('YYYY-MM-DD')
        document.getElementById('minTimeInput').value = '00:00'
        this.currentDayFilterActive = true
      }
    },
    activateYesterdayFilter () {
      if (!this.yesterdayFilterActive) {
        this.clearAllFilter()
        document.getElementById('minDateInput').value = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD')
        document.getElementById('minTimeInput').value = '00:00'
        document.getElementById('maxDateInput').value = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')
        document.getElementById('maxTimeInput').value = '23:59'
        this.yesterdayFilterActive = true
      }
    },
    activateCurrentMonthFilter () {
      if (!this.currentMonthFilterActive) {
        this.clearAllFilter()
        document.getElementById('minDateInput').value = moment().startOf('month').format('YYYY-MM-DD')
        document.getElementById('minTimeInput').value = '00:00'
        this.currentMonthFilterActive = true
      }
    },
    activateLastMonthFilter () {
      if (!this.lastMonthFilterActive) {
        this.clearAllFilter()
        document.getElementById('minDateInput').value = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
        document.getElementById('minTimeInput').value = '00:00'
        document.getElementById('maxDateInput').value = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
        document.getElementById('maxTimeInput').value = '23:59'
        this.lastMonthFilterActive = true
      }
    },
    onApplyFilters () {
      this.dataTable.draw()
    },
    showOrHideFilter () {
      this.showFilters = !this.showFilters
    },
    clearAllFilter () {
      this.activeFiltersCount = 0
      this.activeFiltersCount += document.getElementById('minAmountInput').value = undefined
      this.activeFiltersCount += document.getElementById('maxAmountInput').value = undefined
      this.activeFiltersCount += document.getElementById('minDateInput').value = ''
      this.activeFiltersCount += document.getElementById('maxDateInput').value = ''
      this.activeFiltersCount += document.getElementById('minTimeInput').value = ''
      this.activeFiltersCount += document.getElementById('maxTimeInput').value = ''
      this.activeFiltersCount += document.getElementById('filterBuchungsartGuthabenAufgeladen').checked = false
      this.activeFiltersCount += document.getElementById('filterBuchungsartGuthabenEingeloest').checked = false
      this.activeFiltersCount += document.getElementById('filterBuchungsartKundenbonus').checked = false
      this.currentDayFilterActive = false
      this.yesterdayFilterActive = false
      this.currentMonthFilterActive = false
      this.lastMonthFilterActive = false
      this.dataTable.draw()
    }
  },
  created () {
    $.fn.dataTableExt.afnFiltering.push(
      function(oSettings, aData, iDataIndex) {
        if (document.getElementById('minAmountInput').value.length > 0 || document.getElementById('maxAmountInput').value.length > 0) {
          var iColumn = 4
          var iMin = ''
          var iMax = ''
          if (document.getElementById('minAmountInput').value.length > 0) {
            iMin = document.getElementById('minAmountInput').value * 1
          }
          if (document.getElementById('maxAmountInput').value.length > 0) {
            iMax = document.getElementById('maxAmountInput').value * 1
          }

          var iVersion = aData[iColumn] === '-' ? 0 : aData[iColumn] * 1
          if (iMin === '' && iMax === '') {
            return true
          } else if (iMin === '' && iVersion <= iMax) {
            return true
          } else if (iMax === '' && iMin <= iVersion) {
            return true
          } else if (iMin <= iVersion && iVersion <= iMax) {
            return true
          }
          return false
        } else {
          return true
        }
      }
    )
    $.fn.dataTableExt.afnFiltering.push(
      function(oSettings, aData, iDataIndex) {
        var iColumn = 3
        const guthabenAufgeladenFilterInput = document.getElementById('filterBuchungsartGuthabenAufgeladen')
        const guthabenEingeloestFilterInput = document.getElementById('filterBuchungsartGuthabenEingeloest')
        const kundenbonusFilterInput = document.getElementById('filterBuchungsartKundenbonus')
        let amountOfChecked = 0
        let shouldReturnTrue = false
        if (guthabenAufgeladenFilterInput.checked) {
          amountOfChecked = amountOfChecked + 1
          if (aData[iColumn] === guthabenAufgeladenFilterInput.value) {
            shouldReturnTrue = true
          }
        }
        if (guthabenEingeloestFilterInput.checked) {
          amountOfChecked = amountOfChecked + 1
          if (aData[iColumn] === guthabenEingeloestFilterInput.value) {
            shouldReturnTrue = true
          }
        }
        if (kundenbonusFilterInput.checked) {
          amountOfChecked = amountOfChecked + 1
          if (aData[iColumn] === kundenbonusFilterInput.value) {
            shouldReturnTrue = true
          }
        }
        if (amountOfChecked > 0) {
          return shouldReturnTrue
        } else {
          return true
        }
      }
    )
    $.fn.dataTableExt.afnFiltering.push(
      function(oSettings, aData, iDataIndex) {
        const minDateInput = document.getElementById('minDateInput')
        const maxDateInput = document.getElementById('maxDateInput')

        if (minDateInput.value.length > 0 || maxDateInput.value.length > 0) {
          var iColumn = 0
          var iMinDate = ''
          var iMaxDate = ''
          if (minDateInput.value.length > 0) {
            iMinDate = new Date(minDateInput.value).getTime() / 1000
          }
          if (maxDateInput.value.length > 0) {
            iMaxDate = new Date(maxDateInput.value).getTime() / 1000
          }

          if (iMinDate === iMaxDate) {
            iMaxDate = iMaxDate + (86400000 / 1000)
          }

          var iVersion = aData[iColumn] === '-' ? 0 : aData[iColumn]
          if (iMinDate === '' && iMaxDate === '') {
            return true
          } else if (iMinDate === '' && iVersion <= iMaxDate) {
            return true
          } else if (iMaxDate === '' && iMinDate <= iVersion) {
            return true
          } else if (iMinDate <= iVersion && iVersion <= iMaxDate) {
            return true
          }
          return false
        } else {
          return true
        }
      }
    )
    $.fn.dataTableExt.afnFiltering.push(
      function(oSettings, aData, iDataIndex) {
        const minTimeInput = document.getElementById('minTimeInput')
        const maxTimeInput = document.getElementById('maxTimeInput')

        if (minTimeInput.value.length > 0 || maxTimeInput.value.length > 0) {
          var iColumn = 1
          var iMin = ''
          var iMax = ''
          var currentMinDate = new Date()
          var currentMaxDate = new Date()

          if (minTimeInput.value.length > 0) {
            iMin = minTimeInput.value
            currentMinDate.setHours(minTimeInput.value.split(':')[0], minTimeInput.value.split(':')[1], 0)
          }
          if (maxTimeInput.value.length > 0) {
            iMax = maxTimeInput.value
            currentMaxDate.setHours(maxTimeInput.value.split(':')[0], maxTimeInput.value.split(':')[1], 59)
          }
          var currentTableDate = new Date()
          currentTableDate.setHours(aData[iColumn].split(':')[0], aData[iColumn].split(':')[1], aData[iColumn].split(':')[2])

          if (iMin === '' && iMax === '') {
            return true
          } else if (iMin === '' && currentTableDate <= currentMaxDate) {
            return true
          } else if (iMax === '' && currentMinDate <= currentTableDate) {
            return true
          } else if (currentMinDate <= currentTableDate && currentTableDate <= currentMaxDate) {
            return true
          }
          return false
        } else {
          return true
        }
      }
    )

    const superThis = this
    $(document).on('click', '.correction-link', function() {
      superThis.showCorrectionLinkDemoAlert()
    })
  },
  mounted () {
    this.dataTable = this.$refs.transactionstable.dt
    const superThis = this
    this.dataTable.on('search.dt', function () {
      superThis.activeFiltersCount = 0
      superThis.activeFiltersCount += document.getElementById('minAmountInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('maxAmountInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('minDateInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('maxDateInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('minTimeInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('maxTimeInput').value.length > 0 ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('filterBuchungsartGuthabenAufgeladen').checked ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('filterBuchungsartGuthabenEingeloest').checked ? 1 : 0
      superThis.activeFiltersCount += document.getElementById('filterBuchungsartKundenbonus').checked ? 1 : 0
    })
    this.dataTable.buttons().container().appendTo($('#export-buttons-container'))
  }
}
</script>

<style scoped>
@import 'datatables.net-bs5';
@media (max-width: 500px) {
  #partner-transactions-table td,
  #partner-transactions-table th {
    font-size: 14px;
  }
}

#filter-row input[type="number"] {
  width: 100px;
}

@media (max-width: 992px) {
  #filter-row input[type="number"] {
    width: 70px;
  }
}

#filter-row input[type="date"] {
  width: 120px;
  font-size: 15px;
}

.filter-from-label,
.filter-to-label {
  width: 40px;
}

#filter-row label {
  font-size: 15px;
}

#filter-row {
  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  -moz-transition: height 0.5s ease-in-out;
  -ms-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  overflow: hidden;
}
#filter-row.collapsed {
  height: 0;
}

#filter-row.expanded {
  height: 260px;
}

@media (max-width: 992px) {
  #filter-row.expanded {
    height: 280px;
  }
}

@media (max-width: 768px) {
  #filter-row.expanded {
    height: 400px;
  }
}

.delete-quicklfilter-btn,
.quick-filter-button {
  cursor: pointer;
}

#filter-row .badge {
  font-size: 0.9em;
}

#demo-watermark {
  top: 380px;
  font-size: 11rem;
  font-weight: 700;
  opacity: 0.07;
  position: absolute;
  transform: rotate(45deg);
  z-index: 0;
  left: 10%;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

</style>
