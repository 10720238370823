<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-12 ps-lg-5">
        <h1>Passwort<span class="fw-bold"> Resettokens</span></h1>
        <div class="alert alert-warning" role="alert">
            Hier erscheinen nur die Benutzer, bei denen die Passwort zurücksetzen Funktion auch wirklich ausgelöst wurde.<br />Nachdem das neue Passwort erfolgreich vergeben wurde, verschwindet der Eintrag hier.
        </div>
        <div class="border border-radius rounded table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th scope="col" class="py-2">E-Mail</th>
                <th scope="col" class="py-2">Passwort zurücksetzen Token</th>
                <th scope="col" class="py-2">Passwort zurücksetzen Link</th>
                <th scope="col" class="py-2">Zeitstempel</th>
                <th scope="col" class="py-2">Name der Karte</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="index">
                <td>{{ user.email }}</td>
                <td>{{ user.password_reset_token }}</td>
                <td><a :href="user.reset_password_link">{{ user.reset_password_link }}</a></td>
                <td>{{ user.password_reset_timestamp }}</td>
                <td>{{ user.card_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
  name: 'PasswordResetListPage',
  data () {
    return {
      users: []
    }
  },
  methods: {
    async fetchPasswordResetTokenListen () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getPasswordResetList').then(response => {
        this.users = response
      }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.fetchPasswordResetTokenListen()
  }
}
</script>
