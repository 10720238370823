<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0 pt-sm-5">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <div v-html="this.imprintText">
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>

export default {
  name: 'ImprintPage',
  data () {
    return {
      imprintText: ''
    }
  },
  methods: {
    loadImprintText () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('loadImprintText')
        .then((imprint) => {
          this.imprintText = imprint.replace('[region_quellennachweise]<br />', '')
        })
        .catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  created () {
    this.loadImprintText()
  }
}
</script>
