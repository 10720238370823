<template>
  <div class="container py-1 py-md-5">
    <div class="content pt-0 pt-sm-5">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6 text-center mb-5 mg-lg-0">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50" />
        </div>
        <div class="col-12 col-lg-6">
          <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
            <h1><span class="fw-bold">Auftraggeber</span>login</h1>
            <p>Herzlich Willkommen. Bitte melden Sie sich an.</p>
            <div class="col-11 col-md-6 col-lg-8">
              <div class="form my-3 fs-3">
                <label for="inputCardId">E-Mail-Adresse</label>
                <input v-model="email" type="text" class="form-control" id="inputCardId"
                  placeholder="E-Mail Adresse oder Kartennummer eingeben..." autocomplete="username" />
              </div>
              <div class="form my-3 fs-3">
                <label for="inputPassword">Passwort</label>
                <div class="input-group flex-nowrap">
                  <input v-model="password" :type="passwordInputType" class="form-control" id="inputPassword"
                    placeholder="Passwort eingeben..." autocomplete="current-password" />
                  <span class="input-group-text" id="changePasswordType" @click="togglePasswordVisibility()"><img
                      :src="require(`../assets/img/${showPasswordIcon}.svg`)" width="20" height="20" /></span>
                </div>
              </div>
              <label for="inputCaptcha">Captcha-Code</label>
              <VueClientRecaptcha :value="captchaInputValue" :count="3"
                chars="abcdefghkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ0123456789" @isValid="checkValidCaptcha">
                <template #icon>
                  <img width="60" style="width: 60px;" :src="require(`../assets/img/black-refresh-icon.png`)">
                </template>
              </VueClientRecaptcha>
              <div class="form my-3 fs-3">
                <input v-model="captchaInputValue" type="text" class="form-control" id="inputCaptcha"
                  placeholder="Captcha-Code eingeben..." autocomplete="off" />
              </div>
              <div class="row justify-content-end mt-4 mt-sm-2">
                <div class="col-12 col-sm-6">
                  <button @click="login()" class="w-100 btn btn-lg btn-primary">Login >></button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>Sie möchten sich als Interessent / Akzeptanzpartner / Arbeitgeber registrieren? <a
                      href="/interessent-registrierung">Jetzt hier registrieren</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueClientRecaptcha from 'vue-client-recaptcha'
const slugify = require('slugify')
export default {
  name: 'ContractorLoginPage',
  data() {
    return {
      email: '',
      password: '',
      captchaInputValue: '',
      isValidCaptcha: false,
      passwordInputType: 'password'
    }
  },
  computed: {
    cardName() {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    showPasswordIcon() {
      return this.passwordInputType === 'password' ? 'eye-icon' : 'eye-hide-icon'
    }
  },
  methods: {
    login() {
      if (this.isValidCaptcha) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.$store.dispatch('contractorLogin', { email: this.email, password: this.password })
          .catch(() => { })
          .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Der eingegebene Captcha ist falsch.' })
      }
    },
    checkValidCaptcha(value) {
      this.isValidCaptcha = value
    },
    togglePasswordVisibility() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  },
  components: {
    VueClientRecaptcha
  }
}
</script>
<style lang="scss" scoped>
.vue_client_recaptcha {
  justify-content: flex-start;
}

.input-group-text {
  border-radius: 0;
}

#changePasswordType {
  cursor: pointer;
}
</style>
