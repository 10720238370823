<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center mb-5">
        <a href="/login">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid"/>
        </a>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1><span class="fw-bold">Partnerdaten</span> bearbeiten</h1>
          <p>Bitte ergänzen Sie die fehlenden Informationen.</p>
          <div v-if="this.registrationData.partnerDataComplete == false" class="alert alert-danger" role="alert">
            <u>Wichtiger Hinweis:</u> Die Veröffentlichung des Geschäfts sowie die Freischaltung zur Buchungsplattform kann nur bei vollständigen Partnerinformationen erfolgen!
          </div>
          <div class="btn-group mb-3" role="group" aria-label="Schritte">
            <button type="button" class="btn" :class="currentStep === 1 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(1)">1. Vertrags- &amp; Rechnungsdaten</button>
            <button type="button" class="btn" :class="currentStep === 2 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(2)">2. Daten zur Veröffentlichung</button>
            <button type="button" class="btn" :class="currentStep === 3 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(3)">3. Zahlungsart</button>
            <button type="button" class="btn" :class="currentStep === 4 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(4)">4. Vertragsdetails</button>
            <button type="button" class="btn" v-if="this.priceToPay > 0 && !(this.registrationData.tmspartner && this.registrationData.tmspartner === true)" :class="currentStep === 5 ? 'btn-primary' : 'btn-outline-secondary'" @click="toStep(5)">5. Checkout</button>
          </div>
          <div v-if="currentStep === 1">
            <h3>Vertragsdaten</h3>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                  <input v-model="registrationData.companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                  <div id="companyNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyAddressAdditional" class="form-label label-view">Adresse-Zusatz</label>
                  <input v-model="registrationData.companyAddressAdditional" type="text" id="companyAddressAdditional" class="form-control input-view" placeholder="Adress-Zusatz eingeben (optional)">
                  <div id="companyAddressAdditionalNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                  <input v-model="registrationData.companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                  <div id="companyStreetNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyZip" class="form-label label-view">Postleitzahl</label>
                  <input v-model="registrationData.companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                  <div id="companyZipNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyCity" class="form-label label-view">Ort</label>
                  <input v-model="registrationData.companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                  <div id="companyCityNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyCountry" class="form-label label-view">Land</label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="registrationData.companyCountry" class="form-select input-view" id="companyCountry">
                        <option value="DE">Deutschland</option>
                        <option value="CH">Schweiz</option>
                        <option value="AT">Österreich</option>
                        <option value="FR">Frankreich</option>
                        <option value="BE">Belgien</option>
                        <option value="LU">Luxemburg</option>
                        <option value="DK">Dänemark</option>
                        <option value="NO">Norwegen</option>
                        <option value="SE">Schweden</option>
                        <option value="FI">Finnland</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="NL">Niederlande</option>
                        <option value="PL">Polen</option>
                        <option value="PT">Portugal</option>
                        <option value="RO">Rumänien</option>
                        <option value="RU">Russland</option>
                        <option value="GR">Griechenland</option>
                        <option value="AL">Albanien</option>
                        <option value="AD">Andorra</option>
                        <option value="BG">Bulgarien</option>
                        <option value="IT">Italien</option>
                        <option value="IE">Irland</option>
                        <option value="EE">Estland</option>
                        <option value="LV">Lettland</option>
                        <option value="LT">Litauen</option>
                        <option value="KZ">Kasachstan</option>
                        <option value="SM">San Marino</option>
                        <option value="RS">Serbien</option>
                        <option value="SK">Slowakei</option>
                        <option value="SI">Slowenien</option>
                        <option value="ES">Spanien</option>
                        <option value="CZ">Tschechien</option>
                        <option value="TR">Türkei</option>
                        <option value="UA">Ukraine</option>
                        <option value="HU">Ungarn</option>
                        <option value="GB">Vereinigtes Königreich</option>
                        <option value="MT">Malta</option>
                        <option value="MC">Monaco</option>
                        <option value="MK">Nordmazedonien</option>
                      </select>
                    </div>
                  </div>
                  <div id="companyCountryNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top" v-if="registrationData.community !== 'Leinfelden-Echterdingen'">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyGewerbeverein" class="form-label label-view">Mitglied Gewerbeverein (Info)</label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="registrationData.companyGewerbeverein" class="form-select input-view" id="companyGewerbeverein" readonly="readonly" disabled="disabled">
                        <option v-for="vereinsname in regionCheckoutData.requirement_to_join_club_names" :key="vereinsname.requirement_to_join_club_name" :value="vereinsname.requirement_to_join_club_name">{{vereinsname.requirement_to_join_club_name}}</option>
                        <option value="Kein Mitglied, möchte aber Infos">Kein Mitglied, möchte aber Infos</option>
                        <option value="Kein Mitglied">Kein Mitglied</option>
                      </select>
                    </div>
                  </div>
                  <div id="companyGewerbevereinNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyEmail" class="form-label label-view">E-Mail-Adresse (Vertrag)</label>
                  <input v-model="registrationData.companyEmail" type="email" id="companyEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                  <div id="companyEmailNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyEmailRepeated" class="form-label label-view">E-Mail-Adresse (Vertrag) wiederholen</label>
                  <input v-model="registrationData.companyEmailRepeated" type="email" id="companyEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                  <div id="companyEmailRepeatedNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyEmailHeadquarter" class="form-label label-view">E-Mail-Adresse (Zentrale)</label>
                  <input v-model="registrationData.companyEmailHeadquarter" type="email" id="companyEmailHeadquarter" class="form-control input-view" placeholder="E-Mail-Adresse (Zentrale) eingeben" maxlength="70">
                  <div id="companyEmailHeadquarterNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyEmailHeadquarterRepeated" class="form-label label-view">E-Mail-Adresse (Zentrale) wiederholen</label>
                  <input v-model="registrationData.companyEmailHeadquarterRepeated" type="email" id="companyEmailHeadquarterRepeated" class="form-control input-view" placeholder="E-Mail-Adresse (Zentrale) wiederholen" maxlength="100">
                  <div id="companyEmailHeadquarterRepeatedNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <br />
            <h3>Rechnungsdaten</h3>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyREName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                  <input v-model="registrationData.companyREName" type="text" id="companyREName" class="form-control input-view" placeholder="Firmennamen eingeben">
                  <div id="companyRENameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyREStreet" class="form-label label-view">Straße und Hausnummer</label>
                  <input v-model="registrationData.companyREStreet" type="text" id="companyREStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                  <div id="companyREStreetNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyREZIP" class="form-label label-view">Postleitzahl</label>
                  <input v-model="registrationData.companyREZip" type="text" id="companyREZip" class="form-control input-view" placeholder="PLZ eingeben">
                  <div id="companyREZipNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyRECity" class="form-label label-view">Ort</label>
                  <input v-model="registrationData.companyRECity" type="text" id="companyRECity" class="form-control input-view" placeholder="Ort eingeben">
                  <div id="companyRECityNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyRECountry" class="form-label label-view">Land</label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="registrationData.companyRECountry" class="form-select input-view" id="companyRECountry">
                        <option value="Deutschland">Deutschland</option>
                        <option value="Schweiz">Schweiz</option>
                        <option value="Österreich">Österreich</option>
                        <option value="Frankreich">Frankreich</option>
                        <option value="Belgien">Belgien</option>
                        <option value="Luxemburg">Luxemburg</option>
                        <option value="Dänemark">Dänemark</option>
                        <option value="Norwegen">Norwegen</option>
                        <option value="Schweden">Schweden</option>
                        <option value="Finnland">Finnland</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Niederlande">Niederlande</option>
                        <option value="Polen">Polen</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Rumänien">Rumänien</option>
                        <option value="Russland">Russland</option>
                        <option value="Griechenland">Griechenland</option>
                        <option value="Albanien">Albanien</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Bulgarien">Bulgarien</option>
                        <option value="Italien">Italien</option>
                        <option value="Irland">Irland</option>
                        <option value="Estland">Estland</option>
                        <option value="Lettland">Lettland</option>
                        <option value="Litauen">Litauen</option>
                        <option value="Kasachstan">Kasachstan</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Serbien">Serbien</option>
                        <option value="Slowakei">Slowakei</option>
                        <option value="Slowenien">Slowenien</option>
                        <option value="Spanien">Spanien</option>
                        <option value="Tschechien">Tschechien</option>
                        <option value="Türkei">Türkei</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="Ungarn">Ungarn</option>
                        <option value="Vereinigtes Königreich">Vereinigtes Königreich</option>
                        <option value="Malta">Malta</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Nordmazedonien">Nordmazedonien</option>
                      </select>
                    </div>
                  </div>
                  <div id="companyRECountryNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyREEmail" class="form-label label-view">E-Mail-Adresse (Rechnung)</label>
                  <input v-model="registrationData.companyREEmail" type="email" id="companyREEmail" class="form-control input-view" placeholder="Rechnungs-E-Mail-Adresse eingeben" maxlength="70">
                  <div id="companyREEmailNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCompanyREEmailRepeated" class="form-label label-view">E-Mail-Adresse (Rechnung) wiederholen</label>
                  <input v-model="registrationData.companyREEmailRepeated" type="email" id="companyREEmailRepeated" class="form-control input-view" placeholder="Rechnungs-E-Mail-Adresse wiederholen" maxlength="100">
                  <div id="companyREEmailRepeatedNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <br />
            <h3>Inhaber / Geschäftsführer</h3>
            <span class="fst-italic">Dies sind keine Login-Daten und dienen nur zur Information.</span>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCeoName" class="form-label label-view">Vorname Nachname</label>
                  <input v-model="registrationData.ceoName" type="text" id="ceoName" class="form-control input-view" placeholder="Vorname Nachname des/r Geschäftsführer/in" maxlength="30">
                  <div id="ceoNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputCeoPhone" class="form-label label-view">Telefon (Inhaber/Geschäftsführer)</label>
                  <input v-model="registrationData.ceoPhone" type="text" id="ceoPhone" class="form-control input-view" placeholder="Telefonnummer des/r Geschäftsführer/in" maxlength="30">
                  <div id="ceoPhoneNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <br />
            <h3>Ansprechpartner (Admin &amp; Login der Firma)</h3>
            <span class="fst-italic">Für Änderung der E-Mail-Adresse wenden Sie sich bitte an den Support.</span>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputContactPersonGender" class="form-label label-view">Geschlecht</label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="registrationData.contactPersonGender" class="form-select input-view" id="contactPersonGender">
                        <option value="männlich">männlich</option>
                        <option value="weiblich">weiblich</option>
                        <option value="divers">divers</option>
                        <option value="sonstige">sonstige</option>
                      </select>
                    </div>
                  </div>
                  <div id="contactPersonGenderNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputContactPersonFirstName" class="form-label label-view">Vorname</label>
                  <input v-model="registrationData.contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30">
                  <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputContactPersonLastName" class="form-label label-view">Nachname</label>
                  <input v-model="registrationData.contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30">
                  <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-7">
                  <label for="inputContactPersonLastName" class="form-label label-view">E-Mail</label>
                  <input v-model="registrationData.contactPersonEmail" type="text" id="contactPersonEmail" class="form-control input-view" maxlength="30" readonly>
                  <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3>Handling am POS</h3>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <span><strong>Erfassung der Transaktionen über</strong></span>
                  <div class="form-check">
                    <input v-model="registrationData.handlingAtPOSPC" class="form-check-input" type="checkbox" value="PC / PC-Kasse" id="handlingAtPOSPC">
                    <label class="form-check-label" for="handlingAtPOSPC">
                      PC / PC-Kasse
                    </label>
                  </div>
                  <div id="handlingAtPOSSmartphoneNote" class="form-text error-note"></div>
                  <div class="form-check">
                    <input v-model="registrationData.handlingAtPOSSmartphone" class="form-check-input" type="checkbox" value="Smartphone / Tablet" id="handlingAtPOSSmartphone">
                    <label class="form-check-label" for="handlingAtPOSSmartphone">
                      Smartphone / Tablet
                    </label>
                  </div>
                  <div class="pt-2 border-bottom pb-3" v-if="registrationData.handlingAtPOSSmartphone === true">
                    <div class="row">
                      <div class="col-lg-7 offset-1">
                        <label for="smartphoneOS" class="form-label label-view">Betriebssystem</label>
                        <select v-model="registrationData.smartphoneOS" class="form-select input-view" id="smartphoneOS">
                          <option value="iOS">iOS</option>
                          <option value="Android">Android</option>
                          <option value="Sonstige">Sonstige</option>
                        </select>
                        <div id="smartphoneOSNote" class="form-text error-note"></div>
                      </div>
                    </div>
                    <div class="row" v-if="registrationData.smartphoneOS === 'Sonstige'">
                      <div class="col-lg-7 offset-1">
                        <label for="smartphoneOthersOS" class="form-label label-view">Betriebssystem eingeben</label>
                        <input v-model="registrationData.smartphoneOthersOS" type="text" id="smartphoneOthersOS" class="form-control input-view" placeholder="Sonstiges Betriebssystem eingeben">
                        <div id="smartphoneOthersOSNote" class="form-text error-note"></div>
                      </div>
                    </div>
                  </div>
                  <div id="handlingAtPOSSmartphoneNote" class="form-text error-note"></div>
                  <div class="form-check" v-if="(registrationData.handlingAtPOS !== null && typeof registrationData.handlingAtPOS !== 'undefined') && (registrationData.handlingAtPOS.toLowerCase().includes('ec terminal') || registrationData.handlingAtPOS.toLowerCase().includes('ec-terminal'))">
                    <input v-model="registrationData.handlingAtPOSEcDevice" class="form-check-input" type="checkbox" value="EC Terminal" id="handlingAtPOSEcDevice">
                    <label class="form-check-label" for="handlingAtPOSEcDevice">
                      EC-Cash-Terminal <span class="fst-italic">(nicht alle EC-Terminals können verwendet werden)</span>
                    </label>
                    <div class="form-text">Die Freischaltung eines EC-Cash-Terminals ist mit einer zusätzlichen Freischaltgebühr verbunden <router-link :to="{ name: 'PartnerConditionsOverview' }" target="_blank">(s. aktuelle Preisliste)</router-link>. Ein/e Mitarbeiter/in von uns wird sich in Kürze mit Ihnen in Verbindung setzen, sobald die Prüfung Ihres EC-Gerätes abgeschlossen ist.</div>
                  </div>
                  <div class="pt-2 border-bottom pb-3" v-if="registrationData.handlingAtPOSEcDevice && registrationData.handlingAtPOSEcDevice === true">
                    <div class="row">
                      <div class="col-lg-7 offset-1">
                        <label for="inputEcManufacturer" class="form-label label-view">Hersteller</label>
                        <input v-model="registrationData.ecManufacturer" type="text" id="ecManufacturer" class="form-control input-view" placeholder="Hersteller eingeben">
                        <div id="ecManufacturerNote" class="form-text error-note"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-7 offset-1">
                        <label for="inputEcType" class="form-label label-view">Typ EC-Gerät</label>
                        <input v-model="registrationData.ecType" type="text" id="ecType" class="form-control input-view" placeholder="Typ des EC-Gerätes eingeben">
                        <div id="ecTypeNote" class="form-text error-note"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-7 offset-1">
                        <label for="inputEcTerminalID" class="form-label label-view">Terminal-ID (TID)</label>
                        <input v-model="registrationData.ecTerminalID" type="text" id="ecTerminalID" class="form-control input-view" placeholder="Terminal-ID eingeben">
                        <div class="form-text">Sie finden Ihre TID auf jedem Händlerbeleg (s. Foto) </div>
                        <div id="ecTerminalIDNote" class="form-text error-note"></div>
                      </div>
                      <div class="col-lg-4">
                        <img class="img-fluid" :src="require('../assets/img/ec-terminal-tid-beleg.png')">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-7 offset-1">
                        <label for="inputEcSerialNumber" class="form-label label-view">Seriennummer</label>
                        <input v-model="registrationData.ecSerialNumber" type="text" id="ecSerialNumber" class="form-control input-view" placeholder="Seriennummer eingeben">
                        <div id="ecSerialNumberNote" class="form-text error-note"></div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-7 offset-1">
                        <div class="form-check">
                          <input v-model="registrationData.ecCashpointIntegration" class="form-check-input" type="checkbox" value="" id="ecCashpointIntegration">
                          <label class="form-check-label" for="ecCashpointIntegration">
                            Integration in Handelskasse
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="registrationData.ecCashpointIntegration === true">
                      <div class="col-lg-7 offset-1">
                        <label for="inputEcCashpointIntegrationManufacturer" class="form-label label-view">Anbieter Handelskassensoftware</label>
                        <input v-model="registrationData.ecCashpointIntegrationManufacturer" type="text" id="ecCashpointIntegrationManufacturer" class="form-control input-view" placeholder="Anbieter Handelskassensoftware eingeben">
                        <div id="ecCashpointIntegrationManufacturerNote" class="form-text error-note"></div>
                      </div>
                    </div>
                  </div>
                  <div id="handlingAtPOSEcDeviceNote" class="form-text error-note"></div>
                  <div class="form-check">
                    <input v-model="registrationData.handlingAtPOSCashpoint" class="form-check-input" type="checkbox" value="Handelskassenanbindung" id="handlingAtPOSCashpoint">
                    <label class="form-check-label" for="handlingAtPOSCashpoint">
                      Integration in Handelskasse
                    </label>
                    <div class="form-text">Für eine Integration in Ihre Handelskasse muss die in der Regel kostenpflichtige Schnittstellen-Anbindung durch Ihren Handelskassenanbieter erfolgen. Ein/e Mitarbeiter/in wird sich in Kürze mit Ihnen in Verbindung setzen und Ihren individuellen Bedarf mit Ihnen besprechen.</div>
                  </div>
                  <div id="handlingAtPOSCashpointNote" class="form-text error-note"></div>
                  <div class="row" v-if="registrationData.handlingAtPOSCashpoint === true">
                      <div class="col-lg-7 offset-1">
                        <label for="cashpointManufacturer" class="form-label label-view">Anbieter Handelskassensoftware</label>
                        <input v-model="registrationData.cashpointManufacturer" type="text" id="cashpointManufacturer" class="form-control input-view" placeholder="Anbieter Handelskassensoftware eingeben">
                        <div id="cashpointManufacturerNote" class="form-text error-note"></div>
                      </div>
                    </div>
                </div>
                <div id="handlingAtPOSNote" class="form-text error-note"></div>
              </div>
            </div>
            <br />
            <div v-if="this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('kein disagio')">
              <h3>Disagio je Transaktion (Tx):</h3>
              <div class="row my-3 text-muted">
                <div class="col-lg-12">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="registrationData.disagio" id="0% - Sondertarif" value="0% - Sondertarif" checked>
                    <label class="form-check-label" for="0% - Sondertarif">
                      <span>0% - Sondertarif</span>
                    </label>
                  </div>
                  <div id="disagioNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="registrationData.disagio !== '2% - keine Teilnahmegebühr' || registrationData.community === 'Lahr'">
                <h3>Ich wähle folgendes Disagio-Modell</h3>
                <span class="fst-italic">Kann jederzeit für die Zukunft geändert werden (mind. 0,20 € je Buchung, außer bei 3,0% Disagio). Eine Änderung wird beginnend mit dem nächsten Monat aktiv.</span>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-12">
                      <div v-for="(disagioModel, index) in disagioModels" :key="index">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" v-model="registrationData.disagio" :id="disagioModel.id" :value="disagioModel.value" disabled readonly>
                          <label class="form-check-label" :for="disagioModel.id">
                            <span v-html="disagioModel.description"></span>
                          </label>
                        </div>
                      </div>
                      <div id="disagioNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <h3>Disagio je Transaktion (Tx):</h3>
                <div class="row my-3 text-muted">
                  <div class="col-lg-12">
                    <div v-for="(disagioModel, index) in specialDisagioModels" :key="index">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="registrationData.disagio" :id="disagioModel.id" :value="disagioModel.value" checked>
                        <label class="form-check-label" :for="disagioModel.id">
                          <span v-html="disagioModel.description"></span>
                        </label>
                      </div>
                    </div>
                    <div id="disagioNote" class="form-text error-note"></div>
                  </div>
                </div>
                <h6>Erläuterung zur Berechnung:</h6>
                <table class="table table-bordered text-muted">
                  <tr><td>Einlöse-Tx (Guthabeneinlösung):</td><td>2,0% (mind. 0,20 € je Tx) des Einlösebetrages</td></tr>
                  <tr><td>Bonus-Tx (Einkaufsbonus vergeben):</td><td>2,0% (mind. 0,20 € je Tx) des Einkaufsbetrages, <strong>aber maximal 0,50 € je Buchung (Tx)</strong></td></tr>
                  <tr><td>Auflade-Tx (Gutschein-/Guthabenaufladung):</td><td><strong>kostenfrei</strong></td></tr>
                </table>
                <br />
                <div class="text-danger" v-if="registrationData.community === 'Leinfelden-Echterdingen' || registrationData.community === 'Landshut' || registrationData.community === 'Herbolzheim' || registrationData.community === 'Schwabach' || registrationData.community === 'Gräfelfing' || registrationData.community === 'Planegg'">
                  <strong>Bis Ende des Jahres 2024 werden die Disagio-Kosten vom Auftraggeber der {{ this.$store.state.region.cardName }} übernommen.</strong><br />
                  Über eine Fortführung der Aktion im Jahr 2025 wird Ende 2024 entschieden.<br />
                  Alle Partner erhalten rechtzeitig entsprechende Informationen, um für das Jahr 2025 planen zu können.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class="pt-2 mb-3 border-top">
              <div class="text-end">
                <button v-if="this.registrationData.partnerDataComplete === true && this.priceToPay === 0" @click="saveData()" class="btn btn-primary text-end mx-3">Partnerdaten speichern &gt;&gt;</button>
                <button @click="nextStep()" class="btn btn-primary ms-4">Daten zur Veröffentlichung &gt;&gt;</button>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 2">
            <div class="row mt-4">
              <div class="col-6">
                <h3>{{ (branchIndex + 1) === 1 ? 'Hauptstelle' : 'Filiale ' + branchIndex }}</h3>
              </div>
              <div v-if="(branchIndex + 1) > 1" class="col-6 text-end">
                <button v-if="!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false" @click="removeBranch((branchIndex))" class="btn btn-primary">Filiale {{ branchIndex }} entfernen</button>
                <button v-if="Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') && registrationData.branches[branchIndex].isOriginBranch === true" data-bs-toggle="modal" data-bs-target="#deleteBranchModal" class="btn btn-primary">Filiale {{ branchIndex }} entfernen</button>
              </div>
            </div>
            <h4>Firmendaten für Veröffentlichung<br />auf der Website und in der App</h4>
            <div class="pt-3">
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyNameIntern" class="form-label label-view">interne Bezeichnung</label>
                    <input v-model="registrationData.branches[branchIndex].companyNameIntern" type="text" id="companyNameIntern" class="form-control input-view" placeholder="interne Bezeichnung eingeben">
                    <div id="companyNameInternNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyName" class="form-label label-view">Firmenname (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                    <div id="companyNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyStreet" class="form-label label-view">Straße und Hausnummer (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                    <div id="companyStreetNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyZip" class="form-label label-view">Postleitzahl (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                    <div id="companyZipNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyCity" class="form-label label-view">Ort (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                    <div id="companyCityNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyCountry" class="form-label label-view">Land (zur Veröffentlichung)</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.branches[branchIndex].companyCountry" class="form-select input-view" id="companyCountry">
                          <option value="DE">Deutschland</option>
                          <option value="CH">Schweiz</option>
                          <option value="AT">Österreich</option>
                          <option value="FR">Frankreich</option>
                          <option value="BE">Belgien</option>
                          <option value="LU">Luxemburg</option>
                          <option value="DK">Dänemark</option>
                          <option value="NO">Norwegen</option>
                          <option value="SE">Schweden</option>
                          <option value="FI">Finnland</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="NL">Niederlande</option>
                          <option value="PL">Polen</option>
                          <option value="PT">Portugal</option>
                          <option value="RO">Rumänien</option>
                          <option value="RU">Russland</option>
                          <option value="GR">Griechenland</option>
                          <option value="AL">Albanien</option>
                          <option value="AD">Andorra</option>
                          <option value="BG">Bulgarien</option>
                          <option value="IT">Italien</option>
                          <option value="IE">Irland</option>
                          <option value="EE">Estland</option>
                          <option value="LV">Lettland</option>
                          <option value="LT">Litauen</option>
                          <option value="KZ">Kasachstan</option>
                          <option value="SM">San Marino</option>
                          <option value="RS">Serbien</option>
                          <option value="SK">Slowakei</option>
                          <option value="SI">Slowenien</option>
                          <option value="ES">Spanien</option>
                          <option value="CZ">Tschechien</option>
                          <option value="TR">Türkei</option>
                          <option value="UA">Ukraine</option>
                          <option value="HU">Ungarn</option>
                          <option value="GB">Vereinigtes Königreich</option>
                          <option value="MT">Malta</option>
                          <option value="MC">Monaco</option>
                          <option value="MK">Nordmazedonien</option>
                        </select>
                      </div>
                    </div>
                    <div id="companyCountryNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyEmail" class="form-label label-view">E-Mail-Adresse (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyEmail" type="email" id="companyEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                    <div id="companyEmailNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyWebsite" class="form-label label-view">Website (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyWebsite" type="text" id="companyWebsite" class="form-control input-view" placeholder="URL der Website eingeben">
                    <div id="companyWebsiteNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyPhone" class="form-label label-view">Telefonnummer (zur Veröffentlichung)</label>
                    <input v-model="registrationData.branches[branchIndex].companyPhone" type="text" id="companyPhone" class="form-control input-view" placeholder="Telefonnummer eingeben">
                    <div id="companyPhoneNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h3>Filial-Benutzer</h3>
            <div v-for="currentUsersIndex in (registrationData.branches[branchIndex].branchUserIndex+1)" :key="currentUsersIndex">
              <div class="row mt-4">
                <div class="col-6">
                  <h4>Benutzer {{ currentUsersIndex }}</h4>
                </div>
                <div v-if="currentUsersIndex > 1" class="col-6 text-end">
                  <button @click="removeBranchUser((currentUsersIndex-1))" class="btn btn-primary">Benutzer {{ currentUsersIndex }} entfernen</button>
                </div>
              </div>
              <div class="pt-2 border-top float-none">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonGender" class="form-label label-view">Geschlecht</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonGender" class="form-select input-view" id="contactPersonGender" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                          <option value="männlich">männlich</option>
                          <option value="weiblich">weiblich</option>
                          <option value="divers">divers</option>
                          <option value="sonstige">sonstige</option>
                        </select>
                      </div>
                    </div>
                    <div id="contactPersonGenderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonFirstName" class="form-label label-view">Vorname</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                    <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonLastName" class="form-label label-view">Nachname</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                    <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonEmail" class="form-label label-view">E-Mail-Adresse</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonEmail" type="email" id="contactPersonEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                    <div id="contactPersonEmailNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonEmailRepeated" class="form-label label-view">E-Mail-Adresse wiederholen</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonEmailRepeated" type="email" id="contactPersonEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                    <div id="contactPersonEmailRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top" v-if="!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonPassword" class="form-label label-view">Passwort</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonPassword" @input="passwordChecker" type="password" id="contactPersonPassword" class="form-control input-view" placeholder="Passwort eingeben" maxlength="50">
                    <div id="contactPersonPasswordNote" class="form-text error-note"></div>
                    <div id="passwordChecker" class="form-text text-start">
                        <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                        <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                        <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                        <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top" v-if="!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonPasswordRepeated" class="form-label label-view">Passwort wiederholen</label>
                    <input v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonPasswordRepeated" type="password" id="contactPersonPasswordRepeated" class="form-control input-view" placeholder="Passwort wiederholen" maxlength="50">
                    <div id="contactPersonPasswordRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top float-none" v-if="(branchIndex + 1) === 1 && currentUsersIndex > 1">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonGender" class="form-label label-view">Benutzer-Rolle</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select v-model="registrationData.branches[branchIndex].branchUsers[(currentUsersIndex-1)].contactPersonPartnerPortalRole" class="form-select input-view" id="contactPersonPartnerPortalRole" :disabled="!(!Object.prototype.hasOwnProperty.call(registrationData.branches[branchIndex], 'isOriginBranch') || !registrationData.branches[branchIndex].isOriginBranch || registrationData.branches[branchIndex].isOriginBranch === false) ? true : false">
                          <option value="Admin">Admin</option>
                          <option value="User">User</option>
                          <option value="Buchhaltung">Buchhaltung</option>
                        </select>
                      </div>
                    </div>
                    <div id="contactPersonGenderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3>Kategorien</h3>
            <div class="pt-2 pb-3 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <strong>In welchen Kategorien möchten Sie veröffentlicht werden? (Mehrfachauswahl möglich)</strong>
                  <div class="form-check">
                    <div v-for="(category, categoryIndex) in this.categories" :key="category" :index="categoryIndex">
                      <input :value="category" @change="onCategoryCheckboxChanged" :checked="isCategoryCheckboxChecked(categoryIndex)" name="categories" class="form-check-input categoryCheckbox" type="checkbox" :id="'companyCategories' + categoryIndex">
                      <label class="form-check-label" :for="'companyCategories' + categoryIndex">{{ category }}</label>
                    </div>
                  </div>
                  <div id="companyCategoriesNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3>Öffnungszeiten</h3>
            <div class="pt-2 pb-3 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-check">
                    <input v-model="registrationData.branches[branchIndex].companyOpenHoursOnlyByArrangement" class="form-check-input" type="checkbox" id="companyOpenHoursOnlyByArrangement">
                    <label class="form-check-label" for="companyOpenHoursOnlyByArrangement">
                      Termine nur nach Vereinbarung
                    </label>
                  </div>
                  <div id="companyOpenHoursOnlyByArrangementNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div v-if="registrationData.branches[branchIndex].companyOpenHoursOnlyByArrangement === false">
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Montag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnMonday" v-model="registrationData.branches[branchIndex].isClosedOnMonday">
                            <label class="form-check-label" for="isClosedOnMonday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursMondayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnMonday" type="time" id="companyOpenHoursMondayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursMondayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnMonday" type="time" id="companyOpenHoursMondayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursMondayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursMondayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnMonday" type="time" id="companyOpenHoursMondayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursMondayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnMonday" type="time" id="companyOpenHoursMondayTo2" class="form-control input-view time-field" size="4">
                        </div>
                        <br />
                        <a @click="transferOpeningHoursToAllDays()" class="primary-color" id="transfer-open-hours-button">Auf Montag - Freitag übertragen</a>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('monday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursMondaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Dienstag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnTuesday" v-model="registrationData.branches[branchIndex].isClosedOnTuesday">
                            <label class="form-check-label" for="isClosedOnTuesday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursTuesdayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnTuesday" type="time" id="companyOpenHoursTuesdayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursTuesdayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnTuesday" type="time" id="companyOpenHoursTuesdayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursTuesdayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursTuesdayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnTuesday" type="time" id="companyOpenHoursTuesdayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursTuesdayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnTuesday" type="time" id="companyOpenHoursTuesdayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('tuesday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursTuesdaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Mittwoch
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnWednesday" v-model="registrationData.branches[branchIndex].isClosedOnWednesday">
                            <label class="form-check-label" for="isClosedOnWednesday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursWednesdayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnWednesday" type="time" id="companyOpenHoursWednesdayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursWednesdayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnWednesday" type="time" id="companyOpenHoursWednesdayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursWednesdayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursWednesdayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnWednesday" type="time" id="companyOpenHoursWednesdayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursWednesdayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnWednesday" type="time" id="companyOpenHoursWednesdayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('wednesday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursWednesdaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Donnerstag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnThursday" v-model="registrationData.branches[branchIndex].isClosedOnThursday">
                            <label class="form-check-label" for="isClosedOnThursday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursThursdayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnThursday" type="time" id="companyOpenHoursThursdayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursThursdayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnThursday" type="time" id="companyOpenHoursThursdayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursThursdayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursThursdayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnThursday" type="time" id="companyOpenHoursThursdayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursThursdayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnThursday" type="time" id="companyOpenHoursThursdayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('thursday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursThursdaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Freitag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnFriday" v-model="registrationData.branches[branchIndex].isClosedOnFriday">
                            <label class="form-check-label" for="isClosedOnFriday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursFridayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnFriday" type="time" id="companyOpenHoursFridayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursFridayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnFriday" type="time" id="companyOpenHoursFridayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursFridayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursFridayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnFriday" type="time" id="companyOpenHoursFridayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursFridayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnFriday" type="time" id="companyOpenHoursFridayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('friday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursFridaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Samstag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnSaturday" v-model="registrationData.branches[branchIndex].isClosedOnSaturday">
                            <label class="form-check-label" for="isClosedOnSaturday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursSaturdayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnSaturday" type="time" id="companyOpenHoursSaturdayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursSaturdayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnSaturday" type="time" id="companyOpenHoursSaturdayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursSaturdayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursSaturdayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnSaturday" type="time" id="companyOpenHoursSaturdayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursSaturdayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnSaturday" type="time" id="companyOpenHoursSaturdayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('saturday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursSaturdaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-2">
                        Sonntag
                      </div>
                      <div class="col-4">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="isClosedOnSunday" v-model="registrationData.branches[branchIndex].isClosedOnSunday">
                            <label class="form-check-label" for="isClosedOnSunday">geschlossen</label>
                          </div>
                      </div>
                      <div class="col-5">
                        <input v-model="registrationData.branches[branchIndex].companyOpenHoursSundayFrom1" :disabled="registrationData.branches[branchIndex].isClosedOnSunday" type="time" id="companyOpenHoursSundayFrom1" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursSundayTo1" :disabled="registrationData.branches[branchIndex].isClosedOnSunday" type="time" id="companyOpenHoursSundayTo1" class="form-control input-view time-field" size="4">
                        <div v-if="registrationData.branches[branchIndex].companyOpenHoursSundayFrom2">
                          <br />
                          <input v-model="registrationData.branches[branchIndex].companyOpenHoursSundayFrom2" :disabled="registrationData.branches[branchIndex].isClosedOnSunday" type="time" id="companyOpenHoursSundayFrom2" class="form-control input-view time-field" size="4">&nbsp;&nbsp;-&nbsp;&nbsp;<input v-model="registrationData.branches[branchIndex].companyOpenHoursSundayTo2" :disabled="registrationData.branches[branchIndex].isClosedOnSunday" type="time" id="companyOpenHoursSundayTo2" class="form-control input-view time-field" size="4">
                        </div>
                      </div>
                      <div class="col-1">
                        <span class="newOpeningHours"><img @click="addSecondOpeningHour('sunday')" width="30" class="add-second-opening-hour-image-button" :src="require(`../assets/img/${companyOpenHoursSundaySecondHourButtonImage}`)"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <label for="companyOpenHoursAdditionalInfo" class="form-label label-view">Optionale Infos, z.B. geöffnet an allen Feiertagen außer 25.12. und 01.01.</label>
                    <input v-model="registrationData.branches[branchIndex].companyOpenHoursAdditionalInfo" type="text" id="companyOpenHoursAdditionalInfo" class="form-control input-view" placeholder="Optionale Infos zu den Öffnungszeiten" maxlength="250">
                    <div id="companyOpenHoursAdditionalInfoNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            <br />
            <div class="pt-2 mb-3 border-top">
              <div class="float-start w-50">
                <button @click="previousBranch()" v-if="branchIndex > 0" class="btn btn-secondary"> &lt;&lt; {{ (branchIndex - 1) === 0 ? 'zur Hauptstelle' : 'zur vorherigen Filiale ' + (branchIndex - 1) }}</button>
                <button @click="previousStep()" v-if="branchIndex == 0" class="btn btn-secondary"> &lt;&lt; zurück zu den Vertragsdaten</button>
              </div>
              <div class="float-end text-end w-50">
                <button @click="nextBranch()" class="btn btn-secondary" v-if="!((branchIndex + 1) >= registrationData.branches.length)">{{ 'zu Filiale ' + (branchIndex + 1) }} &gt;&gt;</button>
                <button @click="nextBranch()" class="btn btn-primary" v-if="(branchIndex + 1) >= registrationData.branches.length">weitere Filiale angeben &gt;&gt;</button>
              </div>
              <div class="clearfix"></div>
              <div class="float-none text-end pt-3">
                <button v-if="this.registrationData.partnerDataComplete === true && this.priceToPay === 0" @click="saveData()" class="btn btn-primary text-end mx-3">Partnerdaten speichern &gt;&gt;</button>
                <button @click="nextStep()" class="btn btn-primary ms-4">weiter zu Zahlungsart &gt;&gt;</button>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 3">
            <h2>Zahlungsart</h2>
            <div class="pt-2 border-top">
              <div class="row">
                <div class="col-lg-5">
                  <label for="inputPaymentMethod" class="form-label label-view">Zahlungsart / Art der Abrechnung</label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select v-model="registrationData.paymentMethod" class="form-select input-view" id="paymentMethod" :disabled="this.paymentMethodAlreadySet" :readonly="this.paymentMethodAlreadySet">
                        <option value="Bankeinzug">Bankeinzug</option>
                        <option value="Rechnung">Rechnung</option>
                      </select>
                    </div>
                  </div>
                  <div id="paymentMethodNote" class="form-text error-note"></div>
                </div>
                <div class="col-lg-7">
                  <img class="d-block float-left img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20">
                  Für die Änderung der Zahlungsart senden Sie uns bitte eine Nachricht über das <router-link :to="{ name: 'PartnerContact' }" target="_blank">Kontaktformular</router-link>
                </div>
              </div>
            </div>
            <div v-if="registrationData.paymentMethod === 'Bankeinzug'">
              <h4 class="mt-3">SEPA-Mandat (Firmenlastschrift)</h4>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                    <input v-model="registrationData.sepaCompanyStreet" type="text" id="sepaCompanyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                    <div id="sepaCompanyStreetNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaCompanyZip" class="form-label label-view">Postleitzahl</label>
                    <input v-model="registrationData.sepaCompanyZip" type="text" id="sepaCompanyZip" class="form-control input-view" placeholder="PLZ eingeben">
                    <div id="sepaCompanyZipNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaCompanyCity" class="form-label label-view">Ort</label>
                    <input v-model="registrationData.sepaCompanyCity" type="text" id="sepaCompanyCity" class="form-control input-view" placeholder="Ort eingeben">
                    <div id="sepaCompanyCityNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaMandateReferenceNumber" class="form-label label-view">Mandatsreferenznummer</label>
                    <input v-model="registrationData.sepaMandateReferenceNumber" type="text" id="sepaMandateReferenceNumber" class="form-control input-view" placeholder="Mandatsrefernznummer eingeben" maxlength="70" disabled readonly>
                    <div id="sepaMandateReferenceNumberNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="registrationData.paymentMethod === 'Bankeinzug' || registrationData.paymentMethod === 'Rechnung'">
              <br />
              <br />
              <h4>Bankverbindung {{ registrationData.paymentMethod === 'Rechnung' ? '(Wohin dürfen wir ihre Gutschriften überweisen)' : ''}}</h4>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaAccountHolder" class="form-label label-view">Name Kontoinhaber</label>
                    <input v-model="registrationData.sepaAccountHolder" type="text" id="sepaAccountHolder" class="form-control input-view" placeholder="Name Kontoinhaber eingeben">
                    <div id="sepaAccountHolderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaIBAN" class="form-label label-view">IBAN</label>
                    <input v-model="registrationData.sepaIBAN" type="text" id="sepaIBAN" class="form-control input-view" placeholder="IBAN eingeben">
                    <div id="sepaIBANNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaBIC" class="form-label label-view">BIC</label>
                    <input v-model="registrationData.sepaBIC" type="text" id="sepaBIC" class="form-control input-view" placeholder="BIC eingeben">
                    <div id="sepaBICNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputSepaBankName" class="form-label label-view">Name der Bank</label>
                    <input v-model="registrationData.sepaBankName" type="text" id="sepaBankName" class="form-control input-view" placeholder="Name der Bank eingeben">
                    <div id="sepaBankNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div v-if="registrationData.paymentMethod === 'Bankeinzug'">
              <button @click="generateSepaPDF()" class="btn btn-primary">SEPA-Formular drucken</button>
              <br />
              <br />
              <div class="alert alert-danger" role="alert">
                Achtung, wichtiger Hinweis !<br />
                Bitte drucken Sie das SEPA-Mandat in doppelter Ausfertigung aus. Unterschreiben Sie dieses und senden Sie uns ein Exemplar zu.
                Das zweite Exemplar reichen Sie bitte unverzüglich bei Ihrer Hausbank ein, da nur so der reibungslose Ablauf gewährleistet ist.
                Ggfs. in dieser Zeit erstellte Rechnungen/Abrechnungen überweisen Sie dann bitte. Ein entsprechender Hinweis ist auf dem Abrechnungsbeleg dann vorhanden.
                Frühstens eine Woche nachdem uns Ihr SEPA-Mandat vorliegt, werden wir das Einzugsverfahren dann aktivieren.<br />
                Für Rücklastschriften, die mangels hinterlegtem SEPA-Firmenlastschrift-Mandats entstehen, müssen wir je Rücklastschrift leider 20,- EUR berechnen.
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="float-start">
                <button @click="previousStep()" class="btn btn-primary text-start">&lt;&lt; Zurück zu Filialen</button>
              </div>
              <div class="float-end" >
                <button v-if="this.registrationData.partnerDataComplete === true && this.priceToPay === 0" @click="saveData()" class="btn btn-primary text-end me-3">Partnerdaten speichern &gt;&gt;</button>
                <button @click="nextStep()" class="btn btn-primary text-end">Vertragsdetails &gt;&gt;</button>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 4">
            <h2>Vertragsdetails</h2>
            <div class="mb-3 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Vertragsnummer</td><td>{{ registrationData.contractID }}</td>
                      </tr>
                      <tr>
                        <td>Vertragsdaten übermittelt am</td><td>{{ registrationData.contactDetailsSentAt }}</td>
                      </tr>
                      <tr>
                        <td>Datum der Systemeinweisung</td><td>{{ registrationData.systemOnboardingAt }}</td>
                      </tr>
                      <tr>
                        <td>Vertragsbeginn</td><td>{{ registrationData.contractStartAt }}</td>
                      </tr>
                      <tr>
                        <td>Vertragsende</td><td>{{ registrationData.contractEndAt }} <img class="img-fluid" :src="require('../assets/img/booking/circle-info.svg')" width="20" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Der Vertrag verlängert sich immer um ein Jahr. Die Kündigungsfrist beträgt 1 Monat zum Ende eines Kalenderjahres."></td>
                      </tr>
                      <tr>
                        <td>Vertragsstatus</td><td>{{ registrationData.contractStatus }}</td>
                      </tr>
                      <tr>
                        <td>Teilnahmegebühr</td><td>{{ registrationData.participationFee }}</td>
                      </tr>
                      <tr>
                        <td>Zahlungsweise Teilnahmegebühr</td><td>{{ registrationData.participationPaymentMethod }}</td>
                      </tr>
                      <tr>
                        <td>AGB</td><td><a :href="`${this.$store.state.region.whiteLabelWebsiteUrl}partner-bedingungen/`" target="_blank">Zu den AGBs</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-3">
              <p>Im Rahmen der Registrierung und Nutzung des Partnerportals verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }" target="_blank">Datenschutzerkärung</router-link>.</p>
            </div>
            <div class="pt-2 mb-3 border-top">
                <div class="float-start">
                  <button @click="previousStep()" class="btn btn-primary text-start">&lt;&lt; Zurück zur Zahlungsart</button>
                </div>
                <div class="float-end" >
                  <button v-if="this.priceToPay === 0 || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)" @click="saveData()" class="btn btn-primary text-end">Partnerdaten speichern >></button>
                  <button v-if="this.priceToPay > 0 && !(this.registrationData.tmspartner && this.registrationData.tmspartner === true)" @click="nextStep()" class="btn btn-primary text-end">Zum Checkout >></button>
                </div>
              </div>
          </div>
          <div v-if="currentStep === 5">
            <p>Sie haben einen Gutscheincode? <span class="voucher-link" @click="onOpenVoucherClicked()"><u>Hier Gutscheincode eingeben</u></span></p>
            <div v-if="shouldShowVoucherInput">
              <div class="row">
                <div class="col-lg-7">
                  <label for="voucherCode" class="form-label label-view">Gutscheincode</label>
                  <div class="input-group">
                    <input v-model="voucherCode" type="text" id="voucherCode" class="form-control input-view" placeholder="Gutscheincode eingeben" aria-describedby="add-voucher-button">
                    <button class="btn btn-primary" id="add-voucher-button" @click="setVoucherValue()">Gutscheincode anwenden</button>
                  </div>
                  <div id="vocherCodeNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="row">
                <div class="col-lg-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Produktname</th>
                        <th v-if="voucherValue > 0 || voucherPercent > 0">Gutscheincode</th>
                        <th>Preis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="index in (this.registrationData.branches.length - this.registrationData.amountOfOriginBranches)" :key="index">
                        <td>zusätzliche Filiale ({{ this.registrationData.branches[(this.registrationData.amountOfOriginBranches + index - 1)].companyName }}{{ this.registrationData.branches[(this.registrationData.amountOfOriginBranches + index - 1)].companyNameIntern && this.registrationData.branches[(this.registrationData.amountOfOriginBranches + index - 1)].companyNameIntern !== '' ? ' / ' + this.registrationData.branches[(this.registrationData.amountOfOriginBranches + index - 1)].companyNameIntern : '' }})<br /><span class="fst-italic text-muted" v-html="regionCheckoutData.partner_entry_fee_description_text"></span></td>
                        <td v-if="voucherValue > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{voucherValue}}€ netto Rabatt aktiv</span></td>
                        <td v-if="voucherPercent > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{(voucherPercent * 100)}}% Rabatt aktiv</span></td>
                        <td>{{ this.netAmount / (this.registrationData.branches.length - this.registrationData.amountOfOriginBranches) }}€ zzgl. MwSt.</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <th>Gesamtsumme</th>
                      <th v-if="voucherValue > 0 || voucherPercent > 0">
                      - <span v-if="voucherValue > 0">{{voucherValue}}€ netto Rabatt</span><span v-if="voucherPercent > 0">{{(voucherPercent * 100)}}% netto Rabatt</span>
                      </th>
                      <th>
                        <strong>{{ priceToPay }}€</strong><br />
                          ({{netAmount}}€ zzgl. MwSt.)
                      </th>
                    </tfoot>
                  </table>
                </div>
                <div class="col-lg-12 mt-5">
                  <div v-if="this.priceToPay > 0" id="paypal-button-container" ref="paypalButons"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="float-start">
                <button @click="previousStep()" class="btn btn-primary text-start">&lt;&lt; Zurück zur Zahlungsart</button>
              </div>
              <div class="float-end">
                <button v-if="this.priceToPay === 0" @click="saveData()" class="btn btn-primary text-end">Partnerdaten speichern &gt;&gt;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="deleteBranchModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-black">Filiale wirklich löschen?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <img class="d-block img-fluid mx-auto" :src="require('../assets/img/warning-icon.svg')" width="60">
          <p class="fs-3">Achtung, wenn Sie die Filiale entfernen, können Sie über diese Filiale keine Buchungen mehr tätigen. Die Filiale wird dann auch aus allen Veröffentlichungen entfernt!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg" data-bs-dismiss="modal" @click="removeBranch((branchIndex))">Filiale löschen</button>
          <button type="button" class="btn btn-primary btn-lg" data-bs-dismiss="modal">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
require('../assets/img/circle-minus.svg')
const slugify = require('slugify')
const bootstrap = require('bootstrap')

export default {
  name: 'PartnerPersonalDataPage',
  data () {
    return {
      currentStep: 1,
      registrationData: {},
      regionCheckoutData: {},
      paymentMethodAlreadySet: false,
      validationStatus: false,
      branchIndex: 0,
      paypalLoaded: false,
      showVoucherInput: false,
      voucherValue: 0,
      voucherPercent: 0,
      voucherCode: '',
      categories: [],
      deleteBranchBootstrapEl: undefined,
      registrationDataStep1Keys: ['companyEmailHeadquarter', 'companyEmailHeadquarterRepeated', 'companyEmail', 'companyEmailRepeated', 'companyName', 'companyAddressAdditional', 'companyStreet', 'companyZip', 'companyCity', 'companyCountry', 'contactPersonGender', 'contactPersonFirstName', 'contactPersonLastName', 'contactPersonEmail', 'handlingAtPOSPC', 'handlingAtPOSSmartphone', 'handlingAtPOSCashpoint', 'handlingAtPOSEcDevice', 'companyREName', 'companyREStreet', 'companyREZip', 'companyRECity', 'companyRECountry', 'companyREEmail', 'companyREEmailRepeated', 'disagio'],
      registrationDataStep2Keys: ['companyName', 'companyStreet', 'companyZip', 'companyCity', 'companyCountry', 'contactPersonGender', 'contactPersonFirstName', 'contactPersonLastName', 'contactPersonEmail', 'contactPersonEmailRepeated', 'contactPersonPassword', 'contactPersonPasswordRepeated', 'companyCategories', 'companyOpenHoursMondayFrom1', 'companyOpenHoursMondayTo1', 'companyOpenHoursMondayFrom2', 'companyOpenHoursMondayTo2', 'companyOpenHoursTuesdayFrom1', 'companyOpenHoursTuesdayTo1', 'companyOpenHoursTuesdayFrom2', 'companyOpenHoursTuesdayTo2', 'companyOpenHoursWednesdayFrom1', 'companyOpenHoursWednesdayTo1', 'companyOpenHoursWednesdayFrom2', 'companyOpenHoursWednesdayTo2', 'companyOpenHoursThursdayFrom1', 'companyOpenHoursThursdayTo1', 'companyOpenHoursThursdayFrom2', 'companyOpenHoursThursdayTo2', 'companyOpenHoursFridayFrom1', 'companyOpenHoursFridayTo1', 'companyOpenHoursFridayFrom2', 'companyOpenHoursFridayTo2', 'companyOpenHoursSaturdayFrom1', 'companyOpenHoursSaturdayTo1', 'companyOpenHoursSaturdayFrom2', 'companyOpenHoursSaturdayTo2', 'companyOpenHoursSundayFrom1', 'companyOpenHoursSundayTo1', 'companyOpenHoursSundayFrom2', 'companyOpenHoursSundayTo2', 'isClosedOnSunday', 'isClosedOnSaturday', 'isClosedOnFriday', 'isClosedOnThursday', 'isClosedOnWednesday', 'isClosedOnTuesday', 'isClosedOnMonday'],
      registrationDataStep3Keys: ['paymentMethod', 'sepaCompanyStreet', 'sepaCompanyZip', 'sepaCompanyCity', 'sepaMandateReferenceNumber', 'sepaAccountHolder', 'sepaIBAN', 'sepaBIC', 'sepaBankName'],
      disagioModels: [
        {
          id: 'disagio-5-percent',
          description: '5,0% - ohne monatliche Teilnahmegebühr (geeignet bei Umsätzen 500 € mtl.)',
          value: '5% - keine Teilnahmegebühr'
        },
        {
          id: 'disagio-2-percent',
          description: '2,0% - zzgl. Teilnahmegebühr (geeignet bei Umsätzen bis 1.500 € mtl.)',
          value: '2% - Standardmodell'
        },
        {
          id: 'disagio-1-percent',
          description: '1,0% - monatlicher Aufpreis 15 €, zzgl. Teilnahmegebühr (geeignet bei Umsätzen bis 3.000 € mtl.)',
          value: '1% - zzgl. 15.00 € monatlich'
        },
        {
          id: 'disagio-05-percent',
          description: '0,5% - monatlicher Aufpreis 30 €, zzgl. Teilnahmegebühr (geeignet bei Umsätzen ab 3.000 € mtl.)',
          value: '0.5% - zzgl. 30.00 € monatlich'
        },
        {
          id: 'disagio-3-percent',
          description: '3,0% - Keine Mindestgebühr je Buchung, zzgl. Teilnahmegebühr (geeignet bei Durchschnittsbon &lt; 6,66 €)',
          value: '3% - keine Mindest-Tx-Gebühr'
        }
      ],
      specialDisagioModels: [
        {
          id: 'special-disagio-2-percent',
          description: '2% - keine Teilnahmegebühr',
          value: '2% - keine Teilnahmegebühr'
        }
      ],
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg'
    }
  },
  computed: {
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    companyOpenHoursMondaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursTuesdaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursWednesdaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursThursdaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursFridaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursSaturdaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursSaturdayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    companyOpenHoursSundaySecondHourButtonImage () {
      return this.registrationData.branches[this.branchIndex].companyOpenHoursSundayFrom2 ? 'circle-minus.svg' : 'circle-plus.svg'
    },
    priceToPay () {
      if (Object.prototype.hasOwnProperty.call(this.registrationData, 'branches') && this.registrationData.branches.length > 0 && this.registrationData.branches.length - this.registrationData.amountOfOriginBranches > 0) {
        const amountOfNewBranches = this.registrationData.branches.length - Number(this.registrationData.amountOfOriginBranches)

        if ((this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard')) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
          return 0
        } else {
          if (this.voucherValue > 0) {
            return (((Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)) - Number(this.voucherValue)) * 1.19).toFixed(2)
          } else if (this.voucherPercent > 0) {
            if (this.voucherPercent === 1) {
              return 0
            } else {
              return (((Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)) * Number(this.voucherPercent)) * 1.19).toFixed(2)
            }
          } else {
            return ((Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)) * 1.19).toFixed(2)
          }
        }
      }
      return 0
    },
    netAmount () {
      if (Object.prototype.hasOwnProperty.call(this.registrationData, 'branches') && this.registrationData.branches.length > 0 && this.registrationData.branches.length - this.registrationData.amountOfOriginBranches > 0) {
        const amountOfNewBranches = this.registrationData.branches.length - Number(this.registrationData.amountOfOriginBranches)

        if ((this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr gutscheincard')) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
          return 0
        } else {
          if (this.voucherValue > 0) {
            return ((Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)) - Number(this.voucherValue)).toFixed(2)
          } else if (this.voucherPercent > 0) {
            if (this.voucherPercent === 1) {
              return 0
            } else {
              return ((Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)) * Number(this.voucherPercent)).toFixed(2)
            }
          } else {
            return (Number(amountOfNewBranches) * Number(this.regionCheckoutData.partner_entry_fee)).toFixed(2)
          }
        }
      }
      return 0
    },
    shouldShowVoucherInput () {
      return this.showVoucherInput
    }
  },
  methods: {
    async saveData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      if (await this.validateRegistrationData()) {
        this.saveRegistrationData()
      } else {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    },
    async saveRegistrationData () {
      this.$store.dispatch('updatePartnerPersonalData', this.registrationData)
        .then(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        }).catch(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    },
    async validateRegistrationData () {
      this.validationStatus = true
      if (this.currentStep === 1) {
        const handlingAtPosNote = document.getElementById('handlingAtPOSNote')
        if (handlingAtPosNote) {
          handlingAtPosNote.innerHTML = ''
        }

        const disagioNote = document.getElementById('disagioNote')
        if (disagioNote) {
          disagioNote.innerHTML = ''
        }
        this.registrationDataStep1Keys.forEach(key => {
          if (key !== 'handlingAtPOSPC' && key !== 'handlingAtPOSSmartphone' && key !== 'handlingAtPOSCashpoint' && key !== 'handlingAtPOSEcDevice' && key !== 'contactPersonEmail' && key !== 'disagio') {
            document.getElementById(key).classList.remove('form-element-danger')
            document.getElementById(key + 'Note').innerHTML = ''
          }
        })
        this.registrationDataStep1Keys.forEach(key => {
          if (key === 'handlingAtPOSPC' || key === 'handlingAtPOSSmartphone' || key === 'handlingAtPOSCashpoint' || key === 'handlingAtPOSEcDevice') {
            if (!this.registrationData.handlingAtPOSPC && !this.registrationData.handlingAtPOSSmartphone && !this.registrationData.handlingAtPOSCashpoint && !this.registrationData.handlingAtPOSEcDevice) {
              this.showHandlingAtPosValidationError('handlingAtPOS', 'Sie müssen eine Erfassung der Transaktion auswählen')
              this.validationStatus = false
            } else {
              document.getElementById('handlingAtPOSNote').innerHTML = ''
            }
          } else if (this.registrationData.community === 'Lahr' && key === 'disagio') {
            if (!this.registrationData.disagio) {
              document.getElementById(key + 'Note').innerHTML = 'Sie müssen ein Disagio-Modell auswählen.'
              this.validationStatus = false
            }
          } else {
            if (key === 'contactPersonEmail') {
              return
            }
            if (!(key === 'companyAddressAdditional' || key === 'handlingAtPOSPC' || key === 'handlingAtPOSSmartphone' || key === 'handlingAtPOSCashpoint' || key === 'handlingAtPOSEcDevice') && (!this.registrationData[key] || this.registrationData[key] === '')) {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
              return
            }
            if (key !== 'handlingAtPOSPC' && key !== 'handlingAtPOSSmartphone' && key !== 'handlingAtPOSCashpoint' && key !== 'handlingAtPOSEcDevice') {
              if (key !== 'companyAddressAdditional' && key !== 'contactPersonFirstName' && key !== 'contactPersonLastName' && key !== 'companyCountry' && key !== 'companyRECountry' && this.registrationData[key].length < 3) {
                this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
              }
              if (key === 'companyCountry' && this.registrationData[key].length < 2) {
                this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
              }
              if ((key === 'contactPersonGender' || key === 'contactPersonFirstName' || key === 'contactPersonLastName' || key === 'companyCity' || key === 'companyCountry' || key === 'contactPersonRole' || key === 'handlingAtPOSPC' || key === 'handlingAtPOSCashpoint' || key === 'handlingAtPOSSmartphone' || key === 'handlingAtPOSEcDevice') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
              }
              if (key === 'companyStreet' && !(/\d/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
              }
              if ((key === 'companyZip') && !(/^\d+$/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
              }
              if ((key === 'companyZip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
                this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
              }
              if ((key === 'contactPersonEmail' || key === 'contactPersonEmailRepeated') && this.registrationData.contactPersonEmail !== this.registrationData.contactPersonEmailRepeated) {
                this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
              }
              if ((key === 'companyEmail' || key === 'companyEmailRepeated') && this.registrationData.companyEmail !== this.registrationData.companyEmailRepeated) {
                this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
              }
              if ((key === 'companyEmailHeadquarter' || key === 'companyEmailHeadquarterRepeated') && this.registrationData.companyEmailHeadquarter !== this.registrationData.companyEmailHeadquarterRepeated) {
                this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
              }
              if ((key === 'companyREEmail' || key === 'companyREEmailRepeated') && this.registrationData.companyREEmail !== this.registrationData.companyREEmailRepeated) {
                this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
              }
              if ((key === 'contactPersonEmail' || key === 'companyREEmail' || key === 'companyEmail' || key === 'companyEmailHeadquarter') && !this.registrationData[key].includes('@')) {
                this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
              }
            }
          }
        })
        if (this.registrationData.handlingAtPOSSmartphone && this.registrationData.handlingAtPOSSmartphone === true) {
          if (!this.registrationData.smartphoneOS) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Das Smartphone-Betriebssystem muss ausgewählt werden.')
          } else {
            if (this.registrationData.smartphoneOS === 'Sonstige') {
              if (!this.registrationData.smartphoneOthersOS) {
                this.showHandlingAtPosValidationError('handlingAtPOS', 'Das sonstige Smartphone-Betriebssystem muss angegeben werden.')
              }
            }
            if (!(this.registrationData.smartphoneOS === 'Sonstige' || this.registrationData.smartphoneOS === 'iOS' || this.registrationData.smartphoneOS === 'Android')) {
              this.showHandlingAtPosValidationError('handlingAtPOS', 'Das Smartphone-Betriebssystem hat einen ungültigen Wert.')
            }
          }
        }
        if (this.registrationData.handlingAtPOSEcDevice && this.registrationData.handlingAtPOSEcDevice === true) {
          if (!this.registrationData.ecManufacturer) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Der EC-Cash-Terminal Hersteller muss angegeben werden.')
          }
          if (!this.registrationData.ecType) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Der EC-Cash-Terminal Typ muss angegeben werden.')
          }
          if (!this.registrationData.ecTerminalID) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Die EC-Cash-Terminal Terminal-ID muss angegeben werden.')
          }
          if (!this.registrationData.ecSerialNumber) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Die EC-Cash-Terminal Seriennummer muss angegeben werden.')
          }
          if (this.registrationData.ecCashpointIntegration && this.registrationData.ecCashpointIntegration === true) {
            if (!this.registrationData.ecCashpointIntegrationManufacturer) {
              this.showHandlingAtPosValidationError('handlingAtPOS', 'Der Anbieter Ihrer Handelskassensoftware muss angegeben werden.')
            }
          }
        }
        if (this.registrationData.handlingAtPOSCashpoint && this.registrationData.handlingAtPOSCashpoint === true) {
          if (!this.registrationData.cashpointManufacturer) {
            this.showHandlingAtPosValidationError('handlingAtPOS', 'Der Anbieter Ihrer Handelskassensoftware muss angegeben werden.')
          }
        }
      } else if (this.currentStep === 2) {
        this.registrationDataStep2Keys.forEach(key => {
          if (!key.startsWith('companyOpenHours') && !key.startsWith('isClosedOn')) {
            this.removeValidationError(key)
          }
        })
        this.registrationDataStep2Keys.forEach(key => {
          if (key === 'contactPersonGender' || key === 'contactPersonFirstName' || key === 'contactPersonLastName' || key === 'contactPersonEmail' || key === 'contactPersonEmailRepeated') {
            for (const branchUser of this.registrationData.branches[this.branchIndex].branchUsers) {
              if (!branchUser[key]) {
                this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
                return
              }
              if ((key === 'contactPersonGender' || key === 'contactPersonEmail') && branchUser[key].length < 3) {
                this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
              }
              if ((key === 'contactPersonGender' || key === 'contactPersonFirstName' || key === 'contactPersonLastName') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(branchUser[key]))) {
                this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
              }
              if ((key === 'contactPersonEmail' || key === 'contactPersonEmailRepeated') && branchUser.contactPersonEmail !== branchUser.contactPersonEmailRepeated) {
                this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
              }
              if (key === 'contactPersonEmail' && !branchUser.contactPersonEmail.includes('@')) {
                this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
              }
            }
          } else if (key === 'contactPersonPassword' || key === 'contactPersonPasswordRepeated') {
            for (const branchUser of this.registrationData.branches[this.branchIndex].branchUsers) {
              const passwordField = document.getElementById(key)
              if (passwordField) {
                if (!branchUser[key]) {
                  this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
                  return
                }
                if (!(/^[a-zA-ZäöüßÄÖÜ0-9!$%()*,-.?@^_~]+$/.test(branchUser[key]))) {
                  this.showValidationError(key, 'Das Passwort enthält ungültige Zeichen. Neben Buchstaben und Ziffern sind nur folgende Sonderzeichen erlaubt:     !$%()*,-.?@^_~')
                } else if ((branchUser[key].length < 8 || !/[!$%()*,-.?@^_~]+/.test(branchUser[key]) || !/[0-9]+/.test(branchUser[key]) || !/[A-ZÄÖÜ]+/.test(branchUser[key]))) {
                  this.showValidationError(key, 'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Nummer, 1 Großbuchstaben und 1 Sonderzeichen (!$%()*,-.?@^_~) enthalten.')
                } else if (branchUser.contactPersonPassword !== branchUser.contactPersonPasswordRepeated) {
                  this.showValidationError(key, 'Die Passwörter stimmen nicht überein.')
                }
              }
            }
          } else {
            if (key === 'companyCategories') {
              return
            }
            if (key !== 'companyNameIntern' && key !== 'companyWebsite' && key !== 'companyPhone' && !key.startsWith('companyOpenHours') && !key.startsWith('isClosedOn') && !this.registrationData.branches[this.branchIndex][key]) {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
              return
            }
            if (key !== 'companyNameIntern' && key !== 'companyName' && key !== 'companyCountry' && key !== 'companyWebsite' && key !== 'companyPhone' && !key.startsWith('companyOpenHours') && !key.startsWith('isClosedOn') && this.registrationData.branches[this.branchIndex][key].length < 3) {
              this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
            }
            if (key === 'companyCountry' && this.registrationData.branches[this.branchIndex][key].length < 2) {
              this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
            }
            if ((key === 'companyCity' || key === 'companyCountry') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData.branches[this.branchIndex][key]))) {
              this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
            }
            if (key === 'companyStreet' && !(/\d/.test(this.registrationData.branches[this.branchIndex][key]))) {
              this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
            }
            if (key === 'companyZip' && !(/^\d+$/.test(this.registrationData.branches[this.branchIndex][key]))) {
              this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
            }
            if (key === 'companyZip' && !(this.registrationData.branches[this.branchIndex][key].length === 5 || this.registrationData.branches[this.branchIndex][key].length === 4)) {
              this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
            }
            if ((key === 'companyEmail' || key === 'companyEmailRepeated') && this.registrationData.branches[this.branchIndex].companyEmail !== this.registrationData.branches[this.branchIndex].companyEmailRepeated) {
              this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
            }
            if (key === 'companyEmail' && !this.registrationData.branches[this.branchIndex].companyEmail.includes('@')) {
              this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
            }
          }
        })
        for (const branchUser of this.registrationData.branches[this.branchIndex].branchUsers) {
          if (branchUser.contactPersonEmail !== branchUser.originContactPersonEmail) {
            await this.checkIfEmailAlreadyExists(branchUser.contactPersonEmail).then(emailAlreadyExists => {
              if (emailAlreadyExists === true) {
                this.showValidationError('contactPersonEmail', 'Die Benutzer Login E-Mail-Adresse existiert bereits. Bitte wählen Sie eine andere E-Mail-Adresse.')
              }
            }).catch(() => {
            })
          }
        }
        if (!this.registrationData.branches[this.branchIndex].companyCategories || this.registrationData.branches[this.branchIndex].companyCategories.length === 0) {
          this.showValidationError('companyCategories', 'Sie müssen mindestens eine Kategorie auswählen.')
        }
      } else if (this.currentStep === 3) {
        if (!this.registrationData.paymentMethod || this.registrationData.paymentMethod === '') {
          document.getElementById('paymentMethod').classList.remove('form-element-danger')
          document.getElementById('paymentMethodNote').innerHTML = ''
        } else {
          this.registrationDataStep3Keys.forEach(key => {
            if (this.registrationData.paymentMethod === 'Bankeinzug' || (this.registrationData.paymentMethod === 'Rechnung' && !(key === 'sepaCompanyStreet' || key === 'sepaCompanyZip' || key === 'sepaCompanyCity' || key === 'sepaMandateReferenceNumber'))) {
              document.getElementById(key).classList.remove('form-element-danger')
              document.getElementById(key + 'Note').innerHTML = ''
            }
          })
        }
        this.registrationDataStep3Keys.forEach(key => {
          if (key === 'paymentMethod') {
            if (!this.registrationData[key] || this.registrationData[key] === '') {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
              return this.validationStatus
            } else {
              if (!(this.registrationData[key] === 'Bankeinzug' || this.registrationData[key] === 'Rechnung')) {
                this.showValidationError(key, 'Das Feld enthält einen ungültigen Wert.')
              }
            }
          }
          if (this.registrationData.paymentMethod && this.registrationData.paymentMethod === 'Rechnung' && !(key === 'sepaCompanyStreet' || key === 'sepaCompanyZip' || key === 'sepaCompanyCity' || key === 'sepaMandateReferenceNumber')) {
            if ((!this.registrationData[key] || this.registrationData[key] === '')) {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            } else {
              if (key === 'sepaAccountHolder' && this.registrationData[key].length < 3) {
                this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
              }
              if (key === 'sepaIBAN' && this.registrationData.sepaIBAN.startsWith('DE') && this.registrationData.sepaIBAN.replace(/\s/g, '').length !== 22) {
                this.showValidationError(key, 'Die deutsche IBAN muss 22 Zeichen lang sein.')
              }
            }
          } else if (this.registrationData.paymentMethod && this.registrationData.paymentMethod === 'Bankeinzug') {
            if ((!this.registrationData[key] || this.registrationData[key] === '')) {
              this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            } else {
              if (key === 'sepaAccountHolder' && this.registrationData[key].length < 3) {
                this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
              }
              if (key === 'sepaBIC' && this.registrationData[key].length < 5) {
                this.showValidationError(key, 'Der BIC ist ungültig.')
              }
              if (key === 'sepaIBAN' && this.registrationData.sepaIBAN.startsWith('DE') && this.registrationData.sepaIBAN.replace(/\s/g, '').length !== 22) {
                this.showValidationError(key, 'Die deutsche IBAN muss 22 Zeichen lang sein.')
              }
              if ((key === 'sepaCompanyCity') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
              }
              if (key === 'sepaCompanyStreet' && !(/\d/.test(this.registrationData[key]))) {
                this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
              }
              if (key === 'sepaCompanyZip' && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
                this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
              }
            }
          }
        })
      }
      return this.validationStatus
    },
    showValidationError(key, validationMessage) {
      const element = document.getElementById(key)
      if (element) {
        element.classList.add('form-element-danger')
      }

      const elementNote = document.getElementById(key + 'Note')
      if (elementNote) {
        elementNote.innerHTML = validationMessage
      }
      this.validationStatus = false
    },
    showHandlingAtPosValidationError (key, validationMessage) {
      const elementNote = document.getElementById(key + 'Note')
      if (elementNote) {
        elementNote.innerHTML = validationMessage
      }
      this.validationStatus = false
    },
    removeValidationError (key) {
      const element = document.getElementById(key)
      const elementNote = document.getElementById(key + 'Note')
      if (element) {
        element.classList.remove('form-element-danger')
      }
      if (elementNote) {
        elementNote.innerHTML = ''
      }
    },
    passwordChecker (event) {
      const password = event.target.value
      if (/[A-ZÄÖÜ]+/.test(password)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(password)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(password)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (password.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    onCategoryCheckboxChanged(event) {
      const isChecked = event.target.checked
      if (isChecked) {
        this.registrationData.branches[this.branchIndex].companyCategories.push(event.target.value)
      } else {
        const index = this.registrationData.branches[this.branchIndex].companyCategories.indexOf(event.target.value)
        if (index !== -1) {
          this.registrationData.branches[this.branchIndex].companyCategories.splice(index, 1)
        }
      }
    },
    isCategoryCheckboxChecked (index) {
      if (this.registrationData.branches[this.branchIndex].companyCategories !== undefined && this.registrationData.branches[this.branchIndex].companyCategories.includes(this.categories[index])) {
        return true
      } else {
        return false
      }
    },
    setVoucherValue () {
      if (typeof this.voucherCode !== 'undefined' && this.voucherCode !== '') {
        if (this.voucherCode.toLowerCase() === 'trolleymaker100') {
          this.voucherValue = 0
          this.voucherPercent = 1
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + (this.voucherPercent * 100) + '% netto.' })
        } else if (this.voucherCode.toLowerCase() === 'dummy') {
          this.voucherValue = 10
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + this.voucherValue + '€ netto.' })
        } else {
          this.voucherValue = 0
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
        }
      } else {
        this.voucherValue = 0
        this.voucherPercent = 0
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
      }
    },
    onOpenVoucherClicked () {
      this.showVoucherInput = true
    },
    async fetchCompletePartnerPersonalDataAndRegionCheckoutData () {
      this.$store.commit('SET_LOADING_STATUS', true)

      Promise.all([
        this.$store.dispatch('getRegionPartnerCheckoutData', { regionName: this.$store.state.region.name, regionSlug: this.$store.state.region.slug }),
        this.$store.dispatch('getCompletePartnerPersonalData'),
        this.$store.dispatch('getCategories')
      ]).then(([regionResponse, registrationDataResponse, categoriesResponse]) => {
        this.regionCheckoutData = regionResponse[0].acf

        this.registrationData = registrationDataResponse
        this.registrationData.amountOfOriginBranches = this.registrationData.branches.length
        this.registrationData.sepaCompanyStreet = this.registrationData.companyREStreet
        this.registrationData.sepaCompanyZip = this.registrationData.companyREZip
        this.registrationData.sepaCompanyCity = this.registrationData.companyRECity
        if (Object.prototype.hasOwnProperty.call(this.registrationData, 'paymentMethod') && this.registrationData.paymentMethod !== '' && typeof this.registrationData.paymentMethod !== 'undefined' && this.registrationData.paymentMethod !== null) {
          this.paymentMethodAlreadySet = true
        }
        if (this.registrationData.handlingAtPOS !== null && typeof this.registrationData.handlingAtPOS !== 'undefined' && this.registrationData.handlingAtPOS) {
          if (this.registrationData.handlingAtPOS.toLowerCase().includes('pc / pc-kasse')) {
            this.registrationData.handlingAtPOSPC = true
          }
          if (this.registrationData.handlingAtPOS.toLowerCase().includes('smartphone / tablet')) {
            this.registrationData.handlingAtPOSSmartphone = true
          }
          if (this.registrationData.handlingAtPOS.toLowerCase().includes('ec terminal') || this.registrationData.handlingAtPOS.toLowerCase().includes('ec-terminal')) {
            this.registrationData.handlingAtPOSEcDevice = true
          }
          if (this.registrationData.handlingAtPOS.toLowerCase().includes('handelskassenanbindung')) {
            this.registrationData.handlingAtPOSCashpoint = true
          }
        }
        let branchUserError = false
        for (const branch of this.registrationData.branches) {
          if (!Object.prototype.hasOwnProperty.call(branch, 'branchUsers') || branch.branchUsers.length <= 0) {
            branchUserError = true
          }
        }
        if (branchUserError) {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Eine Ihrer Filialen hat keinen Benutzer. Bitte wenden Sie sich an den Support.' })
          this.deactivateForm()
        }

        if (!categoriesResponse || categoriesResponse.length === 0) {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Die Kategorien konnten nicht abgerufen werden.' })
          this.deactivateForm()
        } else {
          this.categories = categoriesResponse
        }

        this.$store.commit('SET_LOADING_STATUS', false)
      }).catch(() => {
        this.deactivateForm()
        this.$store.commit('SET_LOADING_STATUS', false)
      })
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    async checkIfEmailAlreadyExists (emailToCheck) {
      return await this.$store.dispatch('checkEmailExists', { inputEmail: emailToCheck }).then(response => {
        return response.exists
      }).catch(() => {
      })
    },
    addSecondOpeningHour (day) {
      if (day === 'monday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2 = true
        }
      } else if (day === 'tuesday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom2 = true
        }
      } else if (day === 'wednesday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom2 = true
        }
      } else if (day === 'thursday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom2 = true
        }
      } else if (day === 'friday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursFridayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom2 = true
        }
      } else if (day === 'saturday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursSaturdayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursSaturdayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursStaurdayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursSaturdayFrom2 = true
        }
      } else if (day === 'sunday') {
        if (this.registrationData.branches[this.branchIndex].companyOpenHoursSundayFrom2) {
          this.registrationData.branches[this.branchIndex].companyOpenHoursSundayFrom2 = false
          this.registrationData.branches[this.branchIndex].companyOpenHoursSundayTo2 = false
        } else {
          this.registrationData.branches[this.branchIndex].companyOpenHoursSundayFrom2 = true
        }
      }
    },
    addBranchUser () {
      this.registrationData.branches[this.branchIndex].branchUserIndex = this.registrationData.branches[this.branchIndex].branchUserIndex + 1
      this.registrationData.branches[this.branchIndex].branchUsers[this.registrationData.branches[this.branchIndex].branchUserIndex] = {}
    },
    removeBranchUser (branchUserIndexToDelete) {
      this.registrationData.branches[this.branchIndex].branchUserIndex = this.registrationData.branches[this.branchIndex].branchUserIndex - 1
      this.registrationData.branches[this.branchIndex].branchUsers.splice(branchUserIndexToDelete, 1)
    },
    async nextStep() {
      if (await this.validateRegistrationData()) {
        window.scrollTo(0, 1)
        this.currentStep = this.currentStep + 1
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async previousStep () {
      if (await this.validateRegistrationData()) {
        window.scrollTo(0, 1)
        this.currentStep = this.currentStep - 1
        this.paypalLoaded = false
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async toStep (toStepNumber) {
      if (await this.validateRegistrationData()) {
        if (this.registrationData.partnerDataComplete === true) {
          window.scrollTo(0, 1)
          this.currentStep = toStepNumber
          this.$store.commit('DELETE_ALERTS')
        } else {
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ihr Partnerdaten wurden noch nie vollständig ausgefüllt. Sie müssen zuerst alle Schritte nacheinander ausfüllen und anschließend speichern.' })
        }
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async nextBranch () {
      if (await this.validateRegistrationData()) {
        if (typeof this.registrationData.branches[(this.branchIndex + 1)] === 'undefined') {
          this.registrationData.branches[(this.branchIndex + 1)] = {}
          this.registrationData.branches[(this.branchIndex + 1)].branchUserIndex = 0
          this.registrationData.branches[(this.branchIndex + 1)].branchUsers = []
          this.registrationData.branches[(this.branchIndex + 1)].branchUsers[0] = {}
          this.registrationData.branches[(this.branchIndex + 1)].companyOpenHoursOnlyByArrangement = false
        }
        window.scrollTo(0, 1)
        this.branchIndex = this.branchIndex + 1
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    async previousBranch () {
      if (await this.validateRegistrationData()) {
        window.scrollTo(0, 1)
        this.branchIndex = this.branchIndex - 1
        this.$store.commit('DELETE_ALERTS')
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    removeBranch (branchIndexToDelete) {
      window.scrollTo(0, 1)
      this.branchIndex = this.branchIndex - 1
      this.registrationData.branches.splice(branchIndexToDelete, 1)
    },
    async generateSepaPDF () {
      if (await this.validateRegistrationData()) {
        this.$store.commit('DELETE_ALERTS')
        const sepaData = {}
        sepaData.sepaMandateReferenceNumber = this.registrationData.sepaMandateReferenceNumber
        sepaData.sepaCompanyStreet = this.registrationData.sepaCompanyStreet
        sepaData.sepaCompanyZip = this.registrationData.sepaCompanyZip
        sepaData.sepaCompanyCity = this.registrationData.sepaCompanyCity
        sepaData.sepaAccountHolder = this.registrationData.sepaAccountHolder
        sepaData.sepaIBAN = this.registrationData.sepaIBAN
        sepaData.sepaBIC = this.registrationData.sepaBIC
        sepaData.sepaBankName = this.registrationData.sepaBankName

        this.$store.dispatch('generateSepaPDF', sepaData).then(response => {
          const blob = new Blob([response], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'trolleymaker-sepa-mandat.pdf'
          link.click()
        })
      } else {
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
      }
    },
    transferOpeningHoursToAllDays () {
      this.registrationData.branches[this.branchIndex].isClosedOnTuesday = this.registrationData.branches[this.branchIndex].isClosedOnMonday
      this.registrationData.branches[this.branchIndex].isClosedOnWednesday = this.registrationData.branches[this.branchIndex].isClosedOnMonday
      this.registrationData.branches[this.branchIndex].isClosedOnThursday = this.registrationData.branches[this.branchIndex].isClosedOnMonday
      this.registrationData.branches[this.branchIndex].isClosedOnFriday = this.registrationData.branches[this.branchIndex].isClosedOnMonday
      this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom1
      this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom1
      this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom1
      this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom1
      this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayFrom2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2
      this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayFrom2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2
      this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayFrom2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2
      this.registrationData.branches[this.branchIndex].companyOpenHoursFridayFrom2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayFrom2
      this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayTo1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo1
      this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayTo1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo1
      this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayTo1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo1
      this.registrationData.branches[this.branchIndex].companyOpenHoursFridayTo1 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo1
      this.registrationData.branches[this.branchIndex].companyOpenHoursTuesdayTo2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo2
      this.registrationData.branches[this.branchIndex].companyOpenHoursWednesdayTo2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo2
      this.registrationData.branches[this.branchIndex].companyOpenHoursThursdayTo2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo2
      this.registrationData.branches[this.branchIndex].companyOpenHoursFridayTo2 = this.registrationData.branches[this.branchIndex].companyOpenHoursMondayTo2
    },
    renderTooltips () {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    showPayPal () {
      if (!(this.registrationData.sktype && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr')) && !(this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
        loadScript({
          'client-id': 'AfFBbQ79tZTtiS9yUjS3HgL8diVR4ZjazvJ6_UoDynx5u0m9Z09YZ5MgdSpKIkeMtcyM5jvjK6CXLlJA',
          currency: 'EUR'
        })
          .then((paypal) => {
            this.paypalLoaded = true
            const that = this
            paypal
              .Buttons({
                createOrder: function (data, actions) {
                  return actions.order.create({
                    purchase_units: [{
                      amount: {
                        value: that.priceToPay,
                        currency_code: 'EUR'
                      }
                    }]
                  })
                },
                onApprove: function (data, actions) {
                  return actions.order.capture().then(function (details) {
                    that.saveRegistrationData()
                  })
                },
                onError: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Bei der Bezahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.' })
                },
                onCancel: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Bezahlung abschließen, um Ihre Partnerdaten zu speichern.' })
                }
              })
              .render('#paypal-button-container')
              .catch((error) => {
                console.error('failed to render the PayPal Buttons', error)
              })
          })
          .catch((error) => {
            console.error('failed to load the PayPal JS SDK script', error)
          })
      }
    }
  },
  created () {
    this.fetchCompletePartnerPersonalDataAndRegionCheckoutData()
  },
  mounted () {
    /* const paypalScript = document.createElement('script')
    paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=test&currency=EUR')
    document.head.appendChild(paypalScript) */

    const deleteBranchModal = document.getElementById('deleteBranchModal')
    this.deleteBranchBootstrapEl = new bootstrap.Modal(deleteBranchModal)

    this.renderTooltips()
  },
  updated () {
    this.renderTooltips()
    if (this.currentStep === 5 && !this.paypalLoaded) {
      this.showPayPal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .label-view {
    margin-bottom: 5px;
  }

  #gender {
    min-width: 130px;
  }

  #country {
    min-width: 220px;
  }

  #companyGewerbeverein {
    min-width: 350px;
  }

  .input-view {
    border-radius: 0;
  }

  .form-control:focus, .form-select:focus {
    border: 1px solid #003882;
    box-shadow: 0 0 0 0.1rem #003882;
    padding-left: 5px;
  }

  .form-control.time-field {
    width: auto;
    display: inline-block;
  }

  .form-select {
    width: 260px;
  }

  .add-second-opening-hour-image-button {
    width: 30px;
    cursor: pointer;
  }

  #transfer-open-hours-button {
    cursor: pointer;
  }

  .voucher-link {
    cursor: pointer;
  }
</style>
