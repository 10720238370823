<template>
  <div class="card-box mb-2 mb-lg-0">
    <img id="card-image" class="img-fluid mb-3" :src="require(`../assets/img/cards/${cardName}.png`)">
    <div v-if="currentCardData && currentCardData.isIndividualCard === true">
      <img :src="currentCardData.mitarbeitercardLogo" width="300" class="mb-2" />
    </div>
    <vue-barcode v-if="currentCardData && currentCardData.cardId" :value="currentCardData.cardId" :options="{ displayValue: true, height: 35, format: 'CODE128', background: 'rgba(255,255,255,0)', lineColor: '#000000' }"></vue-barcode>
  </div>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode'
const slugify = require('slugify')

export default {
  name: 'CardDataComponent',
  props: ['currentCardID'],
  data () {
    return {
      currentCardData: {}
    }
  },
  watch: {
    currentCardID(newSelectedCardID) {
      this.currentCardData = {}
      this.currentCardData.cardId = ''

      const foundCard = this.$store.state.user.cardIDs.find(obj => Number(obj.cardId) === Number(newSelectedCardID))
      if (typeof foundCard !== 'undefined' && foundCard !== undefined && foundCard !== null) {
        this.currentCardData = foundCard
      }
    }
  },
  computed: {
    cardName () {
      return this.$store.state.user.cardName ? slugify(this.$store.state.user.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    }
  },
  components: {
    VueBarcode
  },
  mounted() {
    this.currentCardData.cardId = this.currentCardID
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  #card-image {
    height: 150px;
  }
}
</style>
