<template>
  <div class="content">
    <div class="container py-1 py-md-3">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img :src="conditionsImage" />
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PartnerConditionsOverviewPage',
  data () {
    return {
      conditionsImageSrc: ''
    }
  },
  computed: {
    conditionsImage () {
      return this.conditionsImageSrc
    }
  },
  methods: {
    async fetchPartnerConditionsImage () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getPartnerConditionsImage', { regionID: this.$store.state.region.id }).then(response => {
        this.conditionsImageSrc = response.acf.partner_conditions_image
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  created () {
    this.fetchPartnerConditionsImage()
  }
}
</script>
