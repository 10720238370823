<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>Passwort-<span class="fw-bold">Änderung</span></h1>
        <p>Ändern Sie Ihr Passwort</p>
        <div class="mt-5 pt-3">
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="currentPassword" class="form-label label-view">
                  Bitte geben Sie Ihr aktuelles Passwort ein
                </label>
                <input v-model="currentPassword" type="password" id="currentPassword" class="form-control" placeholder="Aktuelles Passwort eingeben">
                <div id="currentPasswordNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="pt-2 border-top">
            <div class="row">
              <div class="col-lg-6">
                <label for="newPassword" class="form-label label-view">
                  Bitte geben Sie Ihr neues Passwort ein
                </label>
                <div class="input-group flex-nowrap">
                  <input v-model="newPassword" :type="passwordInputType" @input="passwordChecker" id="newPassword" class="form-control" placeholder="Neues Passwort eingeben">
                  <span class="input-group-text"  id="changePasswordType" @click="togglePasswordVisibility()"><img :src="require(`../assets/img/${showPasswordIcon}.svg`)" width="20" height="20" /></span>
                </div>
                <div id="newPasswordNote" class="form-text error-note"></div>
                <div id="passwordChecker" class="form-text text-start">
                    <div id="passwordCheckerMinCharacter"><img id="passwordCheckerMinCharacterIcon" v-bind:src="getIcon(passwordCheckerMinCharacterIcon)" width="15" height="15"> enthält mind. 8 Zeichen</div>
                    <div id="passwordCheckerCapitalLetter"><img id="passwordCheckerCapitalLetterIcon" v-bind:src="getIcon(passwordCheckerCapitalLetterIcon)" width="15" height="15"> enthält mind. 1 Großbuchstaben</div>
                    <div id="passwordCheckerNumber"><img id="passwordCheckerNumberIcon" v-bind:src="getIcon(passwordCheckerNumberIcon)" width="15" height="15"> enthält mind. 1 Ziffer</div>
                    <div id="passwordCheckerSpecialCharacter"><img id="passwordCheckerSpecialCharacterIcon" v-bind:src="getIcon(passwordCheckerSpecialCharacterIcon)" width="15" height="15"> enthält mind. 1 Sonderzeichen (!$%()*,-.?@^_~)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-2 mb-3 border-top border-bottom">
            <div class="row">
              <div class="col-lg-6">
                <label for="confirmNewPassword" class="form-label label-view">
                  Bitte bestätigen Sie Ihr neues Passwort
                </label>
                <input v-model="confirmNewPassword" type="password" id="confirmNewPassword" class="form-control" placeholder="Neues Passwort bestätigen">
                <div id="confirmNewPasswordNote" class="form-text error-note"></div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button @click="updatePassword" class="btn btn-primary">Speichern >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'UpdatePasswordPage',
  components: {
    CardData
  },
  data () {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      passwordInputType: 'password',
      passwordCheckerMinCharacterIcon: 'icon-x.svg',
      passwordCheckerCapitalLetterIcon: 'icon-x.svg',
      passwordCheckerNumberIcon: 'icon-x.svg',
      passwordCheckerSpecialCharacterIcon: 'icon-x.svg'
    }
  },
  computed: {
    showPasswordIcon () {
      return this.passwordInputType === 'password' ? 'eye-icon' : 'eye-hide-icon'
    }
  },
  methods: {
    updatePassword () {
      if (!this.newPassword || !this.confirmNewPassword || !this.currentPassword) {
        if (!this.newPassword) {
          this.showValidationError('newPassword', 'Sie müssen das neue Passwort eingeben.')
        }
        if (!this.confirmNewPassword) {
          this.showValidationError('confirmNewPassword', 'Sie müssen das neue Passwort wiederholt eingeben.')
        }

        if (!this.currentPassword) {
          this.showValidationError('currentPassword', 'Sie müssen Ihr aktuelles Passwort eingeben.')
        }

        return
      }

      if (this.newPassword !== this.confirmNewPassword) {
        this.showValidationError('newPassword', 'Die beiden Passwörter stimmen nicht überein.')
        this.showValidationError('confirmNewPassword', 'Die beiden Passwörter stimmen nicht überein.')
        return
      }

      if ((this.newPassword.length < 8 || !/[!$%()*,-.?@^_~]+/.test(this.newPassword) || !/[0-9]+/.test(this.newPassword) || !/[A-ZÄÖÜ]+/.test(this.newPassword))) {
        this.showValidationError('newPassword', 'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Nummer, 1 Großbuchstaben und 1 Sonderzeichen (!$%()*,-.?@^_~) enthalten.')
        return
      }

      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('updatePassword', { password: this.currentPassword, newPassword: this.newPassword, newPasswordRepeated: this.confirmNewPassword })
        .then(() => {
          this.currentPassword = this.newPassword = this.confirmNewPassword = ''
          this.removeValidationError('currentPassword')
          this.removeValidationError('newPassword')
          this.removeValidationError('confirmNewPassword')
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    },
    showValidationError (fieldId, validationMessage) {
      document.getElementById(fieldId).classList.add('form-element-danger')
      document.getElementById(fieldId + 'Note').innerHTML = validationMessage
    },
    removeValidationError (fieldId) {
      document.getElementById(fieldId).classList.remove('form-element-danger')
      document.getElementById(fieldId + 'Note').innerHTML = ''
    },
    passwordChecker () {
      if (/[A-ZÄÖÜ]+/.test(this.newPassword)) {
        this.passwordCheckerCapitalLetterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerCapitalLetterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerCapitalLetter').classList.remove('passwordChecker-passed')
      }
      if (/[0-9]+/.test(this.newPassword)) {
        this.passwordCheckerNumberIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerNumber').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerNumberIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerNumber').classList.remove('passwordChecker-passed')
      }
      if (/[!$%()*,-.?@^_~]+/.test(this.newPassword)) {
        this.passwordCheckerSpecialCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerSpecialCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerSpecialCharacter').classList.remove('passwordChecker-passed')
      }
      if (this.newPassword.length >= 8) {
        this.passwordCheckerMinCharacterIcon = 'icon-check.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.add('passwordChecker-passed')
      } else {
        this.passwordCheckerMinCharacterIcon = 'icon-x.svg'
        document.getElementById('passwordCheckerMinCharacter').classList.remove('passwordChecker-passed')
      }
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    togglePasswordVisibility () {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  }
}
</script>
