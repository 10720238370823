<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-2 text-center">
        <CardData />
      </div>
      <div class="col-12 col-lg-10 ps-lg-5">
        <h1><span class="fw-bold">Termin</span>buchung</h1>
        <iframe :src="appointmentIframeUrl" width='100%' height='2200px' scrolling='yes' style="border:0"></iframe>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import UserRoles from './../enums/UserRoles'

export default {
  name: 'TerminbuchungPage',
  components: {
    CardData
  },
  data () {
    return {
      appointmentIframeUrl: ''
    }
  },
  methods: {
    async fetchAppointmentIframeUrl () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getAppointmentIframeUrl', { regionID: this.$store.state.region.id }).then(response => {
        this.appointmentIframeUrl = response.acf.appointment_iframe_url
        if (this.$store.state.user.role === UserRoles.INTERESTED) {
          this.appointmentIframeUrl = response.acf.appointment_iframe_url_interest
        } else if (this.$store.state.user.role === UserRoles.EMPLOYER) {
          this.appointmentIframeUrl = response.acf.appointment_iframe_url_mitarbeitercard
        }
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  created () {
    this.fetchAppointmentIframeUrl()
  }
}
</script>
