import moment from 'moment'

export default class Helper {
  static convertISODateToGermanDate(dateStringToConvert) {
    const date = moment(dateStringToConvert, moment.ISO_8601)
    return date.format('DD.MM.YYYY')
  }

  static convertGermanDateToISODate(dateStringToConvert) {
    const date = moment(dateStringToConvert, 'DD.MM.YYYY')
    return date.format('YYYY-MM-DD')
  }

  static removeValidationError(fieldId) {
    const input = document.getElementById(fieldId)
    if (input !== null && input !== undefined) {
      input.classList.remove('form-element-danger')
    }
    const inputField = document.getElementById(fieldId + 'Input')
    if (inputField !== null && inputField !== undefined) {
      inputField.classList.remove('form-element-danger')
    }
    const noteField = document.getElementById(fieldId + 'Note')
    if (noteField !== null && noteField !== undefined) {
      noteField.innerHTML = ''
    }
  }
}
