<template>
 <div class="container py-1 py-md-5">
  <div class="content">
    <div class="row">
      <div class="col-12">
        <select class="form-select input-view mb-3" @change="onCurrentCardIDChanged($event)">
          <option v-for="k, cardID in cardData" :key="cardID" :value="cardID" :selected="parseInt(cardID) === parseInt(Object.keys(this.cardData)[0])">{{cardID}}</option>
        </select>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center">
        <CardData :currentCardID="currentCardID" />
      </div>
      <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">
        <h1>Übersicht der <span class="fw-bold">Kartendaten</span></h1>
        <p>Herzlich Willkommen in Ihrem persönlichen Kundenbereich</p>
        <div class="mt-5">
          <table class="table mb-0">
            <tr><td></td></tr>
            <tr>
              <td class="ps-0 py-2">
                Kartennummer:<br>
                <b class="px-0">{{ currentCardID }}</b>
              </td>
            </tr>
            <tr>
              <td id="card-balance" class="ps-0 pt-2">
                Kartenguthaben:<br>
                <b class="px-0">{{ currentCardData && currentCardData.balance && currentCardData.balance.balanceFormattedDE ? currentCardData.balance.balanceFormattedDE : '' }}€</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row my-5 pt-5">
      <div class="col-12">
        <h2>Übersicht der <span class="fw-bold">Buchungen</span></h2>
        <div class="alert alert-warning" role="alert">
          Aktuelle Buchungen erscheinen nach ca. 5 Minuten in der Übersicht.
        </div>
        <div class="mt-3">
          <div class="border border-radius rounded table-responsive">
            <table id="transactions-table" class="table table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col" class="pb-5">Datum</th>
                  <th scope="col" class="pb-5">Partner</th>
                  <th scope="col" class="pb-5">Buchungsart</th>
                  <th scope="col" class="pb-5">Betrag in Euro</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transaction, index) in currentCardData.transactions" :key="index">
                  <td>{{ transaction.dateFormattedDE }} {{ transaction.dateFormattedDE ? 'Uhr' : '' }}</td>
                  <td>{{ transaction.partner }}</td>
                  <td>{{ transaction.text }}</td>
                  <td>{{ transaction.amountFormattedDE }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-end">
              <router-link to="/booking-overview" id="btn-link-bookings-overview" class="btn btn-primary my-5" role="button">Zur kompletten Buchungsübersicht >></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import CardData from '@/components/CardData.vue'

export default {
  name: 'DashboardPage',
  components: {
    CardData
  },
  data () {
    return {
      currentCardID: '',
      cardData: [],
      currentCardData: {},
      currentLocalStorageCardData: {}
    }
  },
  methods: {
    async fetchDashboardData () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getDashboard').then(response => {
        this.cardData = response
        if (Object.keys(this.cardData).length > 0) {
          this.currentCardData = this.cardData[Object.keys(this.cardData)[0]]
          this.currentCardID = Object.keys(this.cardData)[0]
        }
      }).catch(() => { })
        .finally(() => {
          this.$store.commit('SET_LOADING_STATUS', false)
        })
    },
    onCurrentCardIDChanged (event) {
      this.currentCardData = this.cardData[event.target.value]
      this.currentCardID = event.target.value
    }
  },
  created () {
    this.fetchDashboardData()
  }
}
</script>

<style lang="scss" scoped>
#btn-link-bookings-overview {
  border-radius: 0 !important;
}

#card-balance {
  font-size: 30px;
}

@media (max-width: 500px) {
  #transactions-table td,
  #transactions-table th {
    font-size: 14px;
  }
}
</style>
