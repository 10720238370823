<template>
  <footer class="card-primary-background-color">
    <div class="container py-5 mt-5">
      <div class="row">
        <div class="col-12 col-sm-6">
          <h3>Kontakt</h3>
          trolleymaker gmbh<br />
          Zeppelinstr. 7<br />
          76185 Karlsruhe<br />
          <br />
          Telefon: +49 721 59 78 24 900<br />
          E-Mail: post@trolleymaker.com<br />
          Internet: www.trolleymaker.com<br />
        </div>
        <div class="col-12 col-sm-6 pb-4 mt-4 mt-sm-0">
          <h3>powered by</h3>
          <a href="https://www.trolleymaker.com/" target="_blank"><img width="250" src="../assets/img/trolleymaker-logo-weiss.png"></a>
        </div>
      </div>
      <div class="row mt-4 mt-sm-5">
        <div class="col-12">
          <span class="me-sm-4 me-0 d-block d-sm-inline"><router-link :to="{ name: 'ConditionsOfParticipation' }" target="_blank">Teilnahmebedingungen</router-link></span>
          <span class="me-sm-4 me-0 d-block d-sm-inline"><router-link :to="{ name: 'Imprint' }" target="_blank">Impressum</router-link></span>
          <span class="me-sm-4 me-0 d-block d-sm-inline"><router-link :to="{ name: 'DataProtection' }" target="_blank">Datenschutzerklärung</router-link></span>
          <span class="me-sm-4 me-0 d-block d-sm-inline"><router-link :to="{ name: 'PrivacyPolicy' }" target="_blank">Datenschutzhinweise</router-link></span>
          <span class="me-sm-4 me-0 d-block d-sm-inline"><router-link :to="{ name: 'DeclarationOfConsent' }" target="_blank">Einwilligungserklärung</router-link></span>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          © trolleymaker gmbh {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
footer {
  color: #ffffff;
}

footer img {
  max-width: 100%;
}

footer a,
footer h2,
footer h3,
footer h4 {
  color: #ffffff;
  text-decoration: none;
}

footer h2,
footer h3,
footer h4 {
  font-weight: 700;
}
</style>
